import styled from 'styled-components'

import { Modal } from '@ui'

import { Button } from '@ui/Button/Button.styled'
import { ModalContent } from '@ui/Modal/Modal.styled'
import { TextField } from '@ui/TextField/TextField.styled'

export { TextField }

export const AddCreditCardModal = styled(Modal)`
  ${ModalContent} {
    width: 560px;
  }
`

export const Form = styled.form`
  p {
    margin-top: 15px;

    span {
      font-family: 'Montserrat-Bold';
    }
  }

  ${Button} {
    margin-top: 30px;
  }
`

export const Fields = styled.div`
  display: grid;
  grid-template-columns: 284px 150px;
  grid-gap: 20px;
`

import styled from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

import { BoxGray } from '@styled/components'
import { media } from '@styled/media'

export const TraderApplySteps = styled(BoxGray)`
  h6 {
    margin-bottom: 30px;
  }

  ${Button} {
    margin-top: 30px;
  }

  ${media.mobile} {
    h6 {
      font-size: var(--font-size-md);
      margin-bottom: 15px;
    }

    .step-label-label,
    .step-label-completed {
      font-size: var(--font-size-reg);
    }

    ${Button} {
      margin-top: 20px;
      font-size: var(--font-size-reg);
    }
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { UsersAPI } from '@api'

import { Button, Stepper, Step, StepLabel, Skeleton } from '@ui'

import * as S from './TraderApplySteps.styled'

export const TraderApplySteps = ({
  isLoading,
  tradeEligable,
  isUserTrader,
  verifications,
  refetchVerifications
}) => {
  const { t } = useTranslation()

  const { mutate: applyForTrader } = useMutation(UsersAPI.applyForTrader, {
    onSuccess: () => {
      refetchVerifications()
      toast.success('Successfully applied')
    }
  })

  const { investVerified, accountValueMin, notFollowTraders, pitch, appliedForTrader } =
    verifications

  const steps = [
    { label: t('verify to invest'), completed: investVerified },
    { label: t('min 1000$ in total on brok accounts'), completed: accountValueMin },
    { label: t("can't follow other traders"), completed: notFollowTraders },
    { label: t('fill trader pitch'), completed: pitch }
  ]

  return (
    <S.TraderApplySteps>
      <Typography variant="h6">{t('Apply for a trader')}</Typography>

      {!isLoading ? (
        <Stepper orientation="vertical">
          {steps.map(({ label, completed }) => (
            <Step key={label} completed={completed}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Skeleton height={220} />
      )}

      <Button
        variant={tradeEligable && !appliedForTrader ? 'lime' : 'disabled'}
        onClick={() => {
          applyForTrader()
        }}
      >
        {appliedForTrader ? t('Applied') : t('Apply')}
      </Button>
    </S.TraderApplySteps>
  )
}

import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { PasswordField, Button } from '@ui'

import { useAuth } from '@contexts/auth'
import * as AuthAPI from '@api/auth'

import * as S from './ChangePasswordModal.styled'

export const ChangePasswordModal = ({ title, open, onClose }) => {
  const { t } = useTranslation()
  const useFormProps = useForm()
  const { handleSubmit, setError } = useFormProps
  const { userCognito } = useAuth()

  const changePassword = useMutation(
    ({ userCognito, oldPassword, newPassword }) =>
      AuthAPI.changePassword(userCognito, oldPassword, newPassword),
    {
      onError: ({ message }) => {
        toast.error(message || 'Failed to change password')
      },
      onSuccess: () => {
        toast.success('Password changed successfully')
        onClose()
      }
    }
  )

  const onChangePasswordSubmit = ({ oldPassword, newPassword, confirmNewPassword }) => {
    if (newPassword !== confirmNewPassword) {
      setError('confirmNewPassword', {
        type: 'manual',
        message: 'Password mismatch'
      })

      return
    }

    changePassword.mutate({ userCognito, oldPassword, newPassword })
  }

  return (
    <S.ChangePasswordModal title={title} open={open} onClose={onClose}>
      <FormProvider {...useFormProps}>
        <S.Form onSubmit={handleSubmit(onChangePasswordSubmit)}>
          <PasswordField name="oldPassword" label={t('Old Password')} />
          <PasswordField name="newPassword" label={t('New Password')} />
          <PasswordField name="confirmNewPassword" label={t('Repeat password')} />

          <Button type="submit" variant="lime" isLoading={changePassword.isLoading}>
            {t('Change Password')}
          </Button>
        </S.Form>
      </FormProvider>
    </S.ChangePasswordModal>
  )
}

import styled, { css } from 'styled-components'

import { customScrollStyles } from '@styled/customScrollStyles'

import { TableHead, TableBody, TableCell, TableContainer } from '@ui/Table/Table.styled'
import { SortTriangles } from '@ui/SortTriangles/SortTriangles.styled'
import { ModalContent } from '@ui/Modal/Modal.styled'
import { Button } from '@ui/Button/Button.styled'
import { PercentStats } from '@components/PercentStats/PercentStats.styled'
import { Modal } from '@ui/Modal'

import { media } from '@styled/media'

export const InvestorsTable = styled.div`
  position: relative;

  ${TableContainer} {
    max-height: 646px;

    ${customScrollStyles}
  }

  ${PercentStats} {
    span {
      font-family: 'Montserrat-Medium';
    }
  }

  ${TableHead} {
    ${TableCell} {
      &:last-child {
        padding-right: 57px;
      }

      button {
        width: 40px;
        height: 40px;
      }
    }
  }

  ${TableBody} {
    ${TableCell} {
      position: relative;

      &:first-child {
        &.table-cell {
          display: flex;

          a {
            font-family: 'Montserrat-SemiBold';
            color: #000;
            text-decoration: none;
          }
        }
      }
    }
  }
`

export const FilterRow = styled.span`
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 50px;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 1px;
    height: 50px;
    background: #000;
  }
`

export const FilterButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  height: 100%;
  padding: 0;
  background: #1b1b1b;
  border: none;
  transition: 0.3s;
  cursor: pointer;
  z-index: 100;

  svg {
    fill: #fff;
  }

  ${({ active }) =>
    active &&
    css`
      background: #424242;
      transition: 0.3s;
    `}
`

export const Avatar = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;

  img {
    max-width: 100%;
  }
`

export const UserCellRight = styled.div`
  margin-left: 20px;
  overflow: hidden;

  p {
    max-width: 170px;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:first-child {
      font-family: 'Montserrat-SemiBold';
    }

    &:last-child {
      font-family: 'Montserrat-Regular';
      color: #676974;
    }
  }
`

export const SortRow = styled.span`
  display: flex;
  align-items: center;

  ${SortTriangles} {
    margin-left: 6.5px;
  }
`

export const ActionsRow = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Actions = styled.span`
  position: absolute;
  right: 11px;
  display: flex;
  flex-direction: column;
`

export const InvestorCard = styled.li`
  display: flex;
  padding: 10px;
  background: #f7f7f7;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${Avatar} {
    width: 50px;
    height: 50px;
  }
`

export const InvestorCardInfo = styled.div`
  margin-left: 10px;
`

export const InvestorCardInfoRow = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:nth-child(1) {
    a {
      font-family: 'Montserrat-SemiBold';
      color: #1b1b1b;
      text-decoration: none;
    }
  }

  &:nth-child(2) {
    span:first-child {
      margin-right: 5px;
      color: #676974;
    }

    span:nth-child(3) {
      margin-left: 10px;
      text-transform: uppercase;
    }
  }

  &:nth-child(3) {
    span:first-child {
      margin-right: 5px;
      color: #676974;
    }
  }

  &:nth-child(4) {
    font-family: 'Montserrat-Medium';
    color: #1b1b1b;

    span:last-child {
      margin-left: 10px;
    }
  }

  ${media.mobile} {
    span {
      font-size: 10px;
      line-height: 15px;
    }
  }
`

export const InvestorUsername = styled.span``

export const InvestorCardFullName = styled.span`
  margin-left: 6px;
  color: #676974;

  ${media.mobile} {
    width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const InvestorCardCopiers = styled.span`
  margin-left: 3px;
  font-family: 'Montserrat-Medium';
  color: #1b1b1b;
`

export const InvestorCardActions = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;

  button:last-child {
    margin-top: 18px;
  }
`

export const InvestorsCardsList = styled.ul`
  width: 100%;
  margin-top: 20px;
`

export const FilterButtonMobile = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: 30px;
  background: #1b1b1b;
  cursor: pointer;
  padding: 0;
  border: none;

  svg {
    fill: #fff;
  }
`

export const ColumnsSettingsModal = styled(Modal)`
  ${ModalContent} {
    width: 460px;
  }

  ${Button} {
    &:first-of-type {
      margin-top: 30px;
    }

    &:nth-child(4) {
      width: 100%;
      margin-top: 21px;
      font-size: var(--font-size-reg);
      text-align: center;
      line-height: 21px;
    }
  }
`

export const ColumnsSettingsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

import styled from 'styled-components'

import { BoxGray } from '@styled/components'
import { media } from '@styled/media'

export const InvestVerifySteps = styled(BoxGray)`
  h6 {
    margin-bottom: 30px;
  }

  ${media.mobile} {
    h6 {
      font-size: var(--font-size-md);
      margin-bottom: 15px;
    }

    .step-label-label,
    .step-label-completed {
      font-size: var(--font-size-reg);
    }
  }
`

export const VerifiedRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 3px;

  p {
    margin-left: 17px;
    font-family: 'Montserrat-SemiBold';
  }

  ${media.mobile} {
    margin-top: 20px;
  }
`

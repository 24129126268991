import { Auth } from 'aws-amplify'
import { API } from '@core/axios'

const root = '/users'

const transformInfo = (user) => {
  const {
    avatars,
    country,
    full_name,
    has_avatar,
    is_user_investor,
    is_user_trader,
    minimal_capital,
    pitch_info,
    username,
    user_links
  } = user

  const {
    facebook_link: facebook,
    instagram_link: instagram,
    linkedin_link: linkedin,
    telegram_link: telegram,
    twitter_link: twitter,
    youtube_link: youtube
  } = user_links

  return {
    avatar: avatars[0].url,
    country,
    fullName: full_name,
    hasAvatar: has_avatar,
    isInvestor: is_user_investor,
    isTrader: is_user_trader,
    minimalCapital: minimal_capital,
    pitchInfo: pitch_info,
    userLinks: {
      facebook,
      instagram,
      linkedin,
      telegram,
      twitter,
      youtube
    },
    username
  }
}

const transformVerifications = (verifications) => {
  const {
    filled_credentials,
    filled_personal_info,
    filled_pitch,
    is_eligible_to_invest,
    is_eligible_to_trade,
    is_user_investor,
    is_user_trader,
    is_verified_phone,
    linked_billing,
    linked_broker,
    min_account_value,
    min_pnl_stats,
    not_follow_traders,
    verified_email,
    verified_to_invest,
    is_apply_for_trader,
    username,
    phone,
    email,
    is_report_enable
  } = verifications

  return {
    credentials: filled_credentials,
    personalInfo: filled_personal_info,
    pitch: filled_pitch,
    investEligable: is_eligible_to_invest,
    tradeEligable: is_eligible_to_trade,
    isInvestor: is_user_investor,
    isTrader: is_user_trader,
    phoneVerified: is_verified_phone,
    billingLinked: linked_billing,
    brokerLinked: linked_broker,
    accountValueMin: min_account_value,
    pnlStatsMin: min_pnl_stats,
    notFollowTraders: not_follow_traders,
    emailVerified: verified_email,
    investVerified: verified_to_invest,
    appliedForTrader: is_apply_for_trader,
    login: username,
    phone,
    email,
    isReportEnable: is_report_enable
  }
}

const transformInfoToBack = (info) => {
  const { pitchInfo, fullName, links, country, capitalMin } = info

  return {
    pitch_info: pitchInfo,
    full_name: fullName,
    country,
    minimal_capital: capitalMin,
    twitter_link: links?.twitter,
    linkedin_link: links?.linkedin,
    instagram_link: links?.instagram,
    vk_link: links?.vk,
    telegram_link: links?.telegram,
    facebook_link: links?.facebook,
    youtube_link: links?.youtube
  }
}

const transformBrokers = (brokers) => {
  const transformLogos = (logos) => {
    return logos.map((logo) => {
      const { logo_url, logo_type, width, height } = logo

      return {
        url: logo_url,
        type: logo_type,
        width,
        height
      }
    })
  }

  const getCredentialFields = (type) => {
    switch (type) {
      case 0:
        return { login: '', password: '' }
      case 1:
        return { apiToken: '' }
      case 2:
        return { apiKey: '', secretKey: '' }
      default:
        return {}
    }
  }

  const transformCredentials = ({ type_credentials }) => {
    return getCredentialFields(type_credentials)
  }

  return brokers.map((broker) => {
    const { brokername, item_rank, broker_credentials, broker_logos, broker_url } = broker

    return {
      name: brokername,
      order: item_rank,
      credentials: transformCredentials(broker_credentials),
      logos: transformLogos(broker_logos),
      url: broker_url
    }
  })
}

const transformNotifications = (notifications) => {
  return notifications.map(
    ({ category, notification_datetime, notification_read, notification_text }) => {
      return {
        category,
        datetime: notification_datetime,
        viewed: notification_read,
        text: notification_text
      }
    }
  )
}

const transformDiscoveryList = (list) => {
  return list.map((item) => {
    const { country, usernames_list, usernames_group_tag, item_rank } = item

    return {
      country,
      usernames: usernames_list,
      tag: usernames_group_tag,
      order: item_rank
    }
  })
}

const transformBrokersConnected = (brokers) => {
  return brokers.map(({ brokername, username, item_rank }) => {
    return {
      name: brokername,
      username,
      order: item_rank
    }
  })
}

const transformPerformance = (performance) => {
  const {
    asset_type,
    benchmark_name,
    copiers_count,
    currency,
    managed_capital,
    monthly_pnl_by_year,
    pnl_12m,
    pnl_avg_an,
    pnl_ytd,
    risk,
    username
  } = performance

  const pnlMonthly = monthly_pnl_by_year?.map(({ year, monthly_pnl }) => {
    const pnl = monthly_pnl?.map(({ monthly_closing_dt, monthly_return }) => {
      return {
        closingDate: monthly_closing_dt,
        monthlyReturn: monthly_return
      }
    })

    return {
      year,
      pnl
    }
  })

  return {
    assetsType: asset_type,
    benchmarkName: benchmark_name,
    copiersCount: copiers_count,
    currency,
    capital: managed_capital,
    pnlMonthly: pnlMonthly,
    pnlYear: pnl_12m,
    pnlAvg: pnl_avg_an,
    pnlYtd: pnl_ytd,
    risk,
    username
  }
}

const transformWatchlist = (watchlist) => {
  return watchlist.map(({ item_rank, watched_username }) => {
    return {
      usernameWatched: watched_username,
      order: item_rank
    }
  })
}

const transformBalance = (balance) => {
  const {
    balance_performance,
    balance_signal,
    balance_total,
    delivered_signal_count,
    description_performance,
    description_signal,
    description_total,
    monthly_closing_dt,
    stripe_executed
  } = balance

  return {
    performanceBalance: balance_performance,
    performanceDesc: description_performance,
    signalBalance: balance_signal,
    signalDesc: description_signal,
    totalBalance: balance_total,
    totalDesc: description_total,
    signalCountDelivered: delivered_signal_count,
    closingDate: monthly_closing_dt,
    isStripeExecuted: stripe_executed
  }
}

const transformTradersPortfolio = (tradersPortfolio) => {
  return tradersPortfolio.traders.map((trader) => {
    const {
      invested_perc: investedPercent,
      pnl_perc: pnlPercent,
      tradername: traderUsername,
      brokername,
      amount
    } = trader

    return {
      investedPercent,
      pnlPercent,
      traderUsername,
      brokername,
      amount
    }
  })
}

const transformInstrumentsPortfolio = (instrumentsPortfolio) => {
  const transformInstruments = (instruments) => {
    return instruments.map((instrument) => {
      const {
        position_side: position,
        pnl_perc: pnlPercent,
        invested_perc: investedPercent,
        symbol,
        brokername
      } = instrument

      return {
        symbol,
        position,
        pnlPercent,
        investedPercent,
        brokername
      }
    })
  }

  const { instruments } = instrumentsPortfolio

  return transformInstruments(instruments)
}

const transformBenchmarks = (benchmarks) => {
  return benchmarks.map((benchmark) => {
    const {
      benchmark_desc: desc,
      benchmark_name: name,
      pnl_avg_an_bench: pnlAvg,
      pnl_ytd_bench: pnlYtd,
      ticker_yfinance: tickerFinance
    } = benchmark

    return {
      desc,
      name,
      pnlAvg,
      pnlYtd,
      tickerFinance
    }
  })
}

const transformBrokerBalance = (balance) => {
  return balance.map(({ currency, value }) => {
    return {
      currency: currency.toUpperCase(),
      value
    }
  })
}

const transformPerformanceFiltered = (users, usersInfo) => {
  return users.map((user) => {
    const {
      asset_type: assetsType,
      copiers_count: copiersCount,
      currency,
      discover_category: categories,
      pnl_12m: pnlYear,
      pnl_avg_an: pnlAvg,
      risk,
      username
    } = user

    return {
      categories,
      username,
      info: usersInfo.find((user) => user.username === username),
      performance: {
        pnlAvg,
        pnlYear,
        copiersCount,
        assetsType,
        risk,
        currency
      }
    }
  })
}

export const getInfo = async ({ username, search, usergroup }) => {
  const { data: info } = await API.get(`${root}/info`, { params: { username, search, usergroup } })

  return Array.isArray(info) ? info.map((user) => transformInfo(user)) : transformInfo(info)
}

export const changeEmail = (user, email) => {
  return Auth.updateUserAttributes(user, {
    email
  })
}

export const changeInfo = (info) => {
  return API.post(
    `${root}/info`,
    {},
    {
      params: transformInfoToBack(info)
    }
  )
}

export const getBalance = async () => {
  const { data: userInfoBalance } = await API.get(`${root}/balance`)

  return transformBalance(userInfoBalance)
}

export const getVerifications = async () => {
  const { data: verifications } = await API.get(`${root}/verification`)
  return transformVerifications(verifications)
}

export const getBrokers = async ({ search }) => {
  const { data: brokers } = await API.get(`/brokers`, { params: { search } })
  return transformBrokers(brokers)
}

export const getConntectedBrokers = async () => {
  const { data: brokers } = await API.get(`${root}/brokers`)
  return transformBrokersConnected(brokers)
}

export const connectBroker = ({ brokername, apiKey, secretKey, login, password, apiToken }) => {
  return API.post(
    `${root}/brokers`,
    {},
    { params: { brokername, key: apiKey, secretKey, login, password, token: apiToken } }
  )
}

export const deleteConntectedBroker = (brokername) => {
  return API.delete(`${root}/brokers?brokername=${brokername}`)
}

export const getNotifications = async () => {
  const { data: notifications } = await API.get(`${root}/notifications`)
  return transformNotifications(notifications)
}

export const readNotification = (datetime) => {
  return API.post(`${root}/notifications`, {}, { params: { datetime } })
}

export const uploadAvatar = (img) => {
  return API.post(`${root}/img`, img, {
    headers: {
      'Content-Type': 'image/jpeg'
    }
  })
}

export const deleteAvatar = () => {
  return API.delete(`${root}/img`)
}

export const uploadBrokerReport = (pdf) => {
  return API.post(`${root}/reports`, pdf, {
    headers: {
      'Content-Type': 'application/pdf'
    }
  })
}

export const getPerformance = async ({
  usergroup,
  username,
  pnlMin,
  pnlMax,
  pnlAvgMin,
  pnlAvgMax,
  copiersMin,
  copiersMax,
  risk,
  assetsType,
  currency
}) => {
  const { data: performance } = await API.get(`${root}/performance`, {
    params: {
      usergroup,
      username,
      gainmin: pnlMin,
      gainmax: pnlMax,
      ygainmin: pnlAvgMin,
      ygainmax: pnlAvgMax,
      copiesmin: copiersMin,
      copiesmax: copiersMax,
      risk,
      asset: assetsType,
      currency
    }
  })

  return Array.isArray(performance)
    ? performance.map((userPerformance) => transformPerformance(userPerformance))
    : transformPerformance(performance)
}

export const getPerformanceFilteredUsersCount = async ({
  pnlMin,
  pnlMax,
  pnlAvgMin,
  pnlAvgMax,
  copiersMin,
  copiersMax,
  risk,
  assetsType,
  currency
}) => {
  const { data: count } = await API.get(`${root}/performance`, {
    params: {
      gainmin: pnlMin,
      gainmax: pnlMax,
      ygainmin: pnlAvgMin,
      ygainmax: pnlAvgMax,
      copiesmin: copiersMin,
      copiesmax: copiersMax,
      risk,
      asset: assetsType,
      currency
    },
    transformResponse: (data) => {
      const { count } = JSON.parse(data)
      return count
    }
  })

  return count
}

export const getPerformanceFiltered = async ({
  pnlMin,
  pnlMax,
  pnlAvgMin,
  pnlAvgMax,
  copiersMin,
  copiersMax,
  risk,
  assetsType,
  currency
}) => {
  const {
    data: { users, count }
  } = await API.get(`${root}/performance`, {
    params: {
      gainmin: pnlMin,
      gainmax: pnlMax,
      ygainmin: pnlAvgMin,
      ygainmax: pnlAvgMax,
      copiesmin: copiersMin,
      copiesmax: copiersMax,
      risk,
      asset: assetsType,
      currency
    },
    transformResponse: (data) => {
      const { body, count } = JSON.parse(data)
      return {
        users: body,
        count
      }
    }
  })

  const usersWithDiscoverCategory = users.filter(
    ({ discover_category }) => discover_category.length
  )
  const usernames = usersWithDiscoverCategory.map(({ username }) => username).join(',')
  const usersInfo = await getInfo({ usergroup: usernames })

  const transformedUsers = transformPerformanceFiltered(usersWithDiscoverCategory, usersInfo)

  const allCategories = transformedUsers.reduce((prev, curr) => {
    if (!prev.some((category) => curr.categories.includes(category))) {
      return [...prev, ...curr.categories]
    }

    return prev
  }, [])

  const usersByCategory = allCategories.reduce((prev, category) => {
    const usersWithCurrentCategory = transformedUsers.filter((user) =>
      user.categories.includes(category)
    )

    return [
      ...prev,
      {
        tag: category,
        users: usersWithCurrentCategory
      }
    ]
  }, [])

  return {
    users: usersByCategory,
    count
  }
}

export const changePhone = (phone) => {
  return API.post(`${root}/verification`, {}, { params: { phone } })
}

export const applyForTrader = () => {
  return API.post(`${root}/verification`, {}, { params: { trader: 'true' } })
}

export const getWatchlistUsers = async () => {
  const { data: watchlist } = await API.get(`${root}/watchlists`)

  if (!watchlist.length) {
    return []
  }

  const watchlistTransformed = transformWatchlist(watchlist)
  const usernamesStr = watchlistTransformed.map(({ usernameWatched }) => usernameWatched).join(',')
  const usersInfo = await getInfo({ usergroup: usernamesStr })
  const usersPerformance = await getPerformance({ usergroup: usernamesStr })

  return watchlistTransformed.map(({ usernameWatched, order }) => {
    return {
      username: usernameWatched,
      info: usersInfo.find((user) => user.username === usernameWatched),
      performance: usersPerformance.find((user) => user.username === usernameWatched),
      order
    }
  })
}

export const getWatchlist = async () => {
  try {
    const { data: watchlist } = await API.get(`${root}/watchlists`)
    return transformWatchlist(watchlist)
  } catch (e) {
    return []
  }
}

export const addToWatchlist = (username) => {
  return API.post(`${root}/watchlists`, {}, { params: { username } })
}

export const deleteFromWatchlist = (username) => {
  return API.delete(`${root}/watchlists?username=${username}`)
}

export const getInvestorsGroups = async () => {
  const { data } = await API.get('/discovery-list')
  const discoveryList = transformDiscoveryList(data)

  const usernamesStr = discoveryList
    .reduce((all, { usernames }) => [...all, ...usernames], [])
    .join(',')

  const usersInfo = await getInfo({ usergroup: usernamesStr })
  const usersPerformance = await getPerformance({ usergroup: usernamesStr })

  const discoveryListWithUsersData = discoveryList.map(({ tag, usernames, order }) => {
    return {
      tag,
      order,
      users: usernames.map((username) => ({
        username,
        info: usersInfo.find((user) => user.username === username),
        performance: usersPerformance.find((user) => user.username === username)
      }))
    }
  })

  return discoveryListWithUsersData
}

export const getInstrumentsPortfolio = async (username) => {
  const { data: instruments } = await API.get(`${root}/portfolio/instruments`, {
    params: { username }
  })

  return transformInstrumentsPortfolio(instruments)
}

export const getTradersPortfolio = async (username) => {
  try {
    const { data: traders } = await API.get(`${root}/portfolio/traders`, { params: { username } })
    return transformTradersPortfolio(traders)
  } catch (e) {
    return []
  }
}

export const addUserToPortfolio = async ({ brokername, username, mapping, copyAmount }) => {
  return API.post(
    `${root}/portfolio/traders`,
    {},
    { params: { username, brokername, mapping, position: copyAmount } }
  )
}

export const deleteUserFromPortfolio = async (username) => {
  return API.delete(`${root}/portfolio/traders?username=${username}`)
}

export const getBenchmarks = async () => {
  const { data } = await API.get('/benchmarks')
  return transformBenchmarks(data)
}

export const getBrokerBalance = async (brokername) => {
  try {
    const { data } = await API.get(`${root}/brokers/balance`, { params: { brokername } })

    return transformBrokerBalance(data)
  } catch (e) {
    return []
  }
}

import styled from 'styled-components'

import { Modal } from '@ui'

import { ModalContent } from '@ui/Modal/Modal.styled'
import { Button } from '@ui/Button/Button.styled'
import { TextField } from '@ui/TextField/TextField.styled'
import { SelectInput } from '@ui/Select/Select.styled'

import { media } from '@styled/media'

export const InvestorCopyModal = styled(Modal)`
  ${ModalContent} {
    width: 460px;
  }

  ${media.mobile} {
    ${ModalContent} {
      width: 100%;
    }
  }
`

export const UserRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
`

export const User = styled.div`
  display: flex;
`

export const UserRight = styled.div`
  max-width: 108px;
  margin-left: 10px;
  overflow-x: hidden;

  p {
    max-width: 108px;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:nth-child(1) {
      font-family: 'Montserrat-SemiBold';
    }

    &:nth-child(2) {
      color: #676974;
    }
  }
`

export const Stats = styled.div`
  p {
    line-height: 21px;

    &:nth-child(2) {
      color: #676974;
    }
  }
`

export const ReturnValue = styled.p`
  font-size: 'Montserrat-Medium';
  color: ${({ color }) => (color === 'green' ? '#69D200' : '#FF6E66')};
`

export const Avatar = styled.div`
  img {
    max-width: 42px;
  }
`

export const Form = styled.form`
  ${Button} {
    margin-top: 30px;
  }

  ${media.mobile} {
    ${Button} {
      font-size: var(--font-size-reg);
    }
  }
`

export const ParamsRow = styled.div`
  display: grid;
  grid-template-columns: 130px 1fr;
  grid-gap: 20px;
  margin-top: 30px;

  ${SelectInput} {
    .select-menu-paper {
      width: 210px;
    }
  }

  ${media.mobile} {
    ${SelectInput} {
      .select-menu-paper {
        width: calc(100vw - 30px - 130px - 20px - 1px);
        margin-left: 1px;
      }
    }
  }

  ${media.createMedia(400)} {
    grid-template-columns: 1fr;

    ${SelectInput} {
      .select-menu-paper {
        width: calc(100vw - 30px);
        margin-left: 0;
      }
    }
  }
`

export const InstrumentsMapping = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  & > svg {
    margin-left: 5px;
  }
`

export const CopyAmountRow = styled.div`
  position: relative;
  display: flex;
  margin-top: 20px;

  ${TextField} {
    &.text-field-root {
      label {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-left: 0;
      }

      .input-root {
        justify-content: center;
        padding-left: 152px;

        input {
          width: auto;
          margin-top: 15px;
        }
      }
    }
  }

  ${Button} {
    position: absolute;
    top: 12px;
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-top: 0;
    z-index: 50;

    &:hover {
      svg {
        fill: var(--color-primary);
      }
    }

    &:nth-of-type(1) {
      left: 12px;
    }

    &:nth-of-type(2) {
      right: 12px;
    }
  }

  ${media.createMedia(400)} {
    ${TextField}.text-field-root .input-root {
      padding-left: 40%;
    }
  }
`

export const DepositMore = styled.p`
  margin-top: 10px;
  font-family: 'Montserrat-Medium';
  color: var(--color-red);
  line-height: 21px;
`

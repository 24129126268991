import styled from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

import { BoxGray } from '@styled/components'

import starsBgLeftSrc from '@assets/img/stars-bg-left.png'
import starsBgRightSrc from '@assets/img/stars-bg-right.png'

export const Chat = styled(BoxGray)`
  position: relative;
  margin-top: 25px;
  padding: 40px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 10;
  }

  &::before {
    top: 0;
    left: 42px;
    width: 322.36px;
    height: 361.36px;
    background: url(${starsBgLeftSrc});
  }

  &::after {
    top: 30px;
    right: 40.25px;
    width: 281.75px;
    height: 248.13px;
    background: url(${starsBgRightSrc});
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
  border: 2px solid #000;

  h3 {
    text-align: center;
    line-height: 45px;
  }

  ${Button} {
    margin-top: 20px;

    &.button {
      max-width: 260px;
    }
  }
`

import styled, { css } from 'styled-components'

export const PostPreview = styled.li`
  display: flex;
  overflow: hidden;
`

export const Content = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 18px 20px;
  background: #f7f7f7;
  box-sizing: border-box;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat-SemiBold';
  font-size: var(--font-size-sm);
  line-height: 18px;
`

export const Category = styled.span`
  padding: 2px 4px;
  background: #fff;
  text-transform: capitalize;
`

export const PostedBy = styled.div`
  display: flex;
  margin-left: 20px;

  p {
    &:first-child {
      font-family: 'Montserrat-Regular';
    }

    &:last-child {
      padding-left: 3px;
      color: #676974;
    }
  }
`

export const Datetime = styled.span`
  margin-left: 20px;
  color: #676974;
`

export const Body = styled.div`
  margin-top: 20px;
  max-height: 368px;
  overflow: hidden;

  h6 {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    margin-top: 20px;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    padding-left: 1.5em;

    li {
      margin-left: 1.5em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  img {
    max-width: 100%;
    margin-top: 20px;
  }
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  font-family: 'Montserrat-SemiBold';
  color: #676974;

  span {
    margin-left: 5px;
  }
`

export const Comments = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Share = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
`

export const LikeButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 2px solid #000;
  border-radius: 50%;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;

  svg {
    transition: 0.3s;
  }
`

export const LikeButton = styled(LikeButtonBase)`
  &:hover {
    border-color: var(--color-primary);
    svg {
      fill: var(--color-primary);
    }
  }
`

export const DislikeButton = styled(LikeButtonBase)`
  &:hover {
    border-color: var(--color-red);
    svg {
      fill: var(--color-red);
    }
  }

  svg {
    transform: rotate(180deg);
  }

  ${({ active }) =>
    active &&
    css`
      border-color: var(--color-red);
      svg {
        fill: var(--color-red);
      }
    `}
`

export const Likes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60px;
  padding: 10px;
  background: var(--color-gray-light);
  box-sizing: border-box;
`

export const LikesCount = styled.span`
  margin: 8px 0;
  font-family: 'Montserrat-SemiBold';
  font-size: var(--font-size-sm);
  line-height: 18px;
`

import styled from 'styled-components'

import MuiStepper from '@mui/material/Stepper'
import MuiStep from '@mui/material/Step'
import MuiStepLabel from '@mui/material/StepLabel'
import MuiStepConnector from '@mui/material/StepConnector'

export const Stepper = styled((props) => (
  <MuiStepper
    {...props}
    connector={
      <MuiStepConnector
        classes={{
          root: 'step-connector-root',
          line: 'step-connector-line',
          completed: 'step-connector-completed',
          disabled: 'step-connector-disabled'
        }}
      />
    }
  />
))`
  /* .step-connector-active .step-connector-line {
    border-color: var(--color-primary);
  } */

  .step-connector {
    &-disabled .step-connector-line {
      border-color: var(--color-gray-light);
    }

    &-completed .step-connector-line {
      border-color: var(--color-primary);
    }
  }

  .step-connector-line {
    min-height: 35px;
    border-color: var(--color-gray-light);
  }
`

export const StepLabel = styled((props) => (
  <MuiStepLabel
    {...props}
    classes={{
      root: 'step-label-root',
      iconContainer: 'step-label-icon-container',
      completed: 'step-label-completed',
      label: 'step-label-label'
    }}
  />
))`
  font-family: 'Montserrat-Regular';
  font-size: var(--font-size-md);

  && {
    padding: 0;
  }

  .step-label-label {
    color: #000;
  }

  .step-label-completed {
    font-family: 'Montserrat-Medium';
    color: #000;
  }

  .step-label-icon-container {
    position: relative;
    width: 14px;
    height: 14px;
    margin-left: 6px;
    margin-right: 20px;
    padding-right: 0;
    background: var(--color-gray-light);
    border-radius: 50%;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 6px;
      height: 6px;
      background: #000;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }

    svg {
      display: none;
    }
  }
`

export const Step = styled((props) => (
  <MuiStep
    {...props}
    classes={{ root: 'step-root', completed: 'step-completed', disabled: 'step-disabled' }}
  />
))`
  .step-root {
    svg {
      display: none;
    }
  }

  &.step-completed .step-label-icon-container {
    background: var(--color-primary);
  }
`

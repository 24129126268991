import styled from 'styled-components'

import { BoxGray } from '@styled/components'

import { Button } from '@ui/Button/Button.styled'
import { Likes, Comments } from '../PostPreview/PostPreview.styled'

export const PostOpened = styled.div`
  h6 {
    cursor: auto;
  }

  ${Likes} {
    background: #f7f7f7;
  }

  ${Comments} {
    cursor: auto;
  }
`

export const CommentForm = styled(BoxGray).attrs({ as: 'form' })`
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  border-top: 1px solid #d2d3da;
  border-bottom: 1px solid #d2d3da;

  ${Button} {
    width: 260px;
    align-self: flex-end;
    margin-top: 20px;
  }
`

export const CommentsList = styled(BoxGray).attrs({ as: 'ul' })`
  padding: 40px 20px;
`

export const Comment = styled.li`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`

export const CommentTop = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-reg);
  line-height: 24px;

  span:nth-of-type(1) {
    font-family: 'Montserrat-SemiBold';
    margin-left: 10px;
  }
`

export const CommentAvatar = styled.div`
  width: 42px;
  height: 42px;

  img {
    max-width: 100%;
  }
`

export const CommentDatetime = styled.span`
  position: relative;
  margin-left: 10px;
  padding-left: 10px;
  color: #a5a5b1;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 18px;
    top: 50%;
    transform: translateY(-50%);
    background: #d2d3da;
  }
`

export const CommentBody = styled.div`
  margin-top: 10px;
  padding-left: 52px;
  line-height: 24px;

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  ol,
  ul {
    padding-left: 1.5em;

    li {
      margin-left: 1.5em;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    text-decoration: underline;
    color: #06c;
  }
`

export const CommentsSkeleton = styled(BoxGray)`
  padding: 40px 20px;

  & > span {
    span:not(:last-child) {
      margin-bottom: 40px;
    }
  }
`

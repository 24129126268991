import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

import { Autocomplete } from '@ui'

import * as UsersAPI from '@api/users'

import * as S from './InvestorsSearchAutocomplete.styled'

export const InvestorsSearchAutocomplete = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const useFormProps = useForm()
  const { watch } = useFormProps

  const search = watch('search')

  const {
    data: investors = [],
    isLoading: isInvestorSearching,
    mutate: searchInvestors
  } = useMutation((search) => UsersAPI.getInfo({ search }))

  const investorsUsernames = investors.map(({ username }) => username)

  useEffect(() => {
    if (search) {
      searchInvestors(search)
    }
  }, [search, searchInvestors])

  const onInvestorSelect = (username) => {
    navigate(`/${username}`)
  }

  return (
    <S.InvestorsSearchAutocomplete>
      <FormProvider {...useFormProps}>
        <Autocomplete
          name="search"
          options={investorsUsernames}
          placeholder={t('Search among 1000 investors')}
          noOptionsText={search?.length ? 'Investors not found' : 'Type investor name'}
          loading={isInvestorSearching}
          loadingText="Searching for investors..."
          onChange={(_, username) => {
            if (username) {
              onInvestorSelect(username)
            }
          }}
        />
      </FormProvider>
    </S.InvestorsSearchAutocomplete>
  )
}

import styled from 'styled-components'

export const Logo = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Montserrat-Bold';
    font-size: var(--font-size-h5);
    color: #000;
  }

  span {
    padding-left: 10px;
    transition: 0.3s;
  }
`

export const Square = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: var(--color-primary);
`

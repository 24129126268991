import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PercentStats, ContentDisplay, NoData } from '@components'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  ColumnsSettingsPopover,
  Skeleton,
  SortTriangles,
  Button
} from '@ui'

import { UsersAPI } from '@api'

import * as S from './Portfolio.styled'

import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg'
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg'

export const Portfolio = ({
  isUsersLoading,
  isTradersLoading,
  isPortfolioInstrumentsLoading,
  isInstrumentsLoading,
  users,
  instruments,
  instrumentsPortfolio,
  tradersPortfolio,
  refetchTradersPortfolio,
  canDelete
}) => {
  const { t } = useTranslation()

  const useFormProps = useForm({
    defaultValues: {
      position: true,
      pnlPercent: true,
      brokername: true,
      investedPercent: true
    }
  })
  const { watch, reset } = useFormProps

  const [orderBy, setOrderBy] = useState({
    traders: { propName: '', sort: '' },
    instruments: { propName: '', sort: '' }
  })
  const [columnsSettingsAnchor, setColumnsSettingsAnchor] = useState(null)

  const { mutate: deleteFromPortfolio } = useMutation(
    (username) => UsersAPI.deleteUserFromPortfolio(username),
    {
      onSuccess: () => {
        refetchTradersPortfolio()
        toast.success('User successfully removed')
      }
    }
  )

  const fields = [
    { label: t('Name'), propName: 'name' },
    { label: t('Position'), propName: 'position' },
    { label: t('PNL %'), propName: 'pnlPercent' },
    { label: t('Broker'), propName: 'brokername' },
    { label: t('Invested %'), propName: 'investedPercent' }
  ]

  const fieldsVisible = fields.filter(({ propName }) => {
    return propName === 'name' ? true : watch(propName)
  })

  const openColumnsSettings = ({ currentTarget }) => {
    setColumnsSettingsAnchor(currentTarget)
  }

  const closeColumnsSettings = () => {
    setColumnsSettingsAnchor(null)
  }

  const getCellContent = ({
    propName,
    position,
    amount,
    pnlPercent,
    brokername,
    investedPercent
  }) => {
    switch (propName) {
      case 'position':
        return position || amount
      case 'pnlPercent':
        return <PercentStats value={pnlPercent} />
      case 'brokername':
        return brokername
      case 'investedPercent':
        return `${parseFloat(investedPercent).toFixed(2)} %`
      default:
        return null
    }
  }

  const renderFields = ({ fields, position, pnlPercent, brokername, investedPercent }) => {
    return fields.map(({ propName }) => {
      return (
        <TableCell key={propName} align="center">
          {getCellContent({ propName, position, pnlPercent, brokername, investedPercent })}
        </TableCell>
      )
    })
  }

  const renderHeadCells = (table) => {
    return fieldsVisible.map(({ label, propName }) => {
      return (
        <TableCell key={propName}>
          <S.SortRow>
            {label}

            <SortTriangles
              onSort={(value) => {
                setOrderBy({ ...orderBy, [table]: { propName, sort: value } })
              }}
            />
          </S.SortRow>
        </TableCell>
      )
    })
  }

  const getTotalByPropName = (instruments, traders, propName) => {
    return parseFloat(
      [
        ...traders.map((trader) => trader[propName]),
        ...instruments.map((instrument) => instrument[propName])
      ].reduce((total, curr) => total + curr, 0)
    ).toFixed(2)
  }

  const withSort = (data, table) => {
    return data.sort((a, b) => {
      const { propName, sort } = orderBy[table]
      const collator = new Intl.Collator('en')

      if (propName === 'name') {
        return sort === 'asc'
          ? collator.compare(a.symbol, b.symbol)
          : collator.compare(b.symbol, a.symbol)
      }

      if (typeof a[propName] === 'number') {
        return sort === 'asc' ? a[propName] - b[propName] : b[propName] - a[propName]
      }

      return sort === 'asc'
        ? collator.compare(a[propName], b[propName])
        : collator.compare(b[propName], a[propName])
    })
  }

  const investedPercentTotal =
    !isTradersLoading && !isPortfolioInstrumentsLoading
      ? getTotalByPropName(instrumentsPortfolio, tradersPortfolio, 'investedPercent')
      : null

  const instrumentsRows =
    !isInstrumentsLoading && !isPortfolioInstrumentsLoading
      ? withSort(instrumentsPortfolio, 'instruments').map(
          ({ symbol, position, pnlPercent, brokername, investedPercent }) => {
            const {
              name: instrumentName,
              symbol: instrumentSymbol,
              logo: instrumentLogo
            } = instruments.find((instrument) => instrument.symbol === symbol)

            return (
              <TableRow key={symbol}>
                <TableCell>
                  <S.Img>
                    <img src={instrumentLogo} alt={instrumentName} />
                  </S.Img>

                  <S.Info>
                    <Typography>{instrumentSymbol}</Typography>
                    <Typography>{instrumentName}</Typography>
                  </S.Info>
                </TableCell>

                {renderFields({
                  fields: fieldsVisible.filter(({ propName }) => propName !== 'name'),
                  position,
                  pnlPercent,
                  brokername,
                  investedPercent
                })}
              </TableRow>
            )
          }
        )
      : null

  const tradersRows =
    !isTradersLoading && !isUsersLoading
      ? withSort(tradersPortfolio, 'traders')?.map(
          ({ traderUsername, pnlPercent, brokername, investedPercent, amount }) => {
            const userData = users?.find((user) => user.username === traderUsername)

            return (
              <TableRow key={traderUsername}>
                <TableCell>
                  <S.Img>
                    <img src={userData?.avatar} alt={traderUsername} />
                  </S.Img>

                  <S.Info>
                    <Link to={`/${traderUsername}`}>{traderUsername}</Link>
                    <Typography>{userData?.fullName}</Typography>
                  </S.Info>

                  {canDelete && (
                    <Button
                      variant="inline"
                      onClick={() => {
                        deleteFromPortfolio(traderUsername)
                      }}
                    >
                      <TrashIcon />
                    </Button>
                  )}
                </TableCell>

                {renderFields({
                  fields: fieldsVisible.filter(({ propName }) => propName !== 'name'),
                  position: amount,
                  pnlPercent,
                  brokername,
                  investedPercent
                })}
              </TableRow>
            )
          }
        )
      : null

  return (
    <S.Portfolio>
      <ContentDisplay
        isLoading={
          isTradersLoading ||
          isPortfolioInstrumentsLoading ||
          isInstrumentsLoading ||
          isUsersLoading
        }
        skeleton={<Skeleton height={900} />}
        noData={<NoData heading={t('Portfolio is empty')} />}
      >
        {tradersPortfolio.length || instrumentsPortfolio.length ? (
          <>
            {instrumentsPortfolio.length ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>{renderHeadCells('instruments')}</TableRow>
                  </TableHead>

                  <TableBody>{instrumentsRows}</TableBody>
                </Table>

                <S.FilterRow>
                  <S.FilterButton
                    active={Boolean(columnsSettingsAnchor)}
                    onClick={openColumnsSettings}
                  >
                    <FilterIcon />
                  </S.FilterButton>

                  <FormProvider {...useFormProps}>
                    <ColumnsSettingsPopover
                      anchorEl={columnsSettingsAnchor}
                      onClose={closeColumnsSettings}
                      fields={fields.filter(({ propName }) => propName !== 'name')}
                      onReset={() => {
                        reset({
                          position: true,
                          pnlPercent: true,
                          brokername: true,
                          investedPercent: true
                        })

                        closeColumnsSettings()
                      }}
                    />
                  </FormProvider>
                </S.FilterRow>
              </TableContainer>
            ) : null}

            {tradersPortfolio.length ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>{renderHeadCells('traders')}</TableRow>
                  </TableHead>

                  <TableBody>{tradersRows}</TableBody>
                </Table>
              </TableContainer>
            ) : null}

            {fieldsVisible.some((field) => field.propName === 'investedPercent') && (
              <S.TotalRow>
                {fieldsVisible.slice(1).map(({ propName }) => (
                  <div key={propName} />
                ))}

                {/* <Typography>Total:</Typography> */}

                <S.TotalCell>
                  <Typography>{investedPercentTotal} %</Typography>
                </S.TotalCell>
              </S.TotalRow>
            )}
          </>
        ) : null}
      </ContentDisplay>
    </S.Portfolio>
  )
}

import React, { forwardRef } from 'react'
import Typography from '@mui/material/Typography'

import * as S from './Modal.styled'

import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg'

export const Modal = forwardRef(({ title, children, ...props }, ref) => {
  return (
    <S.Modal ref={ref} {...props}>
      <S.ModalContent>
        {title && <Typography variant="h3">{title}</Typography>}

        {children}

        <S.ButtonClose onClick={props.onClose}>
          <CrossIcon />
        </S.ButtonClose>
      </S.ModalContent>
    </S.Modal>
  )
})

import styled, { css } from 'styled-components'

import MuiTextField from '@mui/material/TextField'

export const TextField = styled(({ labelShrink, ...props }) => (
  <MuiTextField
    {...props}
    variant="standard"
    classes={{ root: 'text-field-root' }}
    InputLabelProps={{
      classes: {
        focused: 'label-focused',
        error: 'label-error',
        shrink: 'label-shrink',
        disabled: 'label-disabled'
      },
      ...props.InputLabelProps
    }}
    InputProps={{
      classes: {
        root: 'input-root',
        error: 'input-error',
        adornedStart: 'input-adorned-start',
        adornedEnd: 'input-adorned-end',
        disabled: 'input-disabled'
      },
      ...props.InputProps
    }}
    FormHelperTextProps={{
      classes: {
        root: 'helper-text-root',
        error: 'helper-text-error'
      }
    }}
  />
))`
  position: relative;
  width: 100%;
  height: 64px;
  background: #fff;
  box-sizing: border-box;

  label {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: var(--font-size-md);
    line-height: 18px;
    transform: translate(0, 24px) scale(1);
    transition: 0.3s;
    z-index: 10;

    &.label-shrink {
      transform: translate(0, 6px);
      font-size: var(--font-size-sm);
    }

    &.label-error {
      color: var(--color-gray-dark);
    }

    &.label-focused {
      color: var(--color-gray-dark);
    }

    &.label-disabled {
      color: var(--color-gray-dark);
    }
  }

  .input-root {
    display: flex;
    height: 100%;
    padding: 0 20px;
    margin-top: 0;
    border: 1px solid var(--color-gray-light);
    border-radius: 0;

    &:hover {
      border-color: var(--color-gray-light);
    }

    &.input-adorned-end input {
      width: calc(100% - 68px);
    }

    &.input-error {
      border-color: var(--color-red);
      background: rgba(255, 104, 104, 0.05);
    }

    &.input-disabled {
      background: var(--color-gray-light);

      .MuiInputAdornment-positionStart p {
        -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
      }
    }

    .MuiInputAdornment {
      &-positionStart {
        margin-top: 15px;
        margin-right: 5px;

        p {
          color: #000;
        }
      }

      &-positionEnd {
        height: 40px;
        max-height: 100%;
        margin-left: auto;

        p {
          color: #a5a5b1;
        }
      }
    }

    input {
      padding: 0;
      margin-top: 15px;
      font-family: 'Montserrat-Regular';
      font-size: var(--font-size-md);
      color: #1b1b1b;
      box-sizing: border-box;

      &::placeholder {
        color: var(--color-gray-dark);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &::before,
    &::after {
      display: none;
    }
  }

  .helper-text-root {
    position: absolute;
    left: 0;
    bottom: -24px;
    margin-top: 0;
    font-family: 'Montserrat-Regular';
    font-size: var(--font-size-sm);
    color: var(--color-gray-dark);
    line-height: 18px;

    &.helper-text-error {
      color: var(--color-red);
    }
  }

  & .input-error {
    color: var(--color-red);
  }

  ${({ type }) => {
    return (
      type === 'number' &&
      css`
        .input-root input {
          margin-top: 0;
        }
      `
    )
  }}

  ${({ labelShrink }) =>
    labelShrink &&
    css`
      label {
        transform: translate(0, 6px);
        font-size: var(--font-size-sm);
      }
    `}
`

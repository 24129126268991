import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import * as S from './TextEditor.styled'

import { ReactComponent as BoldIcon } from '@assets/icons/editor/bold.svg'
import { ReactComponent as ItalicIcon } from '@assets/icons/editor/italic.svg'
import { ReactComponent as UnderlineIcon } from '@assets/icons/editor/underline.svg'
import { ReactComponent as ListBulletIcon } from '@assets/icons/editor/list-bullet.svg'
import { ReactComponent as ListOrderedIcon } from '@assets/icons/editor/list-ordered.svg'
import { ReactComponent as LinkIcon } from '@assets/icons/editor/link.svg'

const icons = Quill.import('ui/icons')

icons['bold'] = ReactDOMServer.renderToString(<BoldIcon />)
icons['italic'] = ReactDOMServer.renderToString(<ItalicIcon />)
icons['underline'] = ReactDOMServer.renderToString(<UnderlineIcon />)
icons['list-bullet'] = ReactDOMServer.renderToString(<ListBulletIcon />)
icons['list-ordered'] = ReactDOMServer.renderToString(<ListOrderedIcon />)
icons['link'] = ReactDOMServer.renderToString(<LinkIcon />)

export const TextEditor = ({ value, placeholder, onChange }) => {
  const modules = {
    toolbar: [['bold', 'italic', 'underline', { list: 'bullet' }, { list: 'ordered' }, 'link']]
  }

  return (
    <S.TextEditor modules={modules} value={value} placeholder={placeholder} onChange={onChange} />
  )
}

const currencies = [
  { label: 'USD', symbol: '$' },
  { label: 'EUR', symbol: '€' },
  { label: 'RUB', symbol: '₽' },
  { label: 'GBP', symbol: '£' },
  { label: 'HKD', symbol: 'HK$' }
]

export const getCurrencySymbol = (currency) => {
  return currencies.find(({ label }) => label === currency).symbol
}

export const getCurrencyLabel = (currency) => {
  const symbol = getCurrencySymbol(currency)

  return `${symbol} ${currency}`
}

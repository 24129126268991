import styled from 'styled-components'

import { BrokersSlider } from '@components/BrokersSlider/BrokersSlider.styled'
import { TradersSlider } from '@components/TradersSlider/TradersSlider.styled'
import { GetStartedCircle } from '@components/GetStartedCircle/GetStartedCircle.styled'

import { media } from '@styled/media'

const media1170 = media.createMedia(1170)
const media660 = media.createMedia(660)

export const MainPage = styled.div`
  overflow-x: hidden;
`

export const Content = styled.div``

export const Top = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;

  ${media1170} {
    flex-direction: column;
    max-width: 900px;
    margin: 0 auto;
  }
`

export const Brokers = styled.div`
  display: flex;
  font-size: 64px;

  h2 {
    margin-left: 40px;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  ${media1170} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    h2 {
      margin-left: 0;
      margin-right: 40px;
    }
  }

  ${media.tablet} {
    h2 {
      font-size: 48px;
      line-height: 48px;
      margin-right: 20px;
    }
  }

  ${media660} {
    h2 {
      margin-right: 5px;
    }
  }

  ${media.createMedia(500)} {
    ${BrokersSlider} {
      width: 76px;
      height: 114px;

      .swiper-slide {
        width: 76px;

        svg {
          transform: scale(0.6);
        }
      }
    }

    h2 {
      margin-right: 0;
      font-size: 32px;
      line-height: 37.5px;
    }
  }
`

export const Traders = styled.div`
  position: relative;
  display: flex;

  ${TradersSlider} {
    position: absolute;
    right: 0;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 260px;
      height: 146px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      z-index: 10;
    }
  }

  h2 {
    position: relative;
    margin-top: 150px;
    width: 494px;
    height: 225px;
    z-index: 15;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  ${media1170} {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;

    ${TradersSlider} {
      position: relative;
      right: auto;
      flex-shrink: 0;

      &::after {
        display: none;
      }
    }

    h2 {
      width: auto;
      height: auto;
      padding-left: 40px;
      margin-top: 0;
    }
  }

  ${media.tablet} {
    margin-top: 40px;

    h2 {
      font-size: 48px;
      line-height: 48px;
      padding-left: 20px;
    }
  }

  ${media660} {
    flex-direction: row;

    ${TradersSlider}::after {
      display: block;
    }

    h2 {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding-left: 130px;
      box-sizing: border-box;
    }
  }

  ${media.createMedia(500)} {
    padding-bottom: 40px;

    ${TradersSlider} {
      width: 152px;
      height: 158px;

      &::after {
        display: block;
        width: 152px;
        height: 68px;
      }
    }

    h2 {
      top: 86px;
      bottom: auto;
      margin-top: 0;
      padding-left: 52px;
      font-size: 32px;
      line-height: 37.5px;
    }
  }
`

export const Bottom = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${GetStartedCircle} {
    margin-left: 80px;
    cursor: pointer;
  }

  h2 {
    max-width: 310px;
  }

  ${media1170} {
    max-width: 900px;
    justify-content: center;
    margin-top: 80px;
    margin: 80px auto 0;
  }

  ${media.tablet} {
    margin-top: 40px;

    h2 {
      font-size: 48px;
      line-height: 48px;
    }
  }

  ${media660} {
    margin-top: 85px;
  }

  ${media.createMedia(530)} {
    ${GetStartedCircle} {
      margin-left: 40px;
    }
  }

  ${media.createMedia(500)} {
    justify-content: space-between;
    margin-top: 50px;

    h2 {
      min-width: 134px;
      font-size: 26px;
      line-height: 32px;
    }

    ${GetStartedCircle} {
      margin-left: 0;
      transform: scale(0.7);
    }
  }
`

export const LineFirst = styled.div`
  position: absolute;
  top: 10px;
  left: 420px;
  width: 440.5px;
  height: 190.03px;

  @keyframes dash {
    from {
      stroke-dashoffset: 1587.681396484375;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dashMobile {
    from {
      stroke-dashoffset: 496.80145263671875;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes show {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  svg {
    &:nth-child(1) {
      position: absolute;
      left: -9px;
      bottom: -8.5px;
      animation: show 0.3s forwards;
      animation-delay: 2s;
      opacity: 0;
    }

    &:nth-child(2) path {
      stroke-dasharray: 1587.681396484375;
      stroke-dashoffset: 1587.681396484375;
      stroke-width: 3;
      animation: dash 1.5s linear alternate forwards;
      animation-delay: 2s;
    }

    &:nth-child(3) {
      position: absolute;
      right: -15px;
      top: 43px;
      animation: show 0.3s forwards;
      animation-delay: 2.8s;
      opacity: 0;
    }
  }

  ${media.createMedia(500)} {
    width: 152.58px;
    height: 129px;
    top: 99px;
    left: 115px;
    z-index: 100;

    svg {
      &:nth-child(1) {
        top: -7.5px;
        bottom: auto;
      }

      &:nth-child(2) path {
        stroke-dasharray: 496.80145263671875;
        stroke-dashoffset: 496.80145263671875;
        animation: dashMobile 1s linear alternate forwards;
        animation-delay: 2s;
      }

      &:nth-child(3) {
        bottom: -7px;
        left: 41px;
        right: auto;
        top: auto;
      }
    }
  }
`

export const LineSecond = styled.div`
  position: absolute;
  top: -27px;
  right: 121px;
  width: 456.79px;
  height: 190.79px;

  @keyframes dash2 {
    from {
      stroke-dashoffset: 1016.223388671875;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes dashMobile2 {
    from {
      stroke-dashoffset: 449.40777587890625;
    }

    to {
      stroke-dashoffset: 0;
    }
  }

  svg {
    &:nth-child(1) {
      position: absolute;
      left: -9px;
      bottom: 43.5px;
      animation: show 0.3s forwards;
      animation-delay: 4.5s;
      opacity: 0;
    }

    &:nth-child(2) path {
      stroke-dasharray: 1016.223388671875;
      stroke-dashoffset: 1016.223388671875;
      stroke-width: 3;
      animation: dash2 1.5s linear forwards;
      animation-delay: 3s;
    }

    &:nth-child(3) {
      position: absolute;
      right: 14px;
      top: 0;
      animation: show 0.3s forwards;
      animation-delay: 3s;
      opacity: 0;
    }
  }

  ${media.createMedia(500)} {
    width: 187.76px;
    height: 142.55px;
    top: -110px;
    left: -10px;
    right: auto;

    svg {
      &:nth-child(1) {
        left: 28px;
        top: -5.5px;
        bottom: auto;
        animation-delay: 3s;
      }

      &:nth-child(2) path {
        stroke-dasharray: 449.40777587890625;
        stroke-dashoffset: 449.40777587890625;
        animation: dashMobile2 1s linear forwards;
        animation-delay: 3s;
      }

      &:nth-child(3) {
        right: -9px;
        bottom: 13px;
        top: auto;
        animation-delay: 4s;
      }
    }
  }
`

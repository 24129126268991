import styled, { css } from 'styled-components'

export const WithViewToggler = styled.div``

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    line-height: 45px;
  }
`

export const Togglers = styled.div`
  display: flex;
`

export const ToggleButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid var(--color-gray-light);
  transition: 0.3s;
  cursor: pointer;

  &:first-child {
    border-right: none;
  }

  ${({ active }) =>
    active &&
    css`
      background: var(--color-primary);
    `}
`

import styled from 'styled-components'

import MuiTableContainer from '@mui/material/TableContainer'
import MuiTable from '@mui/material/Table'
import MuiTableHead from '@mui/material/TableHead'
import MuiTableBody from '@mui/material/TableBody'
import MuiTableRow from '@mui/material/TableRow'
import MuiTableCell from '@mui/material/TableCell'

export const Table = styled((props) => <MuiTable classes={{ root: 'table' }} {...props} />)`
  &.table {
    /* border: 1px solid #d2d3da; */
  }
`

export const TableCell = styled((props) => (
  <MuiTableCell classes={{ root: 'table-cell' }} align="left" {...props} />
))`
  &.table-cell {
    padding: 13px 10px;
    font-family: 'Montserrat-Medium';
    font-size: var(--font-size-reg);
    color: #1b1b1b;
    border-bottom: none;

    &:first-child {
      padding-left: 30px;
    }
  }
`

export const TableRow = styled((props) => (
  <MuiTableRow classes={{ root: 'table-row' }} {...props} />
))`
  .table-row {
  }
`

export const TableHead = styled((props) => (
  <MuiTableHead classes={{ root: 'table-head' }} {...props} />
))`
  &.table-head {
    position: relative;
    background: #1b1b1b;
    z-index: 50;

    ${TableCell} {
      background: #1b1b1b;
      font-family: 'Montserrat-SemiBold';
      font-size: var(--font-size-md);
      color: #fff;

      &:not(:last-child) {
        border-right: 1px solid #000000;
      }
    }
  }
`

export const TableBody = styled((props) => (
  <MuiTableBody classes={{ root: 'table-body' }} {...props} />
))`
  &.table-body {
    background: #f7f7f7;

    ${TableCell} {
      &:not(:last-child) {
        border-right: 1px solid #d2d3da;
      }
    }

    ${TableRow} {
      position: relative;

      &:not(:last-child) {
        padding-bottom: 1px;

        &::before {
          content: '';
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
          background: #d2d3da;
        }
      }

      &:nth-child(even) {
        background: var(--color-gray-light);
      }

      &:nth-child(odd) {
        background: #f7f7f7;
      }
    }
  }
`

export const TableContainer = styled((props) => <MuiTableContainer {...props} />)``

import styled from 'styled-components'

export const TradersSlider = styled.div`
  width: 260px;
  height: 296px;

  .swiper {
    width: 100%;
    height: 100%;

    &-wrapper {
      transition-timing-function: linear;
    }

    &-slide {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
`

import { API } from '@core/axios'

const root = '/users/forum'

const transformPosts = (posts) => {
  const transformComments = (comments) => {
    return comments.map((comment) => {
      const {
        comment_body: body,
        comment_datetime: datetime,
        commentby_username: username
      } = comment

      return {
        username,
        datetime,
        body
      }
    })
  }

  return posts.map((post) => {
    const {
      comments,
      likesby_list: likesBy,
      link_photo: photo,
      post_body: body,
      post_category: category,
      post_datetime: datetime,
      post_head: title,
      postby_username: postBy
    } = post

    return {
      datetime,
      postBy,
      title,
      body,
      category,
      photo,
      likesBy,
      comments: transformComments(comments)
    }
  })
}

export const getUserPosts = async (username) => {
  const { data: posts } = await API.get(root, { params: { username } })
  return transformPosts(posts)
}

export const uploadPostImg = async (img) => {
  const { data } = await API.post(`${root}/img`, img, {
    headers: {
      'Content-Type': 'image/jpeg'
    }
  })

  return data.path
}

export const createPost = async ({ photo, category, username, title, body }) => {
  let imgUrl = null

  if (photo) {
    imgUrl = await uploadPostImg(photo)
  }

  return API.post(root, {}, { params: { photo: imgUrl, category, username, head: title, body } })
}

export const deletePost = (datetime) => {
  return API.delete(`${root}?datetime=${datetime}`)
}

export const createComment = ({ datetime, username, body }) => {
  return API.post(`${root}/comments`, {}, { params: { datetime, username, commentbody: body } })
}

export const deleteComment = ({ commentDatetime, postDatetime, username }) => {
  return API.delete(
    `${root}/comments?commentdatetime=${commentDatetime}&postDatetime=${postDatetime}&username=${username}`
  )
}

export const likePost = ({ datetime, username }) => {
  return API.post(`${root}/likes`, {}, { params: { datetime, username } })
}

export const deleteLike = ({ datetime, username }) => {
  return API.delete(`${root}/likes?datetime=${datetime}&username=${username}`)
}

import React from 'react'
import Typography from '@mui/material/Typography'

import { Accordion, AccordionSummary, AccordionDetails } from '@ui'

import * as S from './Section.styled'

export const Section = ({ icon, heading, qa }) => {
  const qaAccordions = qa.map(({ q, a }) => {
    return (
      <Accordion key={q}>
        <AccordionSummary>{q}</AccordionSummary>
        <AccordionDetails>{a}</AccordionDetails>
      </Accordion>
    )
  })

  return (
    <S.Section>
      <S.Icon>{icon}</S.Icon>

      <Typography variant="h6">{heading}</Typography>

      <S.QaList>{qaAccordions}</S.QaList>
    </S.Section>
  )
}

import React, { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'
import { Auth, Hub } from 'aws-amplify'
import { toast } from 'react-toastify'

import { Notifications, PrivateRoute } from '@components'
import {
  MainPage,
  AccountSettingsPage,
  DiscoverPage,
  EntryPage,
  WatchlistPage,
  HelpPage,
  InvestorPage
} from '@pages'

import { ROUTE_NAMES } from '@core/routes'
import { AuthContext } from '@contexts/auth'
import { getCookie, setCookie } from '@utils'
import { AuthAPI } from '@api'

const stripePromise = loadStripe(
  'pk_live_51HlYxaJFeqR2A5mPEaSsCvlaOz5S6CvqhaGHkY7p9rp9B7C98ckcNfXMJCpjdw9Y8I045927gdetgVEyilfP6Pn200Ovi4nYB8'
)

export const App = () => {
  const { i18n } = useTranslation()

  const [isAuth, setAuth] = useState(false)
  const [user, setUser] = useState({
    login: '',
    email: '',
    phone: ''
  })
  const [userCognito, setUserCognito] = useState(null)
  const [isUserLoading, setUserLoading] = useState(false)

  const authToken = getCookie('token')

  useEffect(() => {
    if (window) {
      const lang = localStorage.getItem('lang')
      i18n.changeLanguage(lang)
    }
  }, [i18n])

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setCookie('token', data?.signInUserSession?.idToken?.jwtToken)
          setAuth(true)
          break
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const getUser = async () => {
      try {
        setUserLoading(true)
        const user = await AuthAPI.getUser()

        if (user) {
          const { username, attributes, storage } = user

          setAuth(true)
          setUserCognito(user)
          setUser({
            login: username,
            email: attributes.email,
            phone: storage.phone
          })
        }
      } catch (e) {
        console.log(e)
      } finally {
        setUserLoading(false)
      }
    }

    if (authToken) {
      getUser()
    }
  }, [authToken, isAuth])

  if ((authToken && !isAuth) || isUserLoading) {
    return null
  }

  return (
    <AuthContext.Provider value={{ isAuth, user, userCognito, setAuth, setUser, setUserCognito }}>
      <Elements stripe={stripePromise} options={{ locale: i18n.language }}>
        <Routes>
          <Route path={ROUTE_NAMES.MAIN} element={<MainPage />} />
          <Route path={ROUTE_NAMES.SIGN_UP} element={<EntryPage variant="sign-up" />} />
          <Route path={ROUTE_NAMES.LOGIN} element={<EntryPage variant="login" />} />
          <Route
            path={ROUTE_NAMES.FORGOT_PASSWORD}
            element={<EntryPage variant="forgot-password" />}
          />
          <Route
            path={ROUTE_NAMES.ACCOUNT_SETTINGS}
            element={<PrivateRoute component={AccountSettingsPage} />}
          />
          <Route path={ROUTE_NAMES.DISCOVER} element={<DiscoverPage />} />
          <Route
            path={ROUTE_NAMES.WATCHLIST}
            element={<PrivateRoute component={WatchlistPage} />}
          />
          <Route path={ROUTE_NAMES.HELP} element={<HelpPage />} />

          <Route path={ROUTE_NAMES.INVESTORS} element={<PrivateRoute component={InvestorPage} />}>
            <Route path="performance" element={<PrivateRoute component={InvestorPage} />} index />
            <Route path="portfolio" element={<PrivateRoute component={InvestorPage} />} />
            <Route path="forum" element={<PrivateRoute component={InvestorPage} />} />
          </Route>
        </Routes>

        <Notifications />
      </Elements>
    </AuthContext.Provider>
  )
}

import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { TextField, Button, PasswordField, CodeInput } from '@ui'

import { ROUTE_NAMES } from '@core/routes'
import * as AuthAPI from '@api/auth'

import * as S from './ForgotPasswordForm.styled'

export const ForgotPasswordForm = () => {
  const { t } = useTranslation()

  const useFormProps = useForm()
  const navigate = useNavigate()
  const { handleSubmit, getValues } = useFormProps

  const [resetStep, setResetStep] = useState(0)
  const [code, setCode] = useState('')
  const [isCodeError, setCodeError] = useState(false)

  const sendResetCode = useMutation((login) => AuthAPI.sendPasswordResetCode(login), {
    onError: ({ message }) => toast.error(message),
    onSuccess: () => {
      setResetStep(1)
    }
  })

  const resetPassword = useMutation(
    ({ login, code, password }) => AuthAPI.resetPassword(login, code, password),
    {
      onError: ({ message }) => toast.error(message),
      onSuccess: () => {
        toast.success('Password successfully changed')
        navigate(ROUTE_NAMES.LOGIN)
      }
    }
  )

  const onSendCodeSubmit = ({ login }) => {
    sendResetCode.mutate(login)
  }

  const onResetPasswordSubmit = ({ password }) => {
    if (code.length !== 6) {
      setCodeError(true)
      return
    }

    resetPassword.mutate({ login: getValues('login'), code, password })
  }

  const onResetPasswordError = () => {
    if (code.length !== 6) {
      setCodeError(true)
      return
    }
  }

  return (
    <FormProvider {...useFormProps}>
      {resetStep === 0 ? (
        <S.ForgotPasswordForm onSubmit={handleSubmit(onSendCodeSubmit)}>
          <Typography variant="body2">
            {t(
              'Enter the login you used when you joined and we’ll send you instructions to reset your password.'
            )}
          </Typography>

          <Typography variant="body2">
            {t(
              'For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.'
            )}
          </Typography>

          <TextField name="login" label={t('Login')} />

          <Button type="submit" variant="lime" isLoading={sendResetCode.isLoading}>
            {t('Send Reset Instructions')}
          </Button>
        </S.ForgotPasswordForm>
      ) : (
        <S.ResetPasswordForm onSubmit={handleSubmit(onResetPasswordSubmit, onResetPasswordError)}>
          <Typography variant="body2">We sent you a reset code to your email</Typography>

          <CodeInput
            fields={6}
            error={isCodeError && code.length !== 6}
            code={code}
            onChange={setCode}
          />

          <PasswordField name="password" label={t('New password')} />

          <Button type="submit" variant="lime" isLoading={resetPassword.isLoading}>
            {t('Reset password')}
          </Button>
        </S.ResetPasswordForm>
      )}
    </FormProvider>
  )
}

import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import Typography from '@mui/material/Typography'

import {
  PageLayout,
  UserStatisticsFilter,
  WithViewToggler,
  InvestorsCarousel,
  InvestorsTable,
  ContentDisplay,
  NoData
} from '@components'
import { Skeleton, InvestorsSearchAutocomplete } from '@ui'

import { UsersAPI } from '@api'
import { useAuth } from '@contexts/auth'
import { sortByOrderProp } from '@utils'

import * as S from './Discover.styled'

import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg'

export const DiscoverPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  const [isFilterModalOpen, setFilterModalOpen] = useState(false)
  const [investorsDisplayed, setInvestorsDisplayed] = useState([])

  const getAllInvestors = (groups) => {
    return groups.reduce((allInvestors, { users }) => [...allInvestors, ...users], [])
  }

  const { isLoading: isInvestorsGroupsLoading, mutate: getInvestorsGroups } = useMutation(
    () => UsersAPI.getInvestorsGroups(),
    {
      onSuccess: (groups) => {
        setInvestorsDisplayed(groups)
      }
    }
  )

  const { data: watchlist = [], isLoading: isWatchlistLoading } = useQuery(
    'watchlist',
    UsersAPI.getWatchlist
  )

  const {
    data: tradersPortfolio = [],
    isLoading: isTradersLoading,
    refetch: refetchTradersPortfolio
  } = useQuery(`traders-portfolio`, () => UsersAPI.getTradersPortfolio(user.login))

  const isInvestorsLoading = isInvestorsGroupsLoading || isWatchlistLoading || isTradersLoading

  useEffect(() => {
    getInvestorsGroups()
  }, [getInvestorsGroups])

  const withFollowProps = (investors) => {
    return investors.map(({ username, ...props }) => {
      const watching = watchlist.some(({ usernameWatched }) => usernameWatched === username)
      const copied = tradersPortfolio.some(({ traderUsername }) => traderUsername === username)

      return {
        ...props,
        username,
        watching,
        copied
      }
    })
  }

  const allInvestors = getAllInvestors(investorsDisplayed)
  const investorsWithFollowProps = withFollowProps(allInvestors)

  const investorsCarouselItems = sortByOrderProp(investorsDisplayed).map(({ tag, users }) => {
    const usersWithData = users.reduce((users, { username }) => {
      const findInvestorDisplayed = investorsWithFollowProps.find(
        (investor) => investor.username === username
      )

      return findInvestorDisplayed ? [...users, findInvestorDisplayed] : users
    }, [])

    return (
      <InvestorsCarousel
        key={tag}
        heading={tag}
        investors={usersWithData}
        onInvestorCopy={refetchTradersPortfolio}
      />
    )
  })

  const noData = <NoData heading="Please update filter criteria" imgVariant="2" />

  return (
    <PageLayout>
      <S.Content>
        <MediaQuery minWidth={870}>
          <UserStatisticsFilter
            users={allInvestors}
            onUsersFilter={setInvestorsDisplayed}
            onClear={() => {
              setInvestorsDisplayed(investorsDisplayed)
            }}
          />
        </MediaQuery>

        <S.InvestorsContainer>
          <InvestorsSearchAutocomplete />

          <WithViewToggler
            heading={
              <S.WithViewTogglerTopLeft>
                <Typography variant="h3" component="h1">
                  {t('Discover')}
                </Typography>

                <MediaQuery maxWidth={870}>
                  <S.FilterModalButton
                    variant="bordered"
                    onClick={() => {
                      setFilterModalOpen(true)
                    }}
                  >
                    {t('Filter')}
                    <FilterIcon />
                  </S.FilterModalButton>
                </MediaQuery>
              </S.WithViewTogglerTopLeft>
            }
            list={
              <ContentDisplay
                isLoading={isInvestorsLoading}
                skeleton={<Skeleton height={648} style={{ marginTop: 20 }} />}
                noData={noData}
              >
                {investorsDisplayed.length ? (
                  <InvestorsTable
                    investors={investorsWithFollowProps}
                    onInvestorCopy={refetchTradersPortfolio}
                  />
                ) : null}
              </ContentDisplay>
            }
            grid={
              <S.InvestorsCarouselGrid>
                <ContentDisplay
                  isLoading={isInvestorsLoading}
                  noData={noData}
                  skeleton={
                    <>
                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />

                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />

                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />

                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />

                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />

                      <Skeleton height={26} style={{ marginBottom: 20 }} />
                      <Skeleton height={373} style={{ marginBottom: 40 }} />
                    </>
                  }
                >
                  {investorsDisplayed.length ? investorsCarouselItems : null}
                </ContentDisplay>
              </S.InvestorsCarouselGrid>
            }
          />
        </S.InvestorsContainer>
      </S.Content>

      <S.FilterModal
        open={isFilterModalOpen}
        title={t('Filter')}
        onClose={() => {
          setFilterModalOpen(false)
        }}
      >
        <UserStatisticsFilter
          users={allInvestors}
          onUsersFilter={(investors) => {
            setInvestorsDisplayed(investors)
            setFilterModalOpen(false)
          }}
          onClear={() => {
            setInvestorsDisplayed(investorsDisplayed)
            setFilterModalOpen(false)
          }}
        />
      </S.FilterModal>
    </PageLayout>
  )
}

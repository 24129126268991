import styled, { css } from 'styled-components'

import { media } from '@styled/media'
import { customScrollStyles } from '@styled/customScrollStyles'

import { Logo, Square as LogoSquare } from '@components/Logo/Logo.styled'
import { Button } from '@ui/Button/Button.styled'
import { Menu } from '@ui/Menu/Menu.styled'
import { MenuItem } from '@ui/MenuItem/MenuItem.styled'

const media1010 = media.createMedia(1010)
const media360 = media.createMedia(360)

export const Nav = styled.nav`
  margin-left: 46px;
`

export const NavList = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;

  li {
    &:not(:last-child) {
      margin-right: 20px;
    }

    a {
      padding: 10px;
      font-size: var(--font-size-md);
      color: #000;
      text-decoration: none;
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }
`

export const EntryButtons = styled.div`
  display: flex;
  height: 100%;
  margin-left: 46px;

  a {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 10px;
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-md);
    color: #000;
    text-decoration: none;
  }

  .react-reveal:first-child {
    a {
      transition: 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  ${Button} {
    width: 142px;
    margin-left: 10px;
  }
`

export const LogoutBtn = styled(Button)`
  && {
    min-width: 142px;
    margin-left: 20px;
  }
`

export const UserButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  transition: 0.3s;

  button {
    padding: 0;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;

    svg {
      transition: 0.3s;
    }
  }

  svg {
    width: 25px;
    height: 25px;
  }

  a {
    margin-left: 20px;
  }

  ${media360} {
    margin-right: 10px;

    a {
      margin-left: 10px;
    }
  }
`

export const LanguageBtn = styled(Button)`
  margin-left: 20px;

  &.button::before {
    background: #fff;
  }

  ${media1010} {
    &.button {
      min-width: 40px;
      width: 40px;
      height: 40px;
    }
  }

  ${media360} {
    margin-left: 10px;
  }
`

export const Right = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin-left: auto;

  & > div {
    display: flex;
    align-items: center;
  }

  .language-popover-paper {
    margin-top: 59px;
  }

  ${media1010} {
    height: 40px;
  }
`

export const MenuBtn = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-left: 12px;
  border: none;
  outline: none;
  padding: 0;
  background: #131727;
  transition: 0.3s;
  cursor: pointer;

  span {
    height: 1.82px;
    background: #fff;
    border-radius: 2px;
    transition: 0.3s;

    &:nth-child(2) {
      width: 12.73px;
      margin-top: 4.55px;
    }

    &:nth-child(3) {
      margin-top: 4.55px;
    }

    &:nth-child(1),
    &:nth-child(3) {
      width: 20.89px;
    }
  }
`

export const MenuMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #141828;

  .react-reveal {
    animation: none !important;
  }
`

export const NavListMobile = styled.ul`
  li {
    &:not(:last-child) {
      margin-bottom: 36px;
    }

    a {
      display: inline-block;
      width: 100%;
      font-family: 'Montserrat-Medium';
      font-size: 32px;
      color: #fff;
      text-transform: capitalize;
      text-decoration: none;
      text-align: center;
    }
  }
`

export const MenuMobileButtons = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;

  .react-reveal {
    height: 100%;
  }

  ${LogoutBtn} {
    height: 100%;
    margin-left: 0;
    text-transform: capitalize;
  }

  ${EntryButtons} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-left: 0;

    ${Button}, a {
      width: 100%;
      height: 100%;
      text-transform: capitalize;
    }

    ${Button} {
      margin-left: 0;
    }

    a {
      justify-content: center;
      padding: 0;
      background: #fff;
      text-align: center;
    }
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1300;

  .react-reveal {
    animation: ${({ animated }) => !animated && 'none !important'};
  }

  ${({ isMenuOpen }) =>
    isMenuOpen &&
    css`
      ${Logo} span {
        color: #fff;
      }

      ${UserButtons} {
        transform: translateY(-100px);
      }

      ${LanguageBtn} {
        color: #fff;
        border-color: #fff;

        &.button {
          background: #fff;

          &::before {
            background: #141828;
          }

          &:hover {
            color: #000;
          }
        }
      }

      ${MenuBtn} {
        background: #fff;

        span {
          background: #141828;

          &:nth-child(1) {
            transform: rotate(45deg) translate(5px, 5px);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translate(3px, -4px);
          }
        }
      }
    `}

  ${media1010} {
    ${LogoSquare} {
      width: 40px;
      height: 40px;
    }
  }

  ${media360} {
    ${Logo} {
      span {
        padding-left: 5px;
        font-size: 20px;
      }
    }
  }
`

export const GreenCircle = styled.div`
  width: 8px;
  height: 8px;
  border: 1px solid #000000;
  background: var(--color-primary);
  border-radius: 50%;
  box-sizing: border-box;

  ${({ big }) =>
    big &&
    css`
      width: 13px;
      height: 13px;
    `}
`

export const NotificationsMenu = styled((props) => (
  <Menu {...props} classes={{ root: 'menu-root', paper: 'menu-paper', list: 'menu-list' }} />
))`
  .menu-paper {
    position: relative;
    width: 256px;
    max-height: 459px;
    margin-top: 8px;
    margin-left: -113.5px;
    padding-top: 10px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    box-shadow: none;
    border-radius: 0;
    overflow-y: hidden;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 50%;
      width: 0;
      height: 0;
      border-style: solid;
      transform: translateX(-50%);
    }

    &::before {
      border-width: 0 5px 10px 5px;
      border-color: transparent transparent #fff transparent;
      z-index: 11;
    }

    &::after {
      border-width: 0 6px 11px 6px;
      border-color: transparent transparent #a5a5b1 transparent;
      z-index: 10;
      margin-top: -2px;
    }
  }

  .menu-list {
    position: relative;
    max-height: 459px;
    padding-top: 0;
    padding-bottom: 0;
    border: 1px solid #a5a5b1;
    box-sizing: border-box;
    overflow-y: auto;

    ${customScrollStyles}
  }
`

export const NotificationMenuItem = styled(MenuItem)`
  &.menu-item-root {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 15px 15px 28px;
    white-space: normal;

    p {
      line-height: 21px;

      &:first-of-type {
        position: relative;
        font-family: 'Montserrat-SemiBold';
      }

      &:last-child {
        margin-top: 5px;
        color: #a5a5b1;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid #dddee6;
  }

  ${GreenCircle} {
    position: absolute;
    top: 5px;
    left: -15px;
  }
`

export const NotificationsBellButton = styled.button`
  position: relative;

  ${GreenCircle} {
    position: absolute;
    top: 0;
    right: 0;
  }
`

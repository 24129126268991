import styled from 'styled-components'

export const User = styled.div`
  display: flex;
  align-items: center;
`

export const UserRight = styled.div`
  margin-left: 20px;
`

export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  flex-shrink: 0;
  overflow: hidden;

  img {
    width: 100%;
  }
`

export const FullName = styled.p`
  font-family: 'Montserrat-SemiBold';
  font-size: var(--font-size-md);
  line-height: 24px;
`

export const Username = styled.p`
  margin-top: 5px;
  line-height: 21px;
`

export const Star = styled.span`
  display: inline-block;
  margin-left: 10px;
`

import styled from 'styled-components'

import { TextField } from '@ui/TextField/TextField.styled'
import { Button } from '@ui/Button/Button.styled'

export const LoginForm = styled.form`
  ${TextField} {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  a {
    display: inline-block;
    margin-top: 15px;
    color: #000;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  ${Button} {
    margin-top: 30px;
  }
`

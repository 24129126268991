import React from 'react'

import { Header, Footer } from '@components'

import * as S from './PageLayout.styled'

export const PageLayout = ({ children, animated }) => {
  return (
    <S.PageLayout>
      <S.Wrapper>
        <Header animated={animated} />

        <S.Content>{children}</S.Content>

        <Footer animated={animated} />
      </S.Wrapper>
    </S.PageLayout>
  )
}

import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { InvestorCopyModal } from '@components'

import { useAuth } from '@contexts/auth'
import { UsersAPI } from '@api'

import * as S from './InvestorCopyButton.styled'

import { ReactComponent as CopyIcon } from '@assets/icons/copy.svg'
import { ReactComponent as CopyFilledIcon } from '@assets/icons/copy-filled.svg'

export const InvestorCopyButton = ({
  small,
  copied,
  username,
  fullName,
  avatar,
  pnlYear,
  minimalCapital,
  currency,
  onCopy,
  ...props
}) => {
  const { isAuth, user } = useAuth()

  const { data: accountInfo, isLoading: isAccountInfoLoading } = useQuery('info', () =>
    UsersAPI.getInfo({ username: user.login })
  )

  const [isInvestorCopyOpen, setInvestorCopyOpen] = useState(false)

  return (
    <>
      <S.InvestorCopyButton
        {...props}
        variant={copied && !small ? 'lime' : null}
        disabled={!isAuth || accountInfo?.isTrader}
        small={small}
        onClick={() => {
          setInvestorCopyOpen(true)
        }}
      >
        <span>
          <span>
            {small ? copied ? <CopyFilledIcon /> : <CopyIcon /> : copied ? 'Copied' : 'Copy'}
          </span>
        </span>
      </S.InvestorCopyButton>

      <InvestorCopyModal
        open={isInvestorCopyOpen}
        username={username}
        fullName={fullName}
        avatar={avatar}
        pnlYear={pnlYear}
        currency={currency}
        minimalCapital={minimalCapital}
        onCopy={onCopy}
        onClose={() => {
          setInvestorCopyOpen(false)
        }}
      />
    </>
  )
}

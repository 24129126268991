import styled from 'styled-components'

import { BoxGray, NumberRangeInput } from '@styled/components'

import { SelectInput } from '@ui/Select/Select.styled'
import { Button } from '@ui/Button/Button.styled'

export { NumberRangeInput }

export const UserStatisticsFilter = styled(BoxGray).attrs({ as: 'form' })`
  padding: 20px 30px 30px;

  h4 {
    margin-bottom: 30px;
  }

  ${Button} {
    &:nth-last-child(2) {
      margin-top: 30px;
    }

    &:last-child {
      width: 100%;
      height: 24px;
      margin-top: 15px;
    }
  }
`

export const Filters = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;

  ${SelectInput} .select-menu-paper {
    width: 200px;
  }
`

export const RowLabel = styled.div`
  label {
    line-height: 24px;
    margin-bottom: 4px;
  }
`

export const CopiesNumberRow = styled(RowLabel)`
  ${NumberRangeInput} {
    margin-top: 4px;
  }
`

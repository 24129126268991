import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PercentStats } from '@components'
import { InvestorCopyButton, AddWatchlistButton, DeleteButton } from '@ui'

import { getCurrencyLabel } from '@utils'

import * as S from './InvestorCard.styled'

import { ReactComponent as StarFilledIcon } from '@assets/icons/star-filled.svg'

export const InvestorCard = ({
  info = {},
  watching,
  copied,
  performance = {},
  onUnWatch,
  onCopy
}) => {
  const { t } = useTranslation()

  const { isTrader, fullName, avatar, username, minimalCapital } = info
  const { risk, pnlYear, pnlAvg, assetsType, copiersCount, currency } = performance

  const stats = [
    { label: t('PNL (last 12M)'), value: pnlYear },
    { label: t('avg. an. PNL'), value: pnlAvg }
  ]

  const infoFields = [
    { label: t('Assets type'), value: assetsType },
    { label: t('Copiers'), value: copiersCount },
    { label: t('Currency'), value: currency ? getCurrencyLabel(currency) : null }
  ]

  const statsColumns = stats.map(({ label, value }) => {
    return (
      <S.StatsCol key={label} color={value > 0 ? 'green' : 'red'}>
        <PercentStats value={value ? value : 0} />
        <p>{label}</p>
      </S.StatsCol>
    )
  })

  const infoFieldsRows = infoFields.map(({ label, value }) => {
    return (
      <S.InfoRow key={label}>
        <S.InfoLabel>{label}</S.InfoLabel>
        <S.InfoValue>{value}</S.InfoValue>
      </S.InfoRow>
    )
  })

  return (
    <S.InvestorCard>
      <S.Top>
        <S.Avatar>
          <img src={avatar} alt={fullName} />
        </S.Avatar>

        <S.TopRight>
          <S.Username>
            {isTrader && (
              <S.Star>
                <StarFilledIcon />
              </S.Star>
            )}

            <Link to={`/${username}`}>{username}</Link>
          </S.Username>

          <S.FullName>{fullName}</S.FullName>

          <S.Risk risk={risk}>
            <span>{risk}</span> {t('risk')}
          </S.Risk>
        </S.TopRight>
      </S.Top>

      <S.Stats>{statsColumns}</S.Stats>

      <S.Info>{infoFieldsRows}</S.Info>

      <S.Bottom>
        <S.Actions>
          <InvestorCopyButton
            height="medium"
            copied={copied}
            username={username}
            fullName={fullName}
            avatar={avatar}
            pnlYear={pnlYear}
            minimalCapital={minimalCapital}
            currency={currency}
            onCopy={onCopy}
          />

          {Boolean(onUnWatch) ? (
            <DeleteButton
              onClick={() => {
                onUnWatch(username)
              }}
            />
          ) : (
            <AddWatchlistButton height="medium" username={username} added={watching} />
          )}
        </S.Actions>
      </S.Bottom>
    </S.InvestorCard>
  )
}

import React from 'react'

import { Skeleton } from '@ui'

import * as S from './Counter.styled'

export const Counter = ({ isLoading, countText, label }) => {
  return (
    <S.Counter>
      {!isLoading ? <S.Count>{countText}</S.Count> : <Skeleton width={140} height={32} />}
      <S.Label>{label}</S.Label>
    </S.Counter>
  )
}

import styled from 'styled-components'

import { Modal, Button } from '@ui'

import { WithViewToggler, Togglers } from '@components/WithViewToggler/WithViewToggler.styled'
import { InvestorsTable } from '@components/InvestorsTable/InvestorsTable.styled'
import { InvestorsCarousel } from '@components/InvestorsCarousel/InvestorsCarousel.styled'
import { InvestorCard } from '@components/InvestorCard/InvestorCard.styled'
import { UserStatisticsFilter } from '@components/UserStatisticsFilter/UserStatisticsFilter.styled'
import { NoData } from '@components/NoData/NoData.styled'

import { BoxGray } from '@styled/components'
import { media } from '@styled/media'

export const Content = styled.div`
  display: grid;
  grid-template-columns: 260px calc(100% - 300px);
  grid-gap: 40px;
  align-items: flex-start;

  ${media.createMedia(870)} {
    grid-template-columns: 1fr;

    ${UserStatisticsFilter} {
      display: none;
    }
  }
`

export const InvestorsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${WithViewToggler} {
    margin-top: 40px;
  }

  ${InvestorsTable}, ${NoData} {
    margin-top: 20px;
  }

  ${NoData} {
    padding: 20px 20px 80px 20px;
  }

  ${media.createMedia(870)} {
    ${WithViewToggler} {
      width: calc(100vw - 30px);

      ${Togglers} {
        align-self: flex-end;
      }
    }
  }

  ${media.mobile} {
    ${WithViewToggler} {
      margin-top: 30px;
    }
  }
`

export const InvestorsCarouselGrid = styled(BoxGray)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;

  ${InvestorsCarousel}:not(:last-child) {
    margin-bottom: 40px;
  }

  ${NoData} {
    margin-top: 0;
    padding: 0 0 60px 0;
  }

  ${media.createMedia(600)} {
    ${InvestorsCarousel} {
      overflow: hidden;
    }
  }

  ${media.mobile} {
    margin-top: 30px;
    padding: 0;
    background: #fff;

    ${InvestorCard} {
      background: #f7f7f7;
    }

    ${InvestorsCarousel}:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`

export const FilterModal = styled(Modal)`
  align-items: flex-start;
  overflow-y: auto;

  ${UserStatisticsFilter} {
    padding: 0;

    h4 {
      display: none;
    }
  }
`

export const FilterModalButton = styled(Button)`
  &.button {
    align-self: flex-start;
    width: auto;
    height: 40px;
    margin-top: 20px;
    font-size: var(--font-size-reg);
    padding: 10px;
    box-sizing: border-box;
  }

  &:hover {
    svg {
      fill: var(--color-primary);
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
    fill: #000;
    transtion: 0.3s;
  }
`

export const WithViewTogglerTopLeft = styled.div``

import styled, { css } from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

export const DeleteButton = styled(Button)`
  &.button {
    min-width: 50px;
    border-color: var(--color-red);

    &::before {
      background: #fff;
    }

    &:hover {
      background: var(--color-red);

      svg {
        fill: #fff;
      }
    }
  }

  ${({ small }) =>
    small &&
    css`
      &.button {
        min-width: 28px;
        width: 28px;
        height: 28px;
        border-radius: 0;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    `}
`

import styled from 'styled-components'

export const SocialButtons = styled.ul`
  display: flex;
  justify-content: space-between;

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 64px;
    background: #ffffff;
    border: 1px solid var(--color-gray-light);
    transition: 0.3s background;
    box-sizing: border-box;

    &:hover {
      border-width: 2px;
      border-color: #000;
      background: var(--color-primary);
    }

    button {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      cursor: pointer;
    }
  }
`

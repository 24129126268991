import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { ThemeProvider } from 'styled-components'
import Amplify from 'aws-amplify'
import { awsConfig } from './aws-config'

import { App } from '@components'

import { GlobalStyles } from '@styled/GlobalStyles'
import { muiTheme } from '@styled/muiTheme'
import { styledTheme } from '@styled/styledTheme'

import '@styled/fonts.css'
import './i18n'

Amplify.configure(awsConfig)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={styledTheme}>
            <GlobalStyles />

            <App />
          </ThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
)

import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import * as S from './Select.styled'

import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg'

export const Select = ({ name, defaultValue, id, label, rules, haveReset, ...props }) => {
  const { control, resetField } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field }) => {
        return (
          <S.SelectFormControl fullWidth>
            <S.InputLabel id={id}>{label}</S.InputLabel>

            <S.SelectInput {...field} {...props} id={id} />

            {field.value && haveReset ? (
              <S.RemoveIcon
                onClick={() => {
                  resetField(name)
                }}
              >
                <CrossIcon />
              </S.RemoveIcon>
            ) : null}
          </S.SelectFormControl>
        )
      }}
    />
  )
}

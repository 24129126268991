import styled, { css } from 'styled-components'

import { SortTriangles } from '@ui/SortTriangles/SortTriangles.styled'
import { PercentStats } from '@components/PercentStats/PercentStats.styled'
import { TableHead, TableBody, TableRow, TableCell } from '@ui/Table/Table.styled'
import { Button } from '@ui/Button/Button.styled'

export const SortRow = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  ${SortTriangles} {
    margin-left: 6.5px;
  }
`

export const Portfolio = styled.div`
  position: relative;

  ${TableHead} {
    button {
      width: 40px;
      height: 40px;
    }
  }

  ${TableRow} {
    display: flex;
  }

  ${TableCell} {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    &:nth-child(1) {
      position: relative;
      max-width: 388px;
      flex: 0 0 388px;
      justify-content: flex-start;

      ${Button} {
        position: absolute;
        top: 13px;
        right: 10px;
        min-width: 16px;
        height: 16px;

        svg {
          width: 16px;
          height: 16px;
          fill: #676974;
        }
      }
    }

    &:nth-child(2) {
      flex: 0 1 183px;
    }

    &:nth-child(3) {
      flex: 0 1 168px;
    }

    &:nth-child(4) {
      flex: 0 1 164px;
    }

    &:nth-child(5) {
      flex: 0 1 257px;
      padding-right: 57px;
    }
  }

  ${TableBody} {
    ${TableCell} {
      position: relative;

      &:first-child {
        &.table-cell {
          display: flex;
        }
      }
    }
  }

  ${PercentStats} {
    justify-content: center;
  }
`

export const FilterRow = styled.span`
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  align-items: center;
  width: 40px;
  height: 50px;
  z-index: 100;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -5px;
    width: 1px;
    height: 50px;
    background: #000;
  }
`

export const FilterButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  padding: 0;
  background: #1b1b1b;
  border: none;
  transition: 0.3s;
  cursor: pointer;

  svg {
    fill: #fff;
  }

  ${({ active }) =>
    active &&
    css`
      background: #424242;
      transition: 0.3s;
    `}
`

export const Img = styled.div`
  width: 42px;
  height: 42px;

  img {
    max-width: 100%;
  }
`

export const Info = styled.div`
  margin-left: 20px;
  overflow: hidden;

  p,
  a {
    max-width: 275px;
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    }
  }

  a {
    font-family: 'Montserrat-SemiBold';
    color: #1B1B1B;
    text-decoration: none;
  }

  p {
    font-family: 'Montserrat-Regular';
      color: #676974;
  }
`

export const TotalRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background: #e7e7ea;
  font-family: 'Montserrat-SemiBold';
  color: #676974;

  & > p {
    margin-right: 44px;
    font-size: var(--font-size-md);
    text-transform: uppercase;
  }

  div {
    box-sizing: border-box;
    height: 50px;

    &:nth-child(1) {
      flex: 0 0 388px;
    }

    &:nth-child(2) {
      flex: 0 1 183px;
    }

    &:nth-child(3) {
      flex: 0 1 168px;
    }

    &:nth-child(4) {
      flex: 0 1 164px;
    }

    &:nth-child(5) {
      flex: 0 1 257px;
    }

    &:not(:last-child) {
      border-right: 1px solid #e7e7ea;
    }

    &:nth-last-child(-n + 2):nth-last-child(2) {
      border-right: 1px solid #d2d3da;
    }
  }
`

export const TotalCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;

  p {
    font-size: var(--font-size-md);
  }
`

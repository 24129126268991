import React from 'react'
import Typography from '@mui/material/Typography'

import * as S from './NoData.styled'

import emptyFolder from '@assets/img/no-data.png'
import documentsImg from '@assets/img/documents.png'

export const NoData = ({ imgVariant = '1', heading, text, button }) => {
  return (
    <S.NoData>
      <S.Img>
        <img src={imgVariant === '1' ? emptyFolder : documentsImg} alt="no data" />
      </S.Img>

      <Typography variant="h6" component="p">
        {heading}
      </Typography>

      {text && <Typography>{text}</Typography>}

      {button && <S.ButtonContainer>{button}</S.ButtonContainer>}
    </S.NoData>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import { MonthlyPnl } from '../MonthlyPnl'
import { AnnualizedTotalReturns } from '../AnnualizedTotalReturns'
import { Pitch } from '../Pitch'
import { Counter } from '../Counter'

import { splitNumber, getCurrencySymbol } from '@utils'

import * as S from './Performance.styled'

export const Performance = ({
  isPerformanceLoading,
  isInfoLoading,
  isLoggedUser,
  username,
  pnlMonthly,
  copiersCount,
  pitch,
  minimalCapital,
  capital,
  currency,
  socialLinks,
  pnlAvg,
  pnlYtd,
  onChangeInfo
}) => {
  const { t } = useTranslation()

  const currencySymbol = currency ? getCurrencySymbol(currency) : ''

  return (
    <S.Performance>
      <MonthlyPnl isLoading={isPerformanceLoading} pnlMonthly={pnlMonthly} />

      <S.Grid>
        <AnnualizedTotalReturns
          username={username}
          pnlAvg={pnlAvg}
          pnlYtd={pnlYtd}
          isLoading={isPerformanceLoading}
        />

        <Pitch
          isLoading={isInfoLoading}
          isLoggedUser={isLoggedUser}
          username={username}
          pitch={pitch}
          minimalCapital={minimalCapital}
          currencySymbol={currencySymbol}
          socialLinks={socialLinks}
          onChangeInfo={onChangeInfo}
        />

        <Counter
          isLoading={isInfoLoading}
          label={t('Number Copiers')}
          countText={copiersCount ? splitNumber(copiersCount) : 0}
        />

        <Counter
          isLoading={isInfoLoading}
          label={t('Managed Capital')}
          countText={capital ? `${splitNumber(capital)} ${currencySymbol}` : 0}
        />
      </S.Grid>
    </S.Performance>
  )
}

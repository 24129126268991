import styled from 'styled-components'

import { BoxGray } from '@styled/components'

export const Counter = styled(BoxGray)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 147px;
  padding: 30px;
  box-sizing: border-box;
  white-space: nowrap;
`

export const Count = styled.p`
  font-family: 'Montserrat-Bold';
  font-size: 35px;
`

export const Label = styled.p`
  margin-top: 10px;
  font-size: var(--font-size-md);
  line-height: 24px;
`

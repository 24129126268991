import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { Button } from '@ui'

import * as S from './Chat.styled'

export const Chat = () => {
  const { t } = useTranslation()

  return (
    <S.Chat>
      <S.Content>
        <Typography variant="h3">
          {t('Still need help?')} <br /> {t('Chat with a representative')}
        </Typography>

        <Button
          href="https://helperproducts.notion.site/Help-27d57d3cc4fa4743bc16c45c595f3137"
          variant="lime"
          height="big"
          component="a"
        >
          {t('Chat')}
        </Button>
      </S.Content>
    </S.Chat>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useMutation } from 'react-query'
import Typography from '@mui/material/Typography'
import { toast } from 'react-toastify'

import { Button } from '@ui'

import { PaymentsAPI } from '@api'

import * as S from './AddCreditCardModal.styled'

export const AddCreditCardModal = ({ open, onClose, refetchCards, refetchVerifications }) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()

  const { mutate: createCard, isLoading: isCardAdding } = useMutation(
    ({ stripe, cardElement }) => PaymentsAPI.createCard({ stripe, cardElement }),
    {
      onSuccess: () => {
        toast.success('Card successfully added')
        refetchCards()
        refetchVerifications()
        onClose()
      },
      onError: ({ response }) => {
        if (response.status === 400) {
          toast.error('max 2 cards could be added')
        } else {
          toast.error('Failed to add card')
        }
      }
    }
  )

  const onCardSubmit = (e) => {
    e.preventDefault()

    const cardElement = elements.getElement(CardElement)
    createCard({ stripe, cardElement })
  }

  return (
    <S.AddCreditCardModal title={t('Add New Credit Card')} open={open} onClose={onClose}>
      <S.Form onSubmit={onCardSubmit}>
        <CardElement />

        <Typography>
          powered by <span>Stripe</span>
        </Typography>

        <Button type="submit" variant="lime" isLoading={isCardAdding}>
          {t('Submit')}
        </Button>
      </S.Form>
    </S.AddCreditCardModal>
  )
}

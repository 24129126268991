import styled from 'styled-components'
import MuiModal from '@mui/material/Modal'

import { media } from '@styled/media'

export const Modal = styled((props) => <MuiModal {...props} classes={{ root: 'modal-root ' }} />)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  position: relative;
  padding: 50px;
  margin: 15px;
  background: #f7f7f7;
  outline: none;
  box-sizing: border-box;

  h3 {
    margin-bottom: 30px;
  }

  ${media.mobile} {
    padding: 30px 16px;
    margin: 0;
    width: 100vw;
    min-height: 100vh;

    h3 {
      font-size: 25px;
      line-height: 38px;
    }
  }
`

export const ButtonClose = styled.button`
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  background: #fff;
  border: 1px solid var(--color-gray-light);
  cursor: pointer;
  box-sizing: border-box;

  ${media.mobile} {
    top: 30px;
    right: 16px;
  }
`

import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'

import { TextField, Select, MenuItem, TextEditor, Button, Skeleton, Dropzone } from '@ui'

import * as S from './CreatePost.styled'

import { ReactComponent as ImageIcon } from '@assets/icons/editor/image.svg'

export const CreatePost = ({
  avatar,
  isAvatarLoading,
  forumCategories,
  isCategoriesLoading,
  onCreate
}) => {
  const useFormProps = useForm()
  const { handleSubmit, reset, watch } = useFormProps

  const [isFormExpanded, setFormExpanded] = useState(false)
  const [isDropzoneOpen, setDropzoneOpen] = useState(false)
  const [post, setPost] = useState('')
  const [imagePreview, setImagePreview] = useState({ src: '', alt: '' })
  const [image, setImage] = useState(null)

  const uploadPicture = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => {
      setImagePreview({ src: reader.result, name: file.name })
    })
    setImage(file)
  }

  const onCreateSubmit = ({ title, category }) => {
    onCreate({ title, body: post, category, photo: image })

    setPost('')
    reset({ title: '', category: '', body: '' })
    setImage(null)
    setImagePreview({ src: '', alt: '' })
    setFormExpanded(false)
  }

  const { category = '', title = '' } = watch()

  const postDisabled = !category.length || !title.length || (!imagePreview.src && !post.length)

  const categoriesItems = forumCategories?.map((category) => {
    return (
      <MenuItem key={category} value={category}>
        {category}
      </MenuItem>
    )
  })

  return (
    <FormProvider {...useFormProps}>
      <S.CreatePost>
        <S.Avatar>
          {!isAvatarLoading ? <img src={avatar} alt={avatar} /> : <Skeleton height="100%" />}
        </S.Avatar>

        <S.Form onSubmit={handleSubmit(onCreateSubmit)}>
          <TextField
            name="title"
            label="Title"
            onClick={() => {
              setFormExpanded(true)
            }}
          />

          {isFormExpanded ? (
            <>
              {isDropzoneOpen ? (
                <Dropzone accept="image/*" multiple={false} onFileChange={uploadPicture}>
                  {imagePreview.src ? (
                    <img src={imagePreview.src} alt={imagePreview.alt} />
                  ) : (
                    <Typography>Upload picture</Typography>
                  )}
                </Dropzone>
              ) : (
                <TextEditor value={post} onChange={setPost} placeholder="Post body" />
              )}

              <S.Bottom>
                {!isCategoriesLoading ? (
                  <Select name="category" label="Select Category" rules={{ required: true }}>
                    {categoriesItems}
                  </Select>
                ) : (
                  <Skeleton width={378} height={64} />
                )}

                <Button type="submit" variant="lime" height="big" disabled={postDisabled}>
                  Post
                </Button>
              </S.Bottom>
            </>
          ) : null}
        </S.Form>

        <S.ViewToggler
          onClick={() => {
            setFormExpanded(true)

            setDropzoneOpen(isDropzoneOpen ? false : true)
          }}
        >
          {isDropzoneOpen ? 'T' : <ImageIcon />}

          {/* <label>
            <input
              name="img"
              type="file"
              accept="image/*"
              onChange={({ target }) => uploadPicture(target.files[0])}
            />
            <ImageIcon />
          </label> */}
        </S.ViewToggler>
      </S.CreatePost>
    </FormProvider>
  )
}

import styled from 'styled-components'

import MuiTooltip from '@mui/material/Tooltip'

export const Tooltip = styled((props) => (
  <MuiTooltip
    {...props}
    PopperProps={{ disablePortal: true }}
    classes={{ popper: 'tooltip-popper', tooltip: 'tooltip', tooltipArrow: 'tooltip-arrow' }}
    enterTouchDelay={0}
  />
))`
  & + .tooltip-popper {
    .tooltip {
      border-radius: 0;
      padding: 10px 13px;
      background: #000;
      font-family: 'Montserrat-Regular';
      font-size: var(--font-size-reg);
      line-height: 21px;

      a {
        color: #fff;
      }

      & > span {
        color: #000;
      }
    }
  }
`

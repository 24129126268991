import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { ContentDisplay, NoData } from '@components'
import { Skeleton } from '@ui'
import { CreatePost, PostPreview, PostOpened } from './components'

import { ForumAPI } from '@api'

import * as S from './Forum.styled'

import { ReactComponent as FireIcon } from '@assets/icons/fire.svg'
import { ReactComponent as NeweIcon } from '@assets/icons/low-brightness.svg'
import { ReactComponent as ThunderIcon } from '@assets/icons/thunder.svg'
import { ReactComponent as CrossIcon } from '@assets/icons/cross-circle.svg'

export const Forum = ({
  posts,
  account,
  username,
  investor,
  isPostsLoading,
  isAccountInfoLoading,
  forumCategories = [],
  isCategoriesLoading,
  refetchPosts
}) => {
  const { t } = useTranslation()

  const [sort, setSort] = useState('new')
  const [postOpenedDatetime, setPostOpenedDatetime] = useState('')
  const [activeCategories, setActiveCategories] = useState(forumCategories)

  const { mutate: createPost } = useMutation(
    ({ title, body, photo, category }) =>
      ForumAPI.createPost({ username, title, body, photo, category }),
    {
      onSuccess: () => {
        refetchPosts()
      }
    }
  )

  const { mutateAsync: likePost } = useMutation(
    (datetime) => ForumAPI.likePost({ datetime, username }),
    {
      onSucces: () => {
        refetchPosts()
      }
    }
  )

  const { mutateAsync: deleteLike } = useMutation(
    (datetime) => ForumAPI.deleteLike({ username, datetime }),
    {
      onSuccess: () => {
        refetchPosts()
      }
    }
  )

  const { mutate: createComment } = useMutation(
    ({ datetime, username, body }) => ForumAPI.createComment({ datetime, username, body }),
    {
      onSuccess: () => {
        refetchPosts()
      }
    }
  )

  useEffect(() => {
    setActiveCategories(forumCategories)
  }, [forumCategories])

  const getPostOpenedData = (datetime) => {
    const postOpenedData = posts.find((post) => post.datetime === datetime)

    const { category, title, body, comments, likesBy, postBy, photo } = postOpenedData

    return {
      username: username,
      postBy: postBy,
      datetime: datetime,
      category: category,
      title: title,
      body: body,
      photo,
      comments,
      commentsCount: comments.length,
      likesCount: likesBy.length,
      liked: likesBy.includes(account?.username),
      onLike: async () => {
        await likePost(datetime)
        refetchPosts()
      },
      onLikeDelete: async () => {
        await deleteLike(datetime)
        refetchPosts()
      }
    }
  }

  const sortPosts = (posts, sortBy) => {
    switch (sortBy) {
      case 'new':
        return posts.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime())
      case 'hot':
        return posts.sort((a, b) => b.comments.length - a.comments.length)
      case 'top':
        return posts.sort((a, b) => b.likesBy.length - a.likesBy.length)
      default:
        return null
    }
  }

  const filterPostsByCategory = (posts, categories) => {
    return posts.filter((post) => categories.some((category) => category === post.category))
  }

  const disableCategory = (category) => {
    setActiveCategories(activeCategories.filter((item) => item !== category))
  }

  const sorts = [
    { label: t('Hot'), value: 'hot', icon: <FireIcon /> },
    { label: t('New'), value: 'new', icon: <NeweIcon /> },
    { label: t('Top'), value: 'top', icon: <ThunderIcon /> }
  ]

  const sortButton = sorts.map(({ label, value, icon }) => {
    return (
      <li
        key={value}
        onClick={() => {
          setSort(value)
        }}
      >
        <S.SortButton active={sort === value}>
          {icon}

          <Typography variant="body2" component="span">
            {label}
          </Typography>
        </S.SortButton>
      </li>
    )
  })

  const categoriesItems = forumCategories?.map((category) => {
    return (
      <S.CategoryItem key={category} disabled={!activeCategories.includes(category)}>
        {category}

        <CrossIcon
          onClick={() => {
            disableCategory(category)
          }}
        />
      </S.CategoryItem>
    )
  })

  const postsSorted = sortPosts(filterPostsByCategory(posts, activeCategories), sort)

  const postsPreviewItems = postsSorted.map(
    ({ datetime, category, title, body, comments, likesBy, postBy, photo }) => {
      return (
        <PostPreview
          key={datetime}
          username={username}
          postBy={postBy}
          datetime={datetime}
          category={category}
          title={title}
          body={body}
          photo={photo}
          commentsCount={comments.length}
          likesCount={likesBy.length}
          liked={likesBy.includes(account?.username)}
          onLike={() => {
            likePost(datetime)
            refetchPosts()
          }}
          onLikeDelete={() => {
            deleteLike(datetime)
            refetchPosts()
          }}
          onPostOpen={() => {
            setPostOpenedDatetime(datetime)
          }}
        />
      )
    }
  )

  return (
    <S.Forum>
      {!postOpenedDatetime ? (
        <>
          <S.Content>
            <CreatePost
              avatar={account?.avatar}
              forumCategories={forumCategories}
              isAvatarLoading={isAccountInfoLoading}
              isCategoriesLoading={isCategoriesLoading}
              onCreate={createPost}
            />

            <ContentDisplay
              isLoading={isPostsLoading}
              skeleton={
                <S.PostPreviewsSkeleton>
                  <Skeleton height={45} />

                  <S.PostPreviewsSkeletonList>
                    <Skeleton count={2} height={250} />
                  </S.PostPreviewsSkeletonList>
                </S.PostPreviewsSkeleton>
              }
              noData={<NoData heading={t('Nothing found')} />}
            >
              {postsPreviewItems.length ? (
                <>
                  <S.SortButtonsList>{sortButton}</S.SortButtonsList>
                  <S.PostsPreviewsList>{postsPreviewItems}</S.PostsPreviewsList>
                </>
              ) : null}
            </ContentDisplay>
          </S.Content>

          <S.Category>
            <S.CategoryTop>
              <Typography variant="h4">{t('Category')}</Typography>

              {!isPostsLoading ? (
                <S.ButtonInlinePrimary
                  onClick={() => {
                    setActiveCategories(forumCategories)
                  }}
                >
                  {t('Clear all')}
                </S.ButtonInlinePrimary>
              ) : null}
            </S.CategoryTop>

            {!isPostsLoading ? (
              <S.CategoriesGrid>{categoriesItems}</S.CategoriesGrid>
            ) : (
              <Skeleton height={120} />
            )}
          </S.Category>
        </>
      ) : (
        <>
          <PostOpened postData={getPostOpenedData(postOpenedDatetime)} onComment={createComment} />

          <S.PostedByUser>
            <S.PostedByUserInfo>
              <S.PostedByUserInfoAvatar>
                <img src={investor?.avatar} alt={username} />
              </S.PostedByUserInfoAvatar>

              <S.PostedByUserInfoRight>
                <Typography variant="body2">{investor?.fullName}</Typography>
                <Typography>{username}</Typography>
              </S.PostedByUserInfoRight>
            </S.PostedByUserInfo>

            <S.ButtonInlinePrimary
              onClick={() => {
                setPostOpenedDatetime('')
              }}
            >
              {t('See all users posts')}
            </S.ButtonInlinePrimary>
          </S.PostedByUser>
        </>
      )}
    </S.Forum>
  )
}

import React from 'react'
import Typography from '@mui/material/Typography'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'

import { Tooltip, Skeleton } from '@ui'

import { UsersAPI } from '@api'

import * as S from './AnnualizedTotalReturns.styled'

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'

export const AnnualizedTotalReturns = ({ username, pnlAvg, pnlYtd, isPerformanceLoading }) => {
  const { t } = useTranslation()

  const { data: benchmarks = [], isLoading: isBenchmarkLoading } = useQuery(
    'benchmarks',
    UsersAPI.getBenchmarks
  )

  const { pnlYtd: pnlYtdBench, pnlAvg: pnlAvgBench, name } = benchmarks[0] || {}

  const pnlMax =
    Math.ceil(
      Math.max(...[pnlYtdBench, pnlAvgBench, pnlAvg, pnlYtd].map((value) => Math.abs(value)))
    ) || 0

  const pnlMaxHalf = parseInt(pnlMax / 2) || 0
  const pnlMaxThird = parseInt(pnlMax / 3) || 0
  const pnlMaxQuarter = parseInt(pnlMax / 4) || 0

  return (
    <S.AnnualizedTotalReturns>
      <Typography variant="h4">{t('Annualized Total Returns')}</Typography>

      {!isPerformanceLoading && !isBenchmarkLoading ? (
        <S.ChartContainer>
          <S.ChartTop>
            <S.Returns>{t('Returns')}</S.Returns>

            <S.Percents>
              <span>{pnlMax}%</span>
              <span>{pnlMaxHalf}%</span>
              <span>{pnlMaxThird}%</span>
              <span>{pnlMaxQuarter}%</span>
              <span>0%</span>
              <span>-{pnlMaxThird}%</span>
              <span>-{pnlMaxHalf}%</span>
              <span>-{pnlMax}%</span>
            </S.Percents>

            <S.Chart>
              <S.BarGreen
                direction={pnlYtd > 0}
                style={{ height: `${(Math.abs(pnlYtd) / pnlMax) * 100}%` }}
              />
              <S.BarBlack
                direction={pnlYtdBench > 0}
                style={{ height: `${(Math.abs(pnlYtdBench) / pnlMax) * 100}%` }}
              />
            </S.Chart>

            <S.Chart>
              <S.BarGreen
                direction={pnlAvg > 0}
                style={{ height: `${(Math.abs(pnlAvg) / pnlMax) * 100}%` }}
              />
              <S.BarBlack
                direction={pnlAvgBench > 0}
                style={{ height: `${(Math.abs(pnlAvgBench) / pnlMax) * 100}%` }}
              />
            </S.Chart>
          </S.ChartTop>

          <S.ChartBottom>
            <S.BarLabels>
              <Typography>{t('YTD')}</Typography>
              <Typography>{t('Since inception')}</Typography>
            </S.BarLabels>

            <S.Names>
              <S.Username>{username}</S.Username>

              <S.Benchmark>
                {t('Benchmark')}
                <Tooltip title={name} placement="top" arrow>
                  <InfoIcon />
                </Tooltip>
              </S.Benchmark>
            </S.Names>
          </S.ChartBottom>
        </S.ChartContainer>
      ) : (
        <Skeleton height={319} styles={{ marginTop: 20 }} />
      )}
    </S.AnnualizedTotalReturns>
  )
}

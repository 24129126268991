import React, { useState, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { TextField, Textarea, Button } from '@ui'

import * as UsersAPI from '@api/users'

import * as S from './UserInfoEditModal.styled'

import { ReactComponent as InstagramIcon } from '@assets/icons/social/users/instagram.svg'
import { ReactComponent as LinkedinIcon } from '@assets/icons/social/users/linkedin.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/social/users/facebook.svg'
import { ReactComponent as TwitterIcon } from '@assets/icons/social/users/twitter.svg'
import { ReactComponent as YoutubeIcon } from '@assets/icons/social/users/youtube.svg'

export const UserInfoEditModal = ({
  open,
  onClose,
  pitch = '',
  socialLinks,
  minimalCapital,
  currencySymbol,
  onChangeInfo
}) => {
  const { t } = useTranslation()

  const pitchRef = useRef()

  const useFormProps = useForm({
    defaultValues: {
      instagram: '',
      linkedin: '',
      facebook: '',
      twitter: '',
      youtube: '',
      capitalMin: minimalCapital
    }
  })
  const { handleSubmit, reset } = useFormProps

  const [pitchField, setPitchField] = useState(pitch)

  const changeInfo = useMutation(
    ({ pitchInfo, capitalMin, links }) => UsersAPI.changeInfo({ pitchInfo, capitalMin, links }),
    {
      onError: () => {
        toast.error('Failed to update info')
      },
      onSuccess: () => {
        onChangeInfo()
        toast.success('Info successfully updated')
      }
    }
  )

  useEffect(() => {
    const { instagram, linkedin, facebook, twitter, youtube } = socialLinks

    reset({
      instagram,
      linkedin,
      facebook,
      twitter,
      youtube,
      capitalMin: minimalCapital
    })

    setPitchField(pitch)
  }, [pitch, socialLinks, minimalCapital, reset, setPitchField])

  const social = [
    { name: 'instagram', label: 'Instagram', icon: <InstagramIcon /> },
    { name: 'linkedin', label: 'LinkedIn', icon: <LinkedinIcon /> },
    { name: 'facebook', label: 'Facebook', icon: <FacebookIcon /> },
    { name: 'twitter', label: 'Twitter', icon: <TwitterIcon /> },
    { name: 'youtube', label: 'Youtube', icon: <YoutubeIcon /> }
  ]

  const socialFieldsItems = social.map(({ name, label, icon }) => {
    return (
      <li key={name}>
        <TextField
          required={false}
          name={name}
          label={
            <>
              {icon} {label}
            </>
          }
          placeholder={label}
        />
      </li>
    )
  })

  const onPitchChange = ({ currentTarget }) => {
    setPitchField(currentTarget.value)
  }

  const onInfoSubmit = ({ capitalMin, instagram, linkedin, facebook, twitter, youtube }) => {
    changeInfo.mutate({
      pitchInfo: pitchRef?.current?.innerHTML,
      capitalMin,
      links: {
        instagram,
        linkedin,
        facebook,
        twitter,
        youtube
      }
    })

    onClose()
  }

  return (
    <S.UserInfoEditModal title={t('Pitch')} open={open} onClose={onClose}>
      <FormProvider {...useFormProps}>
        <S.Form onSubmit={handleSubmit(onInfoSubmit)}>
          <Textarea
            ref={pitchRef}
            value={pitchField}
            placeholder={t('Pitch')}
            onChange={onPitchChange}
          />

          <S.MinimalCapitalCopyRow>
            <Typography variant="body2">{t('Minimal capital to copy:')}</Typography>

            <TextField
              type="number"
              required={false}
              name="capitalMin"
              placeholder="1000"
              InputProps={{
                endAdornment: <InputAdornment position="end">{currencySymbol}</InputAdornment>
              }}
            />
          </S.MinimalCapitalCopyRow>

          <Typography variant="body2">{t('Social:')}</Typography>

          <S.SocialLinksList>{socialFieldsItems}</S.SocialLinksList>

          <Button type="submit" variant="lime">
            {t('Save')}
          </Button>
        </S.Form>
      </FormProvider>
    </S.UserInfoEditModal>
  )
}

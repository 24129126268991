import styled, { css } from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

import { BoxGray } from '@styled/components'
import { media } from '@styled/media'

const media400 = media.createMedia(400)

export const BillingPayments = styled(BoxGray)`
  display: flex;
  flex-direction: column;
  height: 476px;
  box-sizing: border-box;

  & > p {
    margin-top: 20px;
  }

  & > ${Button} {
    margin-top: 20px;
  }

  ${media.mobile} {
    & > p {
      font-size: var(--font-size-reg);
    }

    & > ${Button} {
      font-size: var(--font-size-reg);
    }
  }

  ${media400} {
    height: auto;
  }
`

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Button} {
    width: 84px;
    height: 40px;
  }

  ${media.mobile} {
    h6 {
      font-size: var(--font-size-md);
    }

    ${Button} {
      font-size: var(--font-size-reg);
    }
  }
`

export const CardsList = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 30px;
`

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 20px;
  font-size: var(--font-size-md);
  background: #fff;
  border: 2px solid #fff;
  cursor: pointer;
  transition: 0.3s;

  ${Button} {
    margin-left: auto;
    min-width: 22px;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      border-color: var(--color-primary);
    `}

  ${media400} {
    padding: 13px 15px;

    svg {
      width: 25px;
      height: 25px;
    }

    ${Button} {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

export const CardNumber = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;

  span {
    &:not(:last-child) {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #000;
      margin-right: 5px;
    }

    &:nth-child(4),
    &:nth-child(8),
    &:nth-child(12) {
      margin-right: 10px;
    }

    &:last-child {
      font-family: 'Montserrat-SemiBold';
    }
  }

  ${media400} {
    margin-left: 10px;
    font-size: var(--font-size-reg);
  }
`

export const CardExp = styled.div`
  margin-left: 30px;

  ${media400} {
    margin-left: 10px;
    font-size: var(--font-size-reg);
  }
`

export const BalanceRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;

  svg {
    margin-left: 5px;
  }

  ${media400} {
    margin-top: 20px;
  }
`

export const Balance = styled.p`
  font-family: 'Montserrat-SemiBold';

  span {
    margin-left: 5px;
  }
`

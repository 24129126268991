import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import { useMediaQuery } from 'react-responsive'
import 'swiper/css'
import 'swiper/css/autoplay'

import * as S from './BrokersSlider.styled'

import binance from '@assets/img/brokers/binance.png'
import interactiveBrokers from '@assets/img/brokers/interactive-brokers.png'
import tinkoff from '@assets/img/brokers/tinkoff.png'

import binanceMobile from '@assets/img/brokers/binance-mobile.png'
import interactiveBrokersMobile from '@assets/img/brokers/interactive-brokers-mobile.png'
import tinkoffMobile from '@assets/img/brokers/tinkoff-mobile.png'

SwiperCore.use([Autoplay])

export const BrokersSlider = () => {
  const media500 = useMediaQuery({ query: '(max-width: 500px)' })

  const brokers = [
    { label: 'Binance', logo: media500 ? binanceMobile : binance },
    {
      label: 'Interactive brokers',
      logo: media500 ? interactiveBrokersMobile : interactiveBrokers
    },
    { label: 'Tinkoff', logo: media500 ? tinkoffMobile : tinkoff }
  ]

  const brokersSlides = brokers.map(({ label, logo }) => (
    <SwiperSlide key={label}>
      <img src={logo} alt={label} />
    </SwiperSlide>
  ))

  return (
    <S.BrokersSlider>
      <Swiper
        direction="vertical"
        speed={1000}
        autoplay={{ delay: 1, disableOnInteraction: false }}
        loop
      >
        {brokersSlides}
      </Swiper>
    </S.BrokersSlider>
  )
}

import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'

import * as S from './TradersSlider.styled'

import trader1 from '@assets/img/traders/trader-1.png'
import trader2 from '@assets/img/traders/trader-2.png'
import trader3 from '@assets/img/traders/trader-3.png'
import trader4 from '@assets/img/traders/trader-4.png'

SwiperCore.use([Autoplay])

export const TradersSlider = () => {
  const traders = [
    { label: 'trader 1', img: trader1 },
    { label: 'trader 2', img: trader2 },
    { label: 'trader 3', img: trader3 },
    { label: 'trader 4', img: trader4 }
  ]

  const tradersSlides = traders.map(({ label, img }) => (
    <SwiperSlide key={label}>
      <img src={img} alt={label} />
    </SwiperSlide>
  ))

  return (
    <S.TradersSlider>
      <Swiper
        direction="vertical"
        speed={1000}
        autoplay={{ delay: 1, disableOnInteraction: false }}
        loop
      >
        {tradersSlides}
      </Swiper>
    </S.TradersSlider>
  )
}

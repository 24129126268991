import { Auth } from 'aws-amplify'

import { setCookie, deleteCookie } from '@utils'

export const sendSignUpCode = (login, email, password) => {
  return Auth.signUp({
    username: login,
    password,
    attributes: {
      email
    }
  })
}

export const confirmSignUp = (login, confirmCode) => {
  return Auth.confirmSignUp(login, confirmCode)
}

export const resendConfirmCode = (login) => {
  return Auth.resendSignUp(login)
}

export const login = async (login, password) => {
  try {
    const {
      signInUserSession: {
        idToken: { jwtToken }
      }
    } = await Auth.signIn(login, password)

    setCookie('token', jwtToken)
  } catch ({ message }) {
    throw new Error(message)
  }
}

export const sendPasswordResetCode = (login) => {
  return Auth.forgotPassword(login)
}

export const resetPassword = (login, code, newPassword) => {
  return Auth.forgotPasswordSubmit(login, code, newPassword)
}

export const logout = async () => {
  try {
    const res = await Auth.signOut()
    deleteCookie('token')
    return res
  } catch (e) {
    throw new Error(e)
  }
}

export const changePassword = (user, oldPassword, newPassword) => {
  return Auth.changePassword(user, oldPassword, newPassword)
}

export const completeNewPassword = (user, password) => {
  return Auth.completeNewPassword(user, password)
}

export const getUser = () => {
  return Auth.currentAuthenticatedUser()
}

import styled from 'styled-components'
import MuiMenuItem from '@mui/material/MenuItem'

export const MenuItem = styled((props) => (
  <MuiMenuItem {...props} classes={{ root: 'menu-item-root' }} />
))`
  &.menu-item-root {
    padding: 0;
    background: inherit;
    box-sizing: border-box;

    &:hover {
      background: #fff;
    }
  }
`

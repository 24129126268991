import styled from 'styled-components'

import { Footer } from '@components/Footer/Footer.styled'
import { Wrapper } from '@styled/components'

import { media } from '@styled/media'

export { Wrapper }

export const PageLayout = styled.div`
  ${Wrapper} {
    flex-direction: column;
    min-height: 100vh;
    padding: 10px 15px;
    box-sizing: border-box;
  }

  ${Footer} {
    margin-top: auto;
  }
`

export const Content = styled.div`
  margin: 75px 0 60px 0;

  ${media.createMedia(500)} {
    margin: 40px 0;
  }
`

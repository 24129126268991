import styled from 'styled-components'

import { TextField } from '@ui/TextField/TextField.styled'
import { Button } from '@ui/Button/Button.styled'

import { media } from '@styled/media'

export const SignUpForm = styled.div`
  p:last-child {
    margin-top: 20px;
    color: #a5a5b1;
    line-height: 24px;
  }

  ${media.mobile} {
    p:last-child {
      margin-top: 10px;
      font-size: var(--font-size-sm);
      line-height: 18px;
    }
  }
`

export const Form = styled.form`
  ${TextField} {
    margin-bottom: 30px;
  }

  ${Button} {
    margin-top: 20px;
  }

  ${media.mobile} {
    ${TextField} {
      margin-bottom: 10px;
    }
  }
`

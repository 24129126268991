import React from 'react'

import * as S from './DeleteButton.styled'

import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg'

export const DeleteButton = ({ small, ...props }) => {
  return (
    <S.DeleteButton height="medium" variant="bordered" small={small} {...props}>
      <span>
        <span>
          <TrashIcon />
        </span>
      </span>
    </S.DeleteButton>
  )
}

import React from 'react'
import { useTranslation } from 'react-i18next'

import * as S from './ButtonEdit.styled'

import { ReactComponent as PenIcon } from '@assets/icons/pen.svg'

export const ButtonEdit = (props) => {
  const { t } = useTranslation()

  return (
    <S.ButtonEdit variant="bordered" {...props}>
      <span>
        <span>
          {t('edit')}
          <PenIcon />
        </span>
      </span>
    </S.ButtonEdit>
  )
}

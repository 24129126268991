import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { DataStep, CodeStep } from './steps'

import { useAuth } from '@contexts/auth'
import { ROUTE_NAMES } from '@core/routes'
import * as AuthAPI from '@api/auth'

import * as S from './SignUpForm.styled'

export const SignUpForm = () => {
  const { t } = useTranslation()
  const useFormProps = useForm()
  const { setAuth } = useAuth()
  const navigate = useNavigate()
  const { handleSubmit, getValues } = useFormProps

  const [authStep, setAuthStep] = useState(0)
  const [confirmCode, setConfirmCode] = useState()
  const [email, setEmail] = useState('')

  const showErrorMessage = ({ message }) => {
    toast.error(message)
  }

  const sendSignUpCode = useMutation(
    ({ login, email, password }) => AuthAPI.sendSignUpCode(login, email, password),
    {
      onError: showErrorMessage,
      onSuccess: () => setAuthStep(1)
    }
  )

  const login = useMutation(({ login, password }) => AuthAPI.login(login, password), {
    onError: showErrorMessage,
    onSuccess: () => {
      setAuth(true)
      navigate(ROUTE_NAMES.ACCOUNT_SETTINGS)
    }
  })

  const confirmSignUp = useMutation(({ login, code }) => AuthAPI.confirmSignUp(login, code), {
    onError: showErrorMessage,
    onSuccess: () => {
      login.mutate({
        login: getValues('login'),
        password: getValues('password')
      })
      setEmail('')
    }
  })

  const resendCode = useMutation(() => AuthAPI.resendConfirmCode(getValues('login')), {
    onError: showErrorMessage,
    onSuccess: () => {
      toast.success('Code has been re-sent to your email')
    }
  })

  const onCodeComplete = async (code) => {
    confirmSignUp.mutate({ login: getValues('login'), code })
  }

  const onSignUpSubmit = ({ login, email, password }) => {
    setEmail(email)
    sendSignUpCode.mutate({ login, email, password })
  }

  return (
    <S.SignUpForm>
      <FormProvider {...useFormProps}>
        <S.Form onSubmit={handleSubmit(onSignUpSubmit)}>
          {authStep === 0 ? (
            <DataStep isLoading={sendSignUpCode.isLoading} />
          ) : (
            <CodeStep
              email={email}
              code={confirmCode}
              onCodeResend={resendCode.mutate}
              onCodeChange={setConfirmCode}
              onCodeComplete={onCodeComplete}
              isLoading={confirmSignUp.isLoading}
            />
          )}
        </S.Form>
      </FormProvider>

      <Typography variant="body2">
        {t("All trading involves risk. Only risk capital you're prepared to lose.")}
      </Typography>
    </S.SignUpForm>
  )
}

import axios from 'axios'

import { getCookie } from '@utils'

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
  transformResponse: axios.defaults.transformResponse.concat((res) => {
    return res.body
  })
})

API.interceptors.request.use((config) => {
  const token = getCookie('token')
  config.headers.Authorization = `Bearer ${token}`

  return config
})

import React from 'react'
import Typography from '@mui/material/Typography'
import MediaQuery from 'react-responsive'
import { useTranslation } from 'react-i18next'

import {
  PageLayout,
  TradersSlider,
  GetStartedCircle,
  SocialButtons,
  SignUpForm,
  LoginForm,
  ForgotPasswordForm
} from '@components'

import * as S from './Entry.styled'

export const EntryPage = ({ variant }) => {
  const { t } = useTranslation()

  const getFormContent = (variant) => {
    switch (variant) {
      case 'login':
        return <LoginForm />
      case 'sign-up':
        return <SignUpForm />
      case 'forgot-password':
        return <ForgotPasswordForm />
      default:
        return null
    }
  }

  const getHeadingText = (variant) => {
    switch (variant) {
      case 'sign-up':
        return t('Create an account')
      case 'login':
        return t('Login')
      case 'forgot-password':
        return t('Forgot password?')
      default:
        return null
    }
  }

  return (
    <S.EntryPage>
      <PageLayout>
        <S.Content>
          <MediaQuery minWidth={1120}>
            <S.Left>
              <Typography variant="h1">
                {t('Follow successful')} <br /> {t('traders')}
              </Typography>

              <S.TradersSliderContainer>
                <TradersSlider />

                <Typography variant="h1" component="h2">
                  {t('Invest now')}
                </Typography>
              </S.TradersSliderContainer>

              <GetStartedCircle big />
            </S.Left>
          </MediaQuery>

          <S.Right>
            <Typography variant="h2">{getHeadingText(variant)}</Typography>

            {getFormContent(variant)}

            {/* {variant !== 'forgot-password' && (
              <S.SocialSignUp>
                <Typography variant="body2">{t('or sign up with')}</Typography>

                <SocialButtons />
              </S.SocialSignUp>
            )} */}
          </S.Right>
        </S.Content>
      </PageLayout>
    </S.EntryPage>
  )
}

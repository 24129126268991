import { createTheme } from '@mui/material/styles'

export const muiTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          marginBottom: '0'
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'Lato-Black',
      'Montserrat-Regular',
      'Montserrat-Medium',
      'Montserrat-SemiBold',
      'Montserrat-Bold',
      'RobertSans-Bold',
      'RobertSans-ExtraBold'
    ].join(','),
    fontSize: 14
  }
})

muiTheme.typography.body1 = {
  fontSize: 14
}

muiTheme.typography.body2 = {
  fontSize: 16
}

muiTheme.typography.button = {
  fontSize: 16
}

muiTheme.typography.h1 = {
  fontFamily: 'RobertSans-ExtraBold',
  fontSize: 64,
  lineHeight: '75px'
}

muiTheme.typography.h2 = {
  fontFamily: 'RobertSans-Bold',
  fontSize: 40
}

muiTheme.typography.h3 = {
  fontFamily: 'RobertSans-Bold',
  fontSize: 30
}

muiTheme.typography.h4 = {
  fontFamily: 'Montserrat-Bold',
  fontSize: 25
}

muiTheme.typography.h5 = {
  fontSize: 24
}

muiTheme.typography.h6 = {
  fontFamily: 'Montserrat-Bold',
  fontSize: 20
}

// 64px
// 40px
// 30px
// 25px
// 20px
// 16px
// 14px
// 12px

export const ROUTE_NAMES = {
  MAIN: '/',
  DISCOVER: '/discover',
  WATCHLIST: '/watchlist',
  HELP: '/help',
  PRIVACY: '/privacy-policy',
  TERMS_CONDITIONS: '/terms-conditions',
  DISCLOSURE: '/disclosure',
  SIGN_UP: '/sign-up',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  TERMS_OF_SERVICE: '/terms-of-service',

  ACCOUNT_SETTINGS: '/account',
  INVESTORS: '/:username',
  INVESTORS_PERFORMANCE: '/:username/performance',
  INVESTORS_PORTFOLIO: '/:username/portfolio',
  INVESTORS_FORUM: '/:username/forum'
}

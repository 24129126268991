import styled from 'styled-components'

export const BrokersSlider = styled.div`
  width: 160px;
  height: 225px;

  .swiper {
    width: 100%;
    height: 100%;

    &-wrapper {
      transition-timing-function: linear;
    }

    &-slide {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      background: #131727;
    }
  }
`

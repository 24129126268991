import { forwardRef } from 'react'
import styled from 'styled-components'
import MuiTextarea from '@mui/base/TextareaAutosize'

import { customScrollStyles } from '@styled/customScrollStyles'

export const TextAreaStyled = styled(MuiTextarea)`
  width: 100%;
  padding: 20px;
  background: #fff;
  font-family: 'Montserrat-Regular';
  font-size: var(--font-size-reg);
  border: 1px solid #f1f1f1;
  outline: none;
  resize: none;
  box-sizing: border-box;
  overflow-y: auto !important;

  ${customScrollStyles}
`

export const Textarea = forwardRef((props, ref) => {
  return <TextAreaStyled {...props} ref={ref} />
})

import React from 'react'

import * as S from './PercentStats.styled'

import { ReactComponent as TrendUpIcon } from '@assets/icons/trend-up.svg'
import { ReactComponent as TrendDownIcon } from '@assets/icons/trend-down.svg'

export const PercentStats = ({ value }) => {
  const trend = value > 0 ? 'up' : 'down'

  return (
    <S.PercentStats trend={trend}>
      {trend === 'up' ? <TrendUpIcon /> : <TrendDownIcon />}
      <span>{value.toFixed(2)} %</span>
    </S.PercentStats>
  )
}

import { API } from '@core/axios'

const root = '/users/cards'

const transformCards = (cards) => {
  return cards.map((card) => {
    const { brand, exp_month: expMonth, exp_year: expYear, id, last4 } = card

    return {
      id,
      brand,
      last4,
      expMonth,
      expYear
    }
  })
}

export const postCard = async (token) => {
  const { user_id: card } = await API.post(root, {}, { params: { token } })
  return card
}

export const createCard = async ({ stripe, cardElement }) => {
  const { token } = await stripe.createToken(cardElement)
  const card = await postCard(token.id)
  return card
}

export const getCards = async () => {
  const { data } = await API.get(root)
  return transformCards(data.data)
}

export const deleteCard = (cardId) => {
  return API.delete(`${root}?cardid=${cardId}`)
}

export const makePayment = (cardId, amount) => {
  return API.post('/users/payments', {}, { params: { card: cardId, amount } })
}

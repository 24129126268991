import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@ui'

import * as S from './UploadAvatar.styled'

import { ReactComponent as UserAvatarIcon } from '@assets/icons/user-avatar-placeholder.svg'

export const UploadAvatar = ({ avatar, onUploadAvatar, onDeleteAvatar }) => {
  const { t } = useTranslation()

  const [image, setImage] = useState(null)

  const uploadPicture = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.addEventListener('load', () => {
      setImage({ src: reader.result, name: file.name })
    })
    onUploadAvatar(file)
  }

  const deleteAvatar = () => {
    setImage(null)
    onDeleteAvatar()
  }

  return (
    <S.UploadAvatar>
      <S.PicturePreview>
        {image?.src || avatar ? (
          <img src={image?.src || avatar} alt="avatar" />
        ) : (
          <UserAvatarIcon />
        )}
      </S.PicturePreview>

      <S.Right>
        <label>
          <input
            id="avatar"
            name="avatar"
            type="file"
            accept="image/*"
            onChange={({ target }) => uploadPicture(target.files[0])}
          />
          {t('Upload new picture...')}
        </label>

        <Button variant="inline" onClick={deleteAvatar}>
          {t('Delete picture')}
        </Button>
      </S.Right>
    </S.UploadAvatar>
  )
}

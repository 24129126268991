import React, { useState } from 'react'
import { useMutation } from 'react-query'

import * as UsersAPI from '@api/users'
import { useAuth } from '@contexts/auth'

import * as S from './AddWatchlistButton.styled'

import { ReactComponent as StarIcon } from '@assets/icons/star.svg'
import { ReactComponent as StarFilledIcon } from '@assets/icons/star-rounded-filled.svg'

export const AddWatchlistButton = ({ username, small, added, ...props }) => {
  const { isAuth } = useAuth()

  const [addedToWatchlist, setAddedToWatchlist] = useState(added)

  const addToWatchlist = useMutation((username) => UsersAPI.addToWatchlist(username))
  const removeFromWatchlist = useMutation((username) => UsersAPI.deleteFromWatchlist(username))

  const toggleWatchlist = () => {
    if (addedToWatchlist) {
      removeFromWatchlist.mutate(username)
      setAddedToWatchlist(false)
    } else {
      addToWatchlist.mutate(username)
      setAddedToWatchlist(true)
    }
  }

  return (
    <S.AddWatchlistButton
      variant="lime"
      {...props}
      small={small}
      disabled={!isAuth}
      onClick={toggleWatchlist}
    >
      <span>
        <span>{addedToWatchlist ? <StarFilledIcon /> : <StarIcon />}</span>
      </span>
    </S.AddWatchlistButton>
  )
}

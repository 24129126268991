import styled from 'styled-components'

import { NoData } from '@components/NoData/NoData.styled'
import { AutocompleteContainer } from '@ui/Autocomplete/Autocomplete.styled'

export const Content = styled.div`
  h1 {
    text-align: center;
  }

  ${AutocompleteContainer} {
    width: 860px;
    margin: 40px auto 0;

    .autocomplete-paper {
      border: none;
    }
  }

  ${NoData} {
    margin-top: 60px;
  }
`

export const SectionsList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin-top: 60px;
`

import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { useParams, Link, useLocation } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

import { PageLayout } from '@components'
import { InvestorsSearchAutocomplete, InvestorCopyButton, Tabs, Tab } from '@ui'
import { User, Performance, Portfolio, Forum } from './components'

import { UsersAPI, UtilityAPI, ForumAPI } from '@api'

import { useAuth } from '@contexts/auth'

import * as S from './Investor.styled'

export const InvestorPage = () => {
  const { t } = useTranslation()

  const { user } = useAuth()
  const { username } = useParams()
  const { pathname } = useLocation()
  const subRoutePathname = pathname.split('/')[2]

  const [activeTab, setActiveTab] = useState(subRoutePathname || 'performance')

  const isLoggedUser = user.login === username

  const { data: accountInfo, isLoading: isAccountInfoLoading } = useQuery('info', () =>
    UsersAPI.getInfo({ username: user.login })
  )

  const { data: accountTradersPortfolio = [] } = useQuery(`traders-portfolio`, () =>
    UsersAPI.getTradersPortfolio(user.login)
  )

  const {
    data: investorInfo = {},
    isLoading: isInfoLoading,
    refetch: refetchInfo
  } = useQuery(`info-${username}`, () => UsersAPI.getInfo({ username }))

  const {
    data: investorPerformance = {},
    isLoading: isPerformanceLoading,
    refetch: refetchPerformance
  } = useQuery(`performance-${username}`, () => UsersAPI.getPerformance({ username }))

  const {
    data: users = [],
    isLoading: isUsersLoading,
    mutate: getUsersInfo
  } = useMutation((usernames) => UsersAPI.getInfo({ usergroup: usernames }))

  const {
    data: posts = [],
    isLoading: isPostsLoading,
    refetch: refetchPosts
  } = useQuery(`posts-${username}`, () => ForumAPI.getUserPosts(username))

  const { data: constants, isLoading: isConstantsLoading } = useQuery(
    'constants',
    UtilityAPI.getConstants
  )

  const {
    data: tradersPortfolio = [],
    isLoading: isTradersLoading,
    refetch: refetchTradersPortfolio
  } = useQuery(`traders-portfolio-${username}`, () => UsersAPI.getTradersPortfolio(username), {
    onSuccess: (traders) => {
      const tradersUsernamesUniqStr = [
        ...new Map(traders.map(({ traderUsername }) => [traderUsername, traderUsername])).values()
      ].join(',')

      getUsersInfo(tradersUsernamesUniqStr)
    }
  })

  const { data: instrumentsPortfolio = [], isLoading: isPortfolioInstrumentsLoading } = useQuery(
    `instruments-portfolio-${username}`,
    () => UsersAPI.getInstrumentsPortfolio(username)
  )

  const { data: instruments = [], isLoading: isInstrumentsLoading } = useQuery(
    'instruments',
    UtilityAPI.getInstruments
  )

  useEffect(() => {
    refetchInfo()
    refetchPerformance()
    refetchPosts()
  }, [username, refetchInfo, refetchPerformance, refetchPosts])

  const heading = activeTab.slice(0, 1).toUpperCase().concat(activeTab.slice(1))

  const { avatar, fullName, isTrader, pitchInfo, minimalCapital, userLinks } = investorInfo
  const { pnlYear, pnlMonthly, copiersCount, capital, currency, pnlAvg, pnlYtd } =
    investorPerformance

  const tabs = [
    { label: t('Performance'), path: 'performance' },
    { label: t('Portfolio'), path: 'portfolio' },
    { label: t('Forum'), path: 'forum' }
  ]

  const tabItems = tabs.map(({ label, path }) => {
    return (
      <Tab key={label} label={label} to={`/${username}/${path}`} component={Link} value={path} />
    )
  })

  const onTabChange = (_, value) => {
    setActiveTab(value)
  }

  const getTabContent = (activeTab) => {
    switch (activeTab) {
      case 'performance':
        return (
          <Performance
            isPerformanceLoading={isPerformanceLoading}
            isInfoLoading={isInfoLoading}
            isLoggedUser={isLoggedUser}
            username={username}
            pnlMonthly={pnlMonthly}
            copiersCount={copiersCount}
            pitch={pitchInfo}
            minimalCapital={minimalCapital}
            capital={capital}
            currency={currency}
            socialLinks={userLinks}
            pnlAvg={pnlAvg}
            pnlYtd={pnlYtd}
            onChangeInfo={refetchInfo}
          />
        )
      case 'portfolio':
        return (
          <Portfolio
            isUsersLoading={isUsersLoading}
            isTradersLoading={isTradersLoading}
            isPortfolioInstrumentsLoading={isPortfolioInstrumentsLoading}
            isInstrumentsLoading={isInstrumentsLoading}
            users={users}
            instruments={instruments}
            instrumentsPortfolio={instrumentsPortfolio}
            tradersPortfolio={tradersPortfolio}
            refetchTradersPortfolio={refetchTradersPortfolio}
            canDelete={username === user.login}
            isTrader={isTrader}
          />
        )
      case 'forum':
        return (
          <Forum
            account={accountInfo}
            username={username}
            investor={investorInfo}
            posts={posts}
            isPostsLoading={isPostsLoading}
            isAccountInfoLoading={isAccountInfoLoading}
            forumCategories={constants?.forumCategories}
            isCategoriesLoading={isConstantsLoading}
            refetchPosts={refetchPosts}
          />
        )
      default:
        return null
    }
  }

  const isCopied = accountTradersPortfolio.some(({ traderUsername }) => traderUsername === username)

  return (
    <PageLayout>
      <S.Content>
        <S.HeadingRow>
          <Typography variant="h3">{t(heading)}</Typography>
          <InvestorsSearchAutocomplete />
        </S.HeadingRow>

        <S.Top>
          <User
            isLoading={isInfoLoading}
            avatar={avatar}
            username={username}
            fullName={fullName}
            isTrader={isTrader}
          />

          <Tabs value={activeTab} onChange={onTabChange}>
            {tabItems}
          </Tabs>

          {!isLoggedUser ? (
            <InvestorCopyButton
              height="big"
              avatar={avatar}
              username={username}
              fullName={fullName}
              pnlYear={pnlYear}
              minimalCapital={minimalCapital}
              disabled={!isTrader}
              currency={currency}
              copied={isCopied}
            />
          ) : null}
        </S.Top>

        <S.TabContent>{getTabContent(activeTab)}</S.TabContent>
      </S.Content>
    </PageLayout>
  )
}

import React, { useState } from 'react'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  PageLayout,
  WithViewToggler,
  UserStatisticsFilter,
  InvestorsTable,
  InvestorCard,
  NoData,
  ContentDisplay
} from '@components'
import { Skeleton, InvestorsSearchAutocomplete, Button } from '@ui'

import { sortByOrderProp } from '@utils'
import { useAuth } from '@contexts/auth'
import { ROUTE_NAMES } from '@core/routes'
import * as UsersAPI from '@api/users'

import * as S from './Watchlist.styled'

export const WatchlistPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { user } = useAuth()
  const [usersDisplayed, setUsersDisplayed] = useState([])

  const {
    data: watchlistUsers = [],
    refetch: refetchWatchlistUsers,
    isFetching: isWatchlistUsersLoading
  } = useQuery('watchlist-users', UsersAPI.getWatchlistUsers, {
    onSuccess: (users) => {
      setUsersDisplayed(users)
    }
  })

  const removeFromWatchlist = useMutation((username) => UsersAPI.deleteFromWatchlist(username), {
    onSuccess: () => {
      toast.success('Investor removed from watchlist')
      refetchWatchlistUsers()
    },
    onError: () => {
      toast.error('Failed to remove from watchlist')
    }
  })

  const {
    data: tradersPortfolio = [],
    isLoading: isPortfolioTradersLoading,
    refetch: refetchTradersPortfolio
  } = useQuery(`traders-portfolio`, () => UsersAPI.getTradersPortfolio(user.login))

  const isUsersLoading = isWatchlistUsersLoading || isPortfolioTradersLoading

  const noData = !watchlistUsers.length ? (
    <NoData
      heading={t('Your list is empty')}
      text={t("Don't worry, click the button below and add first investor to your favorites")}
      button={
        <Button
          height="big"
          variant="bordered"
          onClick={() => {
            navigate(ROUTE_NAMES.DISCOVER)
          }}
        >
          {t('Discover Trader')}
        </Button>
      }
    />
  ) : (
    <NoData heading={t('Please update filter criteria')} imgVariant="2" />
  )

  const withCopiedProp = (users) => {
    return users.map(({ username, ...props }) => {
      const copied = tradersPortfolio.some(({ traderUsername }) => traderUsername === username)

      return {
        ...props,
        username,
        copied
      }
    })
  }

  const watchlistUsersSorted = sortByOrderProp(withCopiedProp(usersDisplayed))

  const investorsCards = watchlistUsersSorted.map(({ username, info, performance, copied }) => {
    return (
      <InvestorCard
        key={username}
        info={info}
        performance={performance}
        copied={copied}
        onUnWatch={removeFromWatchlist.mutate}
        onCopy={() => {
          refetchTradersPortfolio()
        }}
      />
    )
  })

  return (
    <S.WatchlistPage>
      <PageLayout>
        <S.Content>
          <UserStatisticsFilter
            users={watchlistUsers}
            onUsersFilter={setUsersDisplayed}
            onClear={() => {
              setUsersDisplayed(watchlistUsers)
            }}
          />

          <S.InvestorsContainer>
            <InvestorsSearchAutocomplete />

            <WithViewToggler
              heading={t('Watchlist')}
              list={
                <ContentDisplay
                  isLoading={isUsersLoading}
                  skeleton={<Skeleton height={648} style={{ marginTop: 20 }} />}
                  noData={noData}
                >
                  {usersDisplayed.length ? (
                    <InvestorsTable
                      investors={watchlistUsersSorted}
                      onUnWatch={removeFromWatchlist.mutate}
                      onInvestorCopy={refetchTradersPortfolio}
                    />
                  ) : null}
                </ContentDisplay>
              }
              grid={
                <ContentDisplay
                  isLoading={isUsersLoading}
                  skeleton={
                    <S.SkeletonGrid>
                      <Skeleton count={6} />
                    </S.SkeletonGrid>
                  }
                  noData={noData}
                >
                  {usersDisplayed.length ? (
                    <S.InvestorsCardsGrid>{investorsCards}</S.InvestorsCardsGrid>
                  ) : null}
                </ContentDisplay>
              }
            />
          </S.InvestorsContainer>
        </S.Content>
      </PageLayout>
    </S.WatchlistPage>
  )
}

import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TextField, Button, PasswordField, Tooltip } from '@ui'

import { ROUTE_NAMES } from '@core/routes'

import * as S from './BrokerConnectModal.styled'

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { ReactComponent as CheckIcon } from '@assets/icons/checkmark.svg'

export const BrokerConnectModal = ({
  open,
  brokerSelected,
  isBrokerConnected,
  isBrokerConnecting,
  onBrokerConnect,
  onClose
}) => {
  const { t } = useTranslation()

  const useFormProps = useForm()
  const { handleSubmit, reset } = useFormProps

  const { credentials, url } = brokerSelected || {}

  useEffect(() => {
    reset({ brokername: brokerSelected?.name })
  }, [brokerSelected, reset])

  const onBrokerSubmit = ({ brokername, apiKey, secretKey, login, password, apiToken }) => {
    onBrokerConnect({ brokername, apiKey, secretKey, login, password, apiToken })
    reset()
  }

  const getCredentialsLabel = (field) => {
    switch (field) {
      case 'login':
        return t('Login')
      case 'password':
        return t('Password')
      case 'apiToken':
        return t('API token')
      case 'apiKey':
        return t('API key')
      case 'secretKey':
        return t('Secret key')
      default:
        return ''
    }
  }

  const credentialsFields =
    credentials &&
    Object.keys(credentials).map((field) => {
      if (field === 'password') {
        return <PasswordField key={field} name={field} label={getCredentialsLabel(field)} />
      }

      return <TextField key={field} name={field} label={getCredentialsLabel(field)} />
    })

  return (
    <S.BrokerConnectModal title={t('Broker Credentials')} open={open} onClose={onClose}>
      <FormProvider {...useFormProps}>
        <S.Form onSubmit={handleSubmit(onBrokerSubmit)}>
          <TextField name="brokername" label={t('Broker')} disabled />

          {credentialsFields}

          <a href={url} target="_blank">
            {t('Broker website')}
          </a>

          <Typography>
            {t('How to connect broker to 4turtles')}
            <Tooltip
              placement="top"
              title={
                <>
                  {t('Detailed')} <Link to={ROUTE_NAMES.HELP}>{t('instructions')}</Link>{' '}
                  {t('of how to connect broker')}
                </>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </Typography>

          <Button type="submit" variant="lime" isLoading={isBrokerConnecting}>
            {t('Connect')}
          </Button>
        </S.Form>

        {isBrokerConnected ? (
          <S.Connected>
            <CheckIcon />
            {t('Connected')}
          </S.Connected>
        ) : null}
      </FormProvider>
    </S.BrokerConnectModal>
  )
}

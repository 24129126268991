import styled from 'styled-components'

import { Modal } from '@ui'

import { Button } from '@ui/Button/Button.styled'
import { TextField } from '@ui/TextField/TextField.styled'
import { TextAreaStyled } from '@ui/Textarea/Textarea.styled'
import { ModalContent } from '@ui/Modal/Modal.styled'

export const UserInfoEditModal = styled(Modal)`
  ${ModalContent} {
    width: 570px;
  }
`

export const Form = styled.form`
  & > p {
    margin-top: 20px;
  }

  ${TextAreaStyled} {
    min-height: 100px;
    max-height: 100px;
  }

  ${Button} {
    margin-top: 30px;
  }
`

export const MinimalCapitalCopyRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-top: 20px;

  p {
    font-family: 'Montserrat-SemiBold';
  }

  ${TextField} {
    .input-root input {
      margin-top: 0;
    }
  }
`

export const SocialLinksList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 10px;

  ${TextField} {
    label {
      transform: translate(0, 19px) scale(1);

      &.label-shrink {
        margin-left: 15px;
        transform: translate(0, 6px);

        svg {
          margin-right: 0;
          transform: scale(0.5);
        }
      }

      svg {
        margin-right: 12px;
        fill: #a5a5b1;
        transition: 0.3s;
      }
    }
  }
`

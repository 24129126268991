import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { FormProvider, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { PercentStats } from '@components'
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  InvestorCopyButton,
  AddWatchlistButton,
  ColumnsSettingsPopover,
  Skeleton,
  SortTriangles,
  DeleteButton,
  Checkbox,
  Button
} from '@ui'

import { getCurrencyLabel } from '@utils'

import * as S from './InvestorsTable.styled'

import { ReactComponent as FilterIcon } from '@assets/icons/filter.svg'

export const InvestorsTable = ({ investors, isLoading, onUnWatch, onInvestorCopy }) => {
  const { t } = useTranslation()

  const useFormProps = useForm({
    defaultValues: {
      pnlYear: true,
      pnlAvg: true,
      copiersCount: true,
      assets: true,
      currency: true
    }
  })
  const { watch, reset } = useFormProps

  const [orderBy, setOrderBy] = useState({ propName: '', sort: '' })
  const [columnsSettingsAnchor, setColumnsSettingsAnchor] = useState(null)
  const [isColumnsSettingsModalOpen, setColumnsSettingsModalOpen] = useState(false)

  const fields = [
    { label: t('Name'), propName: 'name' },
    { label: t('PNL'), propName: 'pnlYear', sortable: true },
    { label: 'Y-AVG PNL', propName: 'pnlAvg', sortable: true },
    { label: t('Copiers'), propName: 'copiersCount', sortable: true },
    { label: t('Assets'), propName: 'assets' },
    { label: t('Currency'), propName: 'currency' }
  ]

  const fieldsVisible = fields.filter(({ propName }) => {
    return propName === 'name' ? true : watch(propName)
  })

  const getInvestorsUnique = (investors) => {
    return [...new Map(investors.map((item) => [item.username, item])).values()]
  }

  const openColumnsSettings = ({ currentTarget }) => {
    setColumnsSettingsAnchor(currentTarget)
  }

  const closeColumnsSettings = () => {
    setColumnsSettingsAnchor(null)
  }

  const headCells = fieldsVisible.map(({ label, propName, sortable }) => {
    return sortable ? (
      <TableCell key={propName}>
        <S.SortRow>
          {label}

          <SortTriangles
            onSort={(value) => {
              setOrderBy({ propName, sort: value })
            }}
          />
        </S.SortRow>
      </TableCell>
    ) : (
      <TableCell key={propName}>{label}</TableCell>
    )
  })

  const getBodyCellContent = ({ propName, username, avatar, fullName, performance }) => {
    const { pnlYear, pnlAvg, assetsType, copiersCount, currency } = performance

    switch (propName) {
      case 'name':
        return (
          <>
            <S.Avatar>
              <img src={avatar} alt={username} />
            </S.Avatar>

            <S.UserCellRight>
              <Link to={`/${username}`}>{username}</Link>
              <Typography variant="body1">{fullName}</Typography>
            </S.UserCellRight>
          </>
        )
      case 'pnlYear':
        return <PercentStats value={pnlYear} />
      case 'pnlAvg':
        return <PercentStats value={pnlAvg} />
      case 'copiersCount':
        return copiersCount
      case 'assets':
        return assetsType
      case 'currency':
        return getCurrencyLabel(currency)
      default:
        return null
    }
  }

  const createBodyCells = ({
    fields,
    username,
    watching,
    copied,
    avatar,
    fullName,
    minimalCapital,
    performance
  }) => {
    return fields.map(({ propName }, idx, arr) => {
      const isLastItem = arr.length - 1 === idx
      const content = getBodyCellContent({ propName, username, avatar, fullName, performance })
      const { pnlYear, currency } = performance

      return (
        <TableCell key={propName}>
          {isLastItem ? (
            <S.ActionsRow>
              {content}

              <S.Actions>
                <InvestorCopyButton
                  small
                  copied={copied}
                  username={username}
                  fullName={fullName}
                  avatar={avatar}
                  pnlYear={pnlYear}
                  minimalCapital={minimalCapital}
                  currency={currency}
                  onCopy={onInvestorCopy}
                />

                {Boolean(onUnWatch) ? (
                  <DeleteButton
                    small
                    onClick={() => {
                      onUnWatch(username)
                    }}
                  />
                ) : (
                  <AddWatchlistButton small added={watching} username={username} />
                )}
              </S.Actions>
            </S.ActionsRow>
          ) : (
            content
          )}
        </TableCell>
      )
    })
  }

  const withSort = (investors) => {
    return getInvestorsUnique(investors).sort(
      ({ performance: performanceA }, { performance: performanceB }) => {
        const { propName, sort } = orderBy

        return sort === 'asc'
          ? performanceA[propName] - performanceB[propName]
          : performanceB[propName] - performanceA[propName]
      }
    )
  }

  const checkFieldVisibility = (propName) => {
    return fieldsVisible.some((field) => field.propName === propName)
  }

  const investorRows = withSort(investors).map(
    ({ username, watching, copied, info, performance }) => {
      const { fullName, avatar, minimalCapital } = info

      return (
        <TableRow key={username}>
          {createBodyCells({
            fields: fieldsVisible,
            username,
            watching,
            copied,
            avatar,
            fullName,
            minimalCapital,
            performance
          })}
        </TableRow>
      )
    }
  )

  const investorsCards = withSort(investors).map(
    ({ username, watching, copied, info, performance }) => {
      const { fullName, avatar, minimalCapital } = info
      const { pnlYear, pnlAvg, assetsType, copiersCount, currency } = performance

      return (
        <S.InvestorCard key={username}>
          <S.Avatar>
            <img src={avatar} alt={username} />
          </S.Avatar>

          <S.InvestorCardInfo>
            <S.InvestorCardInfoRow>
              <Link to={`/${username}`}>{username}</Link>

              <S.InvestorCardFullName>{fullName}</S.InvestorCardFullName>
            </S.InvestorCardInfoRow>

            <S.InvestorCardInfoRow>
              {checkFieldVisibility('pnlYear') ? (
                <>
                  <span>{t('PNL')}:</span>
                  <PercentStats value={pnlYear} />
                </>
              ) : null}

              {checkFieldVisibility('copiersCount') ? (
                <>
                  <span>{t('Copiers')}:</span>
                  <S.InvestorCardCopiers>{copiersCount}</S.InvestorCardCopiers>
                </>
              ) : null}
            </S.InvestorCardInfoRow>

            {checkFieldVisibility('pnlAvg') ? (
              <S.InvestorCardInfoRow>
                <span>{t('Y-AVG PNL')}:</span>
                <PercentStats value={pnlAvg} />
              </S.InvestorCardInfoRow>
            ) : null}

            <S.InvestorCardInfoRow>
              {checkFieldVisibility('assets') ? <span>{assetsType}</span> : null}
              {checkFieldVisibility('currency') ? <span>{getCurrencyLabel(currency)}</span> : null}
            </S.InvestorCardInfoRow>
          </S.InvestorCardInfo>

          <S.InvestorCardActions>
            <InvestorCopyButton
              small
              copied={copied}
              username={username}
              fullName={fullName}
              avatar={avatar}
              pnlYear={pnlYear}
              minimalCapital={minimalCapital}
              currency={currency}
              onCopy={onInvestorCopy}
            />

            {Boolean(onUnWatch) ? (
              <DeleteButton
                small
                onClick={() => {
                  onUnWatch(username)
                }}
              />
            ) : (
              <AddWatchlistButton small added={watching} username={username} />
            )}
          </S.InvestorCardActions>
        </S.InvestorCard>
      )
    }
  )

  const settingsCheckboxes = fields
    .filter(({ propName }) => propName !== 'name')
    .map(({ propName, label }) => {
      return <Checkbox key={propName} name={propName} label={label} />
    })

  return (
    <S.InvestorsTable>
      {!isLoading ? (
        <>
          <MediaQuery minWidth={798}>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>{headCells}</TableRow>
                </TableHead>

                <TableBody>{investorRows}</TableBody>
              </Table>

              <S.FilterRow>
                <S.FilterButton
                  active={Boolean(columnsSettingsAnchor)}
                  onClick={openColumnsSettings}
                >
                  <FilterIcon />
                </S.FilterButton>

                <FormProvider {...useFormProps}>
                  <ColumnsSettingsPopover
                    anchorEl={columnsSettingsAnchor}
                    onClose={closeColumnsSettings}
                    fields={fields.filter(({ propName }) => propName !== 'name')}
                    onReset={() => {
                      reset({
                        pnlYear: true,
                        pnlAvg: true,
                        copiersCount: true,
                        assets: true,
                        currency: true
                      })
                      closeColumnsSettings()
                    }}
                  />
                </FormProvider>
              </S.FilterRow>
            </TableContainer>
          </MediaQuery>

          <MediaQuery maxWidth={798}>
            <S.FilterButtonMobile
              onClick={() => {
                setColumnsSettingsModalOpen(true)
              }}
            >
              <FilterIcon />
            </S.FilterButtonMobile>

            <S.InvestorsCardsList>{investorsCards}</S.InvestorsCardsList>
          </MediaQuery>
        </>
      ) : (
        <Skeleton />
      )}

      <S.ColumnsSettingsModal
        open={isColumnsSettingsModalOpen}
        title={t('Columns Settings')}
        onClose={() => {
          setColumnsSettingsModalOpen(false)
          reset({
            pnlYear: true,
            pnlAvg: true,
            copiersCount: true,
            assets: true,
            currency: true
          })
        }}
      >
        <FormProvider {...useFormProps}>
          <S.ColumnsSettingsGrid>{settingsCheckboxes}</S.ColumnsSettingsGrid>

          <Button
            type="submit"
            variant="lime"
            onClick={() => {
              setColumnsSettingsModalOpen(false)
            }}
          >
            {t('Apply')}
          </Button>
          <Button
            variant="inline"
            onClick={() => {
              setColumnsSettingsModalOpen(false)
              reset({
                pnlYear: true,
                pnlAvg: true,
                copiersCount: true,
                assets: true,
                currency: true
              })
            }}
          >
            {t('by default')}
          </Button>
        </FormProvider>
      </S.ColumnsSettingsModal>
    </S.InvestorsTable>
  )
}

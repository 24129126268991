import { css } from 'styled-components'

export const variablesStyles = css`
  :root {
    --color-primary: #a0f64a;
    --color-red: #ff6868;
    --color-gray-dark: #9da0a8;
    --color-gray-light: #f1f1f1;
    --color-green: #69d200;
    --box-shadow: 0px 20px 40px rgba(34, 45, 61, 0.08);

    --font-size-h1: 64px;
    --font-size-h2: 40px;
    --font-size-h3: 30px;
    --font-size-h4: 25px;
    --font-size-h5: 24px;
    --font-size-h6: 20px;
    --font-size-md: 16px;
    --font-size-reg: 14px;
    --font-size-sm: 12px;
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import { useQuery, useMutation } from 'react-query'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TextField, Button, Select, Skeleton } from '@ui'

import { UsersAPI, UtilityAPI } from '@api'

import * as S from './UserStatisticsFilter.styled'

export const UserStatisticsFilter = ({ users, onUsersFilter, onClear }) => {
  const { t } = useTranslation()

  const useFormProps = useForm({
    mode: 'onChange',
    defaultValues: {
      pnlMin: '',
      pnlMax: '',
      pnlAvgMin: '',
      pnlAvgMax: '',
      copiersMin: '',
      copiersMax: '',
      risk: '',
      assetsType: '',
      currency: ''
    }
  })

  const { handleSubmit, reset, watch } = useFormProps
  const fieldsWatch = watch()

  const { data: constants = null, isLoading } = useQuery('constants', UtilityAPI.getConstants)

  const { data: usersFilteredCount, isFetching: isUsersFilteredCountLoading } = useQuery(
    ['performance-filtered-users-count', fieldsWatch],
    () => UsersAPI.getPerformanceFilteredUsersCount(fieldsWatch)
  )

  const { mutateAsync: getUsersFiltered } = useMutation(() =>
    UsersAPI.getPerformanceFiltered(fieldsWatch)
  )

  if (isLoading) return <Skeleton width={260} height={790} />

  const { assetsTypes, currencies, riskTypes } = constants || {}

  const riskMenuItems = riskTypes.map((type) => (
    <MenuItem key={type} value={type}>
      {type}
    </MenuItem>
  ))

  const assetsTypeMenuItems = assetsTypes.map((type) => (
    <MenuItem key={type} value={type}>
      {type}
    </MenuItem>
  ))

  const currencyMenuItems = currencies.map((currency) => (
    <MenuItem key={currency} value={currency}>
      {currency}
    </MenuItem>
  ))

  const onFilterSubmit = async (filters) => {
    const { users } = await getUsersFiltered(filters)
    onUsersFilter(users)
  }

  const onClearClick = () => {
    reset()
    onClear()
  }

  return (
    <FormProvider {...useFormProps}>
      <S.UserStatisticsFilter onSubmit={handleSubmit(onFilterSubmit)}>
        <Typography variant="h4">{t('Filter')}</Typography>

        <S.Filters>
          <S.RowLabel>
            <Typography variant="body2" component="label">
              {t('PNL')}
            </Typography>

            <S.NumberRangeInput>
              <TextField
                type="number"
                name="pnlMin"
                placeholder="0"
                InputProps={{ endAdornment: '%' }}
                rules={{
                  required: false,
                  maxLength: 3
                }}
              />
              <TextField
                type="number"
                name="pnlMax"
                placeholder="0"
                InputProps={{ endAdornment: '%' }}
                rules={{ required: false, maxLength: 3 }}
              />
            </S.NumberRangeInput>
          </S.RowLabel>

          <S.RowLabel>
            <Typography variant="body2" component="label">
              {t('Y-AVG PNL')}
            </Typography>

            <S.NumberRangeInput>
              <TextField
                type="number"
                name="pnlAvgMin"
                placeholder="0"
                InputProps={{ endAdornment: '%' }}
                rules={{ required: false, maxLength: 3 }}
              />
              <TextField
                type="number"
                name="pnlAvgMax"
                placeholder="0"
                InputProps={{ endAdornment: '%' }}
                rules={{ required: false, maxLength: 3 }}
              />
            </S.NumberRangeInput>
          </S.RowLabel>

          <S.CopiesNumberRow>
            <Typography variant="body2" component="label">
              {t('Copiers number')}
            </Typography>

            <S.NumberRangeInput>
              <TextField
                type="number"
                name="copiersMin"
                placeholder="50"
                rules={{ required: false, maxLength: 4 }}
              />
              <TextField
                type="number"
                name="copiersMax"
                placeholder="4000"
                rules={{ required: false, maxLength: 4 }}
              />
            </S.NumberRangeInput>
          </S.CopiesNumberRow>

          <Select name="risk" label={t('Risk metrics')} haveReset>
            {riskMenuItems}
          </Select>

          <Select name="assetsType" label={t('Assets type')} haveReset>
            {assetsTypeMenuItems}
          </Select>

          <Select name="currency" label={t('Base currency')} haveReset>
            {currencyMenuItems}
          </Select>
        </S.Filters>

        <Button
          type="submit"
          variant="lime"
          disabled={!usersFilteredCount}
          isLoading={isUsersFilteredCountLoading}
        >
          {t('Search')} {usersFilteredCount ? `(${usersFilteredCount})` : '(0)'}
        </Button>

        <Button variant="inline" onClick={onClearClick}>
          {t('Clear all')}
        </Button>
      </S.UserStatisticsFilter>
    </FormProvider>
  )
}

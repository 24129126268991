import styled, { css } from 'styled-components'
import ReactCodeInput from 'react-verification-code-input'

export const CodeInput = styled(({ error, ...props }) => <ReactCodeInput {...props} />)`
  width: 100% !important;

  & > div {
    display: flex;
    justify-content: space-between;

    input {
      border: 1px solid var(--color-gray-light);
      font-family: 'Montserrat-Regular';
      font-size: var(--font-size-md);
      color: #1b1b1b;
      outline: none;
      padding: 0;

      &:focus {
        border-color: var(--color-gray-light);
        caret-color: #000;
      }

      &:last-child,
      &:focus + input {
        border-color: var(--color-gray-light);
        border-left: 1px solid var(--color-gray-light);
      }
    }
  }

  ${({ error }) =>
    error &&
    css`
      & > div input {
        border-color: var(--color-red);
        background: rgba(255, 104, 104, 0.05);

        &:focus,
        &:last-child,
        &:focus + input {
          border-color: var(--color-red);
          border-left: 1px solid var(--color-red);
        }
      }
    `}
`

import React, { useState } from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'

import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg'
import { ReactComponent as EyeOffIcon } from '@assets/icons/eye-off.svg'

import * as S from './PasswordField.styled'

export const PasswordField = ({ name, label, iconDisabled, required, ...props }) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const showPassword = () => {
    setPasswordVisible(true)
  }

  const hidePassword = () => {
    setPasswordVisible(false)
  }

  return (
    <S.PasswordField
      {...props}
      name={name}
      label={label}
      required={required}
      type={isPasswordVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: !iconDisabled && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={showPassword}
              onMouseDown={hidePassword}
              edge="end"
            >
              {isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

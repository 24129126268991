import styled from 'styled-components'
import Popover from '@mui/material/Popover'

import { CheckboxInput, Label } from '@ui/Checkbox/Checkbox.styled'

export const ColumnsSettingsPopover = styled((props) => (
  <Popover classes={{ root: 'popover', paper: 'popover-paper' }} {...props} />
))`
  .popover-paper {
    margin-top: 5px;
    margin-left: 5px;
    padding: 30px;
    border: 1px solid #d2d3da;
    box-shadow: none;
    border-radius: 0;
    color: #000;
    box-sizing: border-box;

    p {
      font-family: 'Montserrat-SemiBold';
      line-height: 21px;
    }
  }
`

export const Top = styled.div`
  display: flex;

  button {
    margin-left: 20px;
    padding: 0;
    font-family: 'Montserrat-Medium';
    color: var(--color-primary);
    border: none;
    background: transparent;
    cursor: pointer;
  }
`

export const FieldsList = styled.ul`
  display: grid;
  grid-gap: 10px;
  margin-top: 20px;
`

export const Field = styled.li`
  ${Label} {
    align-items: center;
    line-height: 21px;
  }

  ${CheckboxInput} {
    &.checkbox-root {
      width: 16px;
      height: 16px;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
`

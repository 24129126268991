import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import MediaQuery from 'react-responsive'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import MuiFade from '@mui/material/Fade'
import Fade from 'react-reveal/Fade'
import { useTranslation } from 'react-i18next'
import Popover from '@mui/material/Popover'

import { Logo } from '@components'
import { Button } from '@ui'

import { ROUTE_NAMES } from '@core/routes'
import { useAuth } from '@contexts/auth'
import { deleteCookie } from '@utils'
import { UsersAPI, AuthAPI } from '@api'

import * as S from './Header.styled'

import { ReactComponent as UserIcon } from '@assets/icons/user.svg'
import { ReactComponent as BellIcon } from '@assets/icons/bell.svg'

export const Header = ({ animated }) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { user, isAuth, setAuth, setUser, setUserCognito } = useAuth()
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [notificationsMenuAnchor, setNotificationsMenuAnchor] = useState(null)
  const [languageAnchor, setLanguageAnchor] = useState(null)

  const {
    data: notifications = [],
    refetch: refetchNotifications,
    mutate: notificationsMutate
  } = useMutation(UsersAPI.getNotifications)

  const readNotification = useMutation((dateTime) => UsersAPI.readNotification(dateTime), {
    onSuccess: () => {
      notificationsMutate()
    }
  })

  const logout = useMutation(() => AuthAPI.logout(), {
    onError: ({ message }) => toast.error(message),
    onSuccess: () => {
      navigate(ROUTE_NAMES.MAIN)
      setAuth(false)
    }
  })

  useEffect(() => {
    if (isAuth) {
      notificationsMutate()
    }
  }, [isAuth, notificationsMutate])

  const someNotificationNotRead = notifications.some(({ viewed }) => !viewed)
  const languageDifferent = i18n.language === 'ru' ? 'en' : 'ru'

  const nav = [
    { label: t('discover'), link: ROUTE_NAMES.DISCOVER },
    { label: t('watchlist'), link: ROUTE_NAMES.WATCHLIST },
    { label: t('trading'), link: user.login ? `/${user.login}` : ROUTE_NAMES.LOGIN },
    { label: t('help'), link: ROUTE_NAMES.HELP }
  ]

  const navList = nav.map(({ label, link }, idx) => {
    return (
      <li key={link}>
        <Fade bottom delay={700 + idx * 100} distance="50%">
          <Link to={link}>{label}</Link>
        </Fade>
      </li>
    )
  })

  const toggleMobileMenu = () => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setMobileMenuOpen(isMobileMenuOpen ? false : true)
  }

  const closeNotificationsMenuOpen = () => {
    setNotificationsMenuAnchor(null)
  }

  const openNotificationsMenu = ({ currentTarget }) => {
    setNotificationsMenuAnchor(currentTarget)
  }

  const onNotificationRead = async (datetime) => {
    await readNotification.mutateAsync(datetime)
    refetchNotifications()
  }

  const getDatetimeLabel = (datetime) => {
    const dateToString = (date) => new Date(date).toLocaleDateString()
    const todayDate = new Date().getDate()
    const yesterday = dateToString(new Date(new Date().setDate(todayDate - 1)))
    const isToday = dateToString(datetime) === dateToString(todayDate)
    const isYesterday = dateToString(datetime) === yesterday
    const AMPMTime = datetime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    let dateLabel = new Date().toGMTString().slice(4, 16)

    if (isToday) {
      dateLabel = 'Today'
    }

    if (isYesterday) {
      dateLabel = 'Yesterday'
    }

    return `${dateLabel} at ${AMPMTime}`
  }

  const openLanguagePopover = ({ currentTarget }) => {
    setLanguageAnchor(currentTarget)
  }

  const closeLanguagePopover = () => {
    setLanguageAnchor(null)
  }

  const onLanguageChange = () => {
    localStorage.setItem('lang', languageDifferent)
    i18n.changeLanguage(languageDifferent)
    closeLanguagePopover()
  }

  const onLogout = () => {
    setAuth(false)
    setUser({
      login: '',
      email: '',
      phone: ''
    })
    setUserCognito(null)
    deleteCookie('token')
    logout.mutate()
  }

  const authButtons = isAuth ? (
    <Fade bottom delay={1060} distance="50">
      <S.LogoutBtn variant={isMobileMenuOpen ? 'lime' : null} height="medium" onClick={onLogout}>
        <span>
          <span>{t('logout')}</span>
        </span>
      </S.LogoutBtn>
    </Fade>
  ) : (
    <S.EntryButtons>
      <Fade bottom delay={1030} distance="50%">
        <Link
          to={ROUTE_NAMES.LOGIN}
          onClick={() => {
            setMobileMenuOpen(false)
          }}
        >
          {t('login')}
        </Link>
      </Fade>

      <Fade bottom delay={1060} distance="50%">
        <Button
          component={Link}
          to={ROUTE_NAMES.SIGN_UP}
          variant={isMobileMenuOpen ? 'lime' : null}
          height="medium"
          onClick={() => {
            setMobileMenuOpen(false)
          }}
        >
          {t('sign up')}
        </Button>
      </Fade>
    </S.EntryButtons>
  )

  const notificationsMenuItems = notifications.map(({ datetime, viewed, text }) => {
    return (
      <S.NotificationMenuItem key={datetime} onClick={() => onNotificationRead(datetime)}>
        <Typography>
          {text}
          {!viewed && <S.GreenCircle />}
        </Typography>

        <Typography>{getDatetimeLabel(new Date(datetime))}</Typography>
      </S.NotificationMenuItem>
    )
  })

  return (
    <S.Header isMenuOpen={isMobileMenuOpen} animated={animated}>
      <Fade bottom delay={500}>
        <Logo />
      </Fade>

      <MediaQuery minWidth={1010}>
        <S.Nav>
          <S.NavList>{navList}</S.NavList>
        </S.Nav>
      </MediaQuery>

      <S.Right>
        {isAuth && (
          <S.UserButtons>
            <Fade bottom delay={1010}>
              <S.NotificationsBellButton onClick={openNotificationsMenu}>
                <BellIcon />
                {someNotificationNotRead && <S.GreenCircle big />}
              </S.NotificationsBellButton>
            </Fade>

            <Fade bottom delay={1020}>
              <Link to={ROUTE_NAMES.ACCOUNT_SETTINGS}>
                <UserIcon />
              </Link>
            </Fade>
          </S.UserButtons>
        )}

        <Fade bottom delay={1000} distance="10%">
          <S.LanguageBtn variant="bordered" height="medium" onClick={openLanguagePopover}>
            <span>
              <span>{i18n.language}</span>
            </span>
          </S.LanguageBtn>
        </Fade>

        <Popover
          open={Boolean(languageAnchor)}
          anchorEl={languageAnchor}
          classes={{ paper: 'language-popover-paper' }}
          disablePortal
          onClose={closeLanguagePopover}
        >
          <S.LanguageBtn variant="bordered" height="medium" onClick={onLanguageChange}>
            <span>
              <span>{languageDifferent}</span>
            </span>
          </S.LanguageBtn>
        </Popover>

        <MediaQuery minWidth={1010}>{authButtons}</MediaQuery>

        <MediaQuery maxWidth={1010}>
          <Fade bottom delay={1020}>
            <S.MenuBtn onClick={toggleMobileMenu()}>
              <span></span>
              <span></span>
              <span></span>
            </S.MenuBtn>
          </Fade>
        </MediaQuery>

        <MediaQuery maxWidth={1010}>
          <Drawer anchor="top" open={isMobileMenuOpen} onClose={toggleMobileMenu()}>
            <S.MenuMobile>
              <S.NavListMobile>{navList}</S.NavListMobile>

              <S.MenuMobileButtons>{authButtons}</S.MenuMobileButtons>
            </S.MenuMobile>
          </Drawer>
        </MediaQuery>
      </S.Right>

      <S.NotificationsMenu
        anchorEl={notificationsMenuAnchor}
        open={Boolean(notificationsMenuAnchor)}
        onClose={closeNotificationsMenuOpen}
        TransitionComponent={MuiFade}
      >
        {notificationsMenuItems}
      </S.NotificationsMenu>
    </S.Header>
  )
}

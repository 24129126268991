import styled from 'styled-components'

import { TextField } from '@ui/TextField/TextField.styled'

export const NumberRangeInput = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 1px;
    background: #a5a5b1;
    transform: translate(-50%, -50%);
  }

  ${TextField} {
    .input-root {
      width: 100%;
      justify-content: center;
      color: #acacac;
      padding: 0 10px;

      input {
        margin-top: 0;
      }

      &.input-adorned-end input {
        width: calc(100% - 23px);
      }
    }
  }
`

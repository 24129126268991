import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { ContentDisplay } from '@components'
import { Skeleton, Button } from '@ui'
import { MoreBrokersModal } from '../MoreBrokersModal'
import { BrokerConnectModal } from '../BrokerConnectModal'

import * as UsersAPI from '@api/users'
import { sortByOrderProp } from '@utils'

import * as S from './LinkBroker.styled'

import { ReactComponent as PenIcon } from '@assets/icons/pen.svg'
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg'

export const LinkBroker = ({ refetchVerifications }) => {
  const { t } = useTranslation()

  const media500 = useMediaQuery({ query: '(min-width: 500px)' })

  const [brokerSelected, setBrokerSelected] = useState(null)
  const [isMoreBrokerModalOpen, setMoreBrokerModalOpen] = useState(false)
  const [isBrokerConnectModalOpen, setBrokerConnectModalOpen] = useState(false)

  const { data: brokers = [], isLoading: isUserBrokersLoading } = useQuery(
    'brokers',
    UsersAPI.getBrokers
  )

  const {
    data: brokersConnected = [],
    isLoading: isBrokersConnectedLoading,
    refetch: refetchBrokersConnected
  } = useQuery('brokers-connected', UsersAPI.getConntectedBrokers)

  const connectBroker = useMutation(
    ({ brokername, apiKey, secretKey, login, password, apiToken }) =>
      UsersAPI.connectBroker({ brokername, apiKey, secretKey, login, password, apiToken }),
    {
      onError: () => {
        toast.error('Failed to connect broker')
      },

      onSuccess: () => {
        refetchBrokersConnected()
        refetchVerifications()
        setBrokerConnectModalOpen(false)
        toast.success('Broker connected successfully')
      }
    }
  )

  const removeConnectedBroker = useMutation(
    (brokername) => {
      return UsersAPI.deleteConntectedBroker(brokername)
    },
    {
      onSuccess: () => {
        toast.success('Broker successfully removed')
        refetchBrokersConnected()
        refetchVerifications()
      }
    }
  )

  const onBrokerConnect = ({ brokername, apiKey, secretKey, login, password, apiToken }) => {
    connectBroker.mutate({ brokername, apiKey, secretKey, login, password, apiToken })
  }

  const onBrokerEdit = (brokername) => {
    setBrokerSelected(brokername)
    setBrokerConnectModalOpen(true)
  }

  const getBrokerLogoUrl = (brokername, brokers) => {
    const { logos } = brokers.find(({ name }) => name === brokername)
    const logoColored = media500 ? logos[0] : logos[3]
    return logoColored.url
  }

  const findBrokerSelectedData = (name, brokers) => {
    return brokers.find((broker) => broker.name === name)
  }

  const isBrokersLoading = isUserBrokersLoading || isBrokersConnectedLoading

  const brokersWithoutConnected = sortByOrderProp(brokers).filter(({ name }) => {
    return !brokersConnected.some(({ name: connectedName }) => connectedName === name)
  })

  const brokernames = brokers.map(({ name }) => name)

  const isSelectedBrokerConnected = brokersConnected.some(
    ({ name }) => name === brokerSelected?.name
  )

  const brokersItems = brokersWithoutConnected.map((broker) => {
    const { name, logos } = broker
    const logoBW = media500 ? logos[1] : logos[4]
    const logoColored = media500 ? logos[2] : logos[3]

    const isSelected = brokerSelected?.name === name

    return (
      <S.BrokerItem key={name} selected={isSelected} onClick={() => setBrokerSelected(broker)}>
        <S.BrokerItemLogoBW url={logoBW.url} />
        <S.BrokerItemLogoColored url={logoColored.url} style={isSelected ? { opacity: 1 } : null} />
      </S.BrokerItem>
    )
  })

  const brokersConnectedItems = sortByOrderProp(brokersConnected).map(({ name }) => {
    const logoUrl = !isBrokersLoading ? getBrokerLogoUrl(name, brokers) : null

    return (
      <S.BrokerItem key={name}>
        <S.BrokerItemLogoColored url={logoUrl} style={{ opacity: 1 }} />

        <S.Actions>
          <button
            onClick={() => {
              onBrokerEdit(findBrokerSelectedData(name, brokers))
            }}
          >
            <PenIcon />
          </button>

          <button
            onClick={() => {
              removeConnectedBroker.mutate(name)
            }}
          >
            <TrashIcon />
          </button>
        </S.Actions>
      </S.BrokerItem>
    )
  })

  return (
    <S.LinkBroker>
      <Typography variant="h3">{t('Link broker')}</Typography>

      <Typography variant="h6">{t('Choose broker')}</Typography>

      <ContentDisplay
        isLoading={isBrokersLoading}
        skeleton={
          <S.SkeletonGrid>
            <Skeleton count={8} />
          </S.SkeletonGrid>
        }
      >
        <S.BrokersList>
          {brokersItems.slice(0, 7)}

          <S.BrokerItem
            onClick={() => {
              setMoreBrokerModalOpen(true)
            }}
          >
            <Typography variant="h6" component="span">
              {t('Other...')}
            </Typography>
          </S.BrokerItem>
        </S.BrokersList>
      </ContentDisplay>

      {Boolean(brokerSelected) ? (
        <Button
          variant="lime"
          onClick={() => {
            setBrokerConnectModalOpen(true)
          }}
        >
          {t('Connect')}
        </Button>
      ) : null}

      <Typography variant="h6">{t('Connected brokers')}</Typography>

      <ContentDisplay
        isLoading={isBrokersLoading}
        skeleton={
          <S.SkeletonGrid>
            <Skeleton count={4} />
          </S.SkeletonGrid>
        }
        noData={<Typography>{t("You don't have connected brokers")}</Typography>}
      >
        {brokersConnected.length !== 0 ? (
          <S.BrokersList>{brokersConnectedItems}</S.BrokersList>
        ) : null}
      </ContentDisplay>

      <MoreBrokersModal
        open={isMoreBrokerModalOpen}
        brokers={brokersWithoutConnected}
        onBrokerChoose={(broker) => {
          setBrokerSelected(broker)
          setMoreBrokerModalOpen(false)
          setBrokerConnectModalOpen(true)
        }}
        onClose={() => {
          setMoreBrokerModalOpen(false)
        }}
      />

      <BrokerConnectModal
        open={isBrokerConnectModalOpen}
        brokerSelected={brokerSelected}
        brokernames={brokernames}
        isBrokerConnected={isSelectedBrokerConnected}
        isBrokerConnecting={connectBroker.isLoading}
        onBrokerConnect={onBrokerConnect}
        onClose={() => {
          setBrokerConnectModalOpen(false)
        }}
      />
    </S.LinkBroker>
  )
}

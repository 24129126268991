import styled from 'styled-components'

import { MyInfo } from './components/MyInfo/MyInfo.styled'
import { BillingPayments } from './components/BillingPayments/BillingPayments.styled'
import { InvestVerifySteps } from './components/InvestVerifySteps/InvestVerifySteps.styled'
import { TraderApplySteps } from './components/TraderApplySteps/TraderApplySteps.styled'
import { LinkBroker } from './components/LinkBroker/LinkBroker.styled'

import { Button } from '@ui/Button/Button.styled'

import { media } from '@styled/media'
import { BoxGray } from '@styled/components'

export const Head = styled.div`
  display: flex;
  align-items: center;

  ${media.createMedia(570)} {
    align-items: flex-start;
    flex-direction: column;

    h3 {
      font-size: 25px;
    }
  }
`

export const Content = styled.div`
  margin-top: 40px;

  ${LinkBroker} {
    margin-top: 40px;
  }

  ${media.tablet} {
    ${LinkBroker} {
      padding: 20px;
    }
  }

  ${media.mobile} {
    ${LinkBroker} {
      padding: 15px;
    }
  }
`

export const BrokerReport = styled(BoxGray)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 50px;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
  }

  ${Button} {
    width: 250px;
    align-items: center;

    &:hover {
      svg {
        fill: var(--color-primary);
      }
    }

    svg {
      margin-left: 10px;
    }
  }

  ${media.mobile} {
    flex-direction: column;
    align-items: flex-start;

    h6 {
      font-size: var(--font-size-md);
      line-height: 24px;
    }

    ${Button} {
      width: 100%;
      font-size: var(--font-size-reg);
      margin-top: 15px;
    }
  }
`

export const MasonryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 476px 130px 1fr 390px;
  grid-template-areas:
    'a b'
    'a c'
    'a c'
    'd e';

  grid-gap: 40px;
  align-items: flex-start;

  ${MyInfo} {
    grid-area: a;
  }

  ${BillingPayments} {
    grid-area: b;
  }

  ${BrokerReport} {
    grid-area: c;
  }

  ${InvestVerifySteps} {
    grid-area: d;
    height: 100%;
    box-sizing: border-box;
  }

  ${TraderApplySteps} {
    grid-area: e;
    height: 100%;
    box-sizing: border-box;
  }

  ${media.createMedia(1110)} {
    display: flex;
    flex-direction: column;
    grid-template-areas: none;

    ${MyInfo}, ${BillingPayments}, ${BrokerReport} {
      grid-area: auto;
    }

    ${BoxGray} {
      width: 100%;
      box-sizing: border-box;
    }
  }

  ${media.tablet} {
    ${BoxGray} {
      padding: 20px;
    }
  }

  ${media.mobile} {
    grid-gap: 30px;

    ${BoxGray} {
      padding: 15px;
    }
  }
`

export const AccountStatus = styled.div`
  margin-left: 30px;
  padding-top: 9px;

  p {
    display: flex;
    align-items: center;
    font-family: 'Montserrat-SemiBold';
    color: #acacac;

    svg:first-child {
      margin-right: 5px;
    }
  }

  ${media.createMedia(570)} {
    margin-top: 10px;
    padding-top: 0;
    margin-left: 0;
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'

import { Checkbox } from '@ui'

import * as S from './ColumnsSettingsPopover.styled'

export const ColumnsSettingsPopover = ({ anchorEl, onClose, fields, onReset }) => {
  const fieldsCheckboxes = fields.map(({ propName, label }) => {
    return (
      <S.Field key={propName}>
        <Checkbox name={propName} label={label} />
      </S.Field>
    )
  })

  return (
    <S.ColumnsSettingsPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        horizontal: 'right'
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      <S.Top>
        <Typography variant="body1">Columns settings</Typography>
        <button onClick={onReset}>By default</button>
      </S.Top>

      <S.FieldsList>{fieldsCheckboxes}</S.FieldsList>
    </S.ColumnsSettingsPopover>
  )
}

import React, { forwardRef } from 'react'

import { TextField } from '@ui'

import * as S from './Autocomplete.styled'

import { ReactComponent as LoupeIcon } from '@assets/icons/loupe.svg'

export const Autocomplete = forwardRef(({ name, defaultValue, placeholder, ...props }, ref) => {
  return (
    <S.AutocompleteContainer>
      <S.Loupe>
        <LoupeIcon />
      </S.Loupe>

      <S.Autocomplete
        {...props}
        ref={ref}
        renderInput={(props) => (
          <TextField {...props} name={name} defaultValue={defaultValue} placeholder={placeholder} />
        )}
      />
    </S.AutocompleteContainer>
  )
})

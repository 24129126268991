import styled from 'styled-components'

import { TextField } from '@ui/TextField/TextField.styled'
import { CodeInput } from '@ui/CodeInput/CodeInput.styled'
import { Button } from '@ui/Button/Button.styled'

export const ForgotPasswordForm = styled.form`
  p {
    line-height: 24px;

    &:nth-child(2) {
      margin-top: 20px;
    }
  }

  ${TextField}, ${Button} {
    margin-top: 30px;
  }
`

export const ResetPasswordForm = styled.form`
  ${CodeInput}, ${TextField}, ${Button} {
    margin-top: 30px;
  }
`

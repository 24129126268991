import styled, { css } from 'styled-components'

import { BoxGray } from '@styled/components'

import { NoData } from '@components/NoData/NoData.styled'

export const Forum = styled.div`
  display: grid;
  grid-template-columns: 760px 360px;
  grid-gap: 40px;
  align-items: flex-start;
`

export const PostsPreviewsList = styled.ul`
  margin-top: 20px;

  & > li:not(:last-child) {
    max-height: 500px;
    margin-bottom: 40px;
    overflow: hidden;
  }
`

export const SortButtonsList = styled.ul`
  display: flex;
  margin-top: 20px;

  li {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const SortButton = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid transparent;
  background: transparent;
  font-family: 'Montserrat-SemiBold';
  color: #676974;
  transition: 0.3s;
  cursor: pointer;

  span {
    margin-left: 5px;
  }

  ${({ active }) =>
    active &&
    css`
      color: #000;
      border-color: #000;
    `}
`

export const Content = styled.div`
  ${NoData} {
    margin-top: 20px;
  }
`

export const Category = styled(BoxGray)`
  padding: 20px;
`

export const CategoryTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

export const ButtonInlinePrimary = styled.button`
  padding: 0;
  border: none;
  background: transparent;
  font-family: 'Montserrat-Medium';
  font-size: var(--font-size-sm);
  line-height: 18px;
  color: #69d200;
  cursor: pointer;
`

export const CategoriesGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 10px;
`

export const PostPreviewsSkeleton = styled.div`
  margin-top: 20px;
`

export const PostPreviewsSkeletonList = styled.div`
  margin-top: 20px;

  span > span:not(:last-child) {
    margin-bottom: 40px;
  }
`

export const PostedByUser = styled(BoxGray)`
  padding: 20px;

  ${ButtonInlinePrimary} {
    margin-top: 20px;
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-reg);
    line-height: 24px;
  }
`

export const PostedByUserInfo = styled.div`
  display: flex;
  align-items: center;
`

export const PostedByUserInfoAvatar = styled.div`
  width: 80px;
  height: 80px;

  img {
    max-width: 100%;
  }
`

export const PostedByUserInfoRight = styled.div`
  margin-left: 20px;

  p {
    &:first-child {
      font-family: 'Montserrat-SemiBold';
      line-height: 24px;
    }

    &:last-child {
      margin-top: 5px;
      line-height: 21px;
    }
  }
`

export const CategoryItem = styled.li`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  list-style: none;
  background: #000;
  font-family: 'Montserrat-Medium';
  font-size: var(--font-size-reg);
  text-transform: capitalize;
  color: var(--color-primary);
  line-height: 24px;
  transition: 0.3s;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      background: #d2d3da;
      color: #676974;

      svg {
        display: none;
      }
    `}
`

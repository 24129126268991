import styled from 'styled-components'

import { TextField } from '@ui/TextField/TextField.styled'
import { SelectInput } from '@ui/Select/Select.styled'

export const PhoneTextFieldInput = styled(TextField)`
  .input-root {
    border-left: none;
  }
`

export const PhoneField = styled.div`
  position: relative;

  .PhoneInputCountry {
    width: 100%;
  }
`

export const CountrySelect = styled(SelectInput)`
  width: 70px;
  height: 64px;

  && {
    .select-root.select-input-outlined {
      height: 100%;
      padding-right: 0;
    }
  }

  .select {
    &-root {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    &-menu-paper {
      max-height: 200px;
    }

    &-menu-list {
      width: 100%;
      padding-right: 0;
    }

    &-icon {
      display: none;
    }
  }
`

import styled, { css } from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

export const InvestorCopyButton = styled(Button)`
  &:hover {
    svg {
      fill: #000;
    }
  }

  ${({ small }) =>
    small &&
    css`
      &.button {
        min-width: 28px;
        width: 28px;
        height: 28px;
        z-index: 10;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    `}
`

import styled from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

import { media } from '@styled/media'

export const UploadAvatar = styled.div`
  display: flex;

  label {
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-md);
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: -1;
  }

  ${media.mobile} {
    label {
      font-size: var(--font-size-reg);
    }
  }
`

export const PicturePreview = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border: 3px solid #fff;
  background: #fff;
  box-sizing: border-box;
  overflow: hidden;

  img {
    max-width: 100%;
  }
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 24px;

  ${Button} {
    margin-top: 20px;
    color: var(--color-red);

    &:hover {
      color: var(--color-red);
    }
  }

  ${media.mobile} {
    ${Button} {
      margin-top: 10px;
      font-size: var(--font-size-reg);
    }
  }

  ${media.createMedia(400)} {
    margin-left: 10px;
  }
`

import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import Typography from '@mui/material/Typography'
import TimeAgo from 'react-timeago'
import { useTranslation } from 'react-i18next'

import { PostPreview } from '../PostPreview'
import { TextEditor, Button, Skeleton } from '@ui'

import { UsersAPI } from '@api'

import * as S from './PostOpened.styled'

import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import enStrings from 'react-timeago/lib/language-strings/en'
import ruStrings from 'react-timeago/lib/language-strings/ru'

export const PostOpened = ({ postData, onComment }) => {
  const {
    i18n: { language }
  } = useTranslation()

  const [comment, setComment] = useState('')

  const timeFormatter = buildFormatter(language === 'en' ? enStrings : ruStrings)

  const {
    data: usersData,
    mutate: getUsersData,
    isLoading: isUsersDataLoading
  } = useMutation((usernames) => UsersAPI.getInfo({ usergroup: usernames }))

  useEffect(() => {
    if (!postData.comments.length) return

    const commentsUsernames = [
      ...new Map(postData.comments.map(({ username }) => [username, username])).values()
    ].join(',')

    getUsersData(commentsUsernames)
  }, [postData, getUsersData])

  const { comments } = postData

  const onCommentSubmit = (e) => {
    e.preventDefault()

    const { datetime, postBy } = postData
    onComment({ datetime, username: postBy, body: comment })
    setComment('')
  }

  const sortCommentsByTime = (comments) => {
    return comments.sort((a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime())
  }

  const commentsItems =
    !isUsersDataLoading && !isUsersDataLoading
      ? sortCommentsByTime(comments).map(({ username, datetime, body }) => {
          const { avatar } = usersData?.find((user) => user.username === username) || {}

          return (
            <S.Comment key={`${username}${datetime}}`}>
              <S.CommentTop>
                <S.CommentAvatar>{avatar && <img src={avatar} alt={username} />}</S.CommentAvatar>

                <Typography component="span">{username}</Typography>

                <S.CommentDatetime>
                  <TimeAgo date={datetime} formatter={timeFormatter} />
                </S.CommentDatetime>
              </S.CommentTop>

              <S.CommentBody dangerouslySetInnerHTML={{ __html: body }} />
            </S.Comment>
          )
        })
      : null

  return (
    <S.PostOpened>
      <PostPreview {...postData} />

      <S.CommentForm onSubmit={onCommentSubmit}>
        <TextEditor value={comment} placeholder="Comment" onChange={setComment} />

        <Button type="submit" variant="lime" hegiht="big" disabled={!comment.length}>
          Comment
        </Button>
      </S.CommentForm>

      {!isUsersDataLoading && !isUsersDataLoading ? (
        <S.CommentsList>{commentsItems}</S.CommentsList>
      ) : (
        <S.CommentsSkeleton>
          <Skeleton count={3} height={100} />
        </S.CommentsSkeleton>
      )}
    </S.PostOpened>
  )
}

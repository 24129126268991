import React, { useRef } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { PageLayout } from '@components'
import { Button } from '@ui'
import {
  MyInfo,
  BillingPayments,
  InvestVerifySteps,
  TraderApplySteps,
  LinkBroker
} from './components'

import * as UsersAPI from '@api/users'
import { useAuth } from '@contexts/auth'

import * as S from './AccountSettings.styled'

import { ReactComponent as ClockIcon } from '@assets/icons/clock.svg'
import { ReactComponent as FileIcon } from '@assets/icons/file-text.svg'
import { ReactComponent as StarIcon } from '@assets/icons/star-filled.svg'

export const AccountSettingsPage = () => {
  const { t } = useTranslation()
  const { setUser } = useAuth()
  const brokerReportInputRef = useRef(null)

  const {
    data: verifications = {},
    isLoading: isVerficationsLoading,
    refetch: refetchVerifications
  } = useQuery('verifications', UsersAPI.getVerifications, {
    onSuccess: ({ login, phone, email }) => {
      setUser({ login, phone, email })
    }
  })

  const createBrokerReport = useMutation(UsersAPI.uploadBrokerReport, {
    onError: () => {
      toast.error('Failed to upload broker report')
    },
    onSuccess: () => {
      toast.success('Report successfully uploaded')
    }
  })

  const getInvestVerifications = ({
    investVerified,
    isInvestor,
    emailVerified,
    personalInfo,
    credentials,
    brokerLinked,
    billingLinked
  }) => {
    return {
      investVerified,
      isInvestor,
      emailVerified,
      personalInfo,
      credentials,
      brokerLinked,
      billingLinked
    }
  }

  const getTraderVerifications = ({
    investVerified,
    accountValueMin,
    pnlStatsMin,
    notFollowTraders,
    pitch,
    appliedForTrader
  }) => {
    return {
      investVerified,
      accountValueMin,
      pnlStatsMin,
      notFollowTraders,
      pitch,
      appliedForTrader
    }
  }

  const getUserStatus = (verifications) => {
    const { isTrader, appliedForTrader, investVerified } = verifications

    if (appliedForTrader && appliedForTrader && isTrader) {
      return (
        <Typography variant="body1">
          <StarIcon /> Trader
        </Typography>
      )
    }

    if (investVerified && !isTrader && !appliedForTrader) {
      return <Typography variant="body1">Verified</Typography>
    }

    if (appliedForTrader && appliedForTrader && !isTrader) {
      return <Typography variant="body1">Applied for a trader</Typography>
    }

    if (!isTrader && !appliedForTrader && !investVerified) {
      return (
        <Typography variant="body1">
          <ClockIcon />
          Pending verification
        </Typography>
      )
    }
  }

  const triggerBrokerReportInput = () => {
    brokerReportInputRef.current.click()
  }

  const onUploadBrokerReport = ({ target }) => {
    createBrokerReport.mutate(target.files[0])
  }

  return (
    <PageLayout>
      <S.Head>
        <Typography variant="h3">{t('Account Settings')}</Typography>

        <S.AccountStatus>{getUserStatus(verifications)}</S.AccountStatus>
      </S.Head>

      <S.Content>
        <S.MasonryGrid>
          <MyInfo phone={verifications?.phone} refetchVerifications={refetchVerifications} />

          <BillingPayments refetchVerifications={refetchVerifications} />

          <S.BrokerReport>
            <Typography variant="h6">{t('Broker report')}</Typography>

            <Button
              variant="lime"
              disabled={!verifications?.isReportEnable}
              onClick={triggerBrokerReportInput}
            >
              {t('Submit')} <FileIcon />
            </Button>

            <input
              ref={brokerReportInputRef}
              type="file"
              accept="application/pdf"
              onChange={onUploadBrokerReport}
            />
          </S.BrokerReport>

          <InvestVerifySteps
            verifications={getInvestVerifications(verifications)}
            isLoading={isVerficationsLoading}
          />

          <TraderApplySteps
            tradeEligable={verifications.tradeEligable}
            verifications={getTraderVerifications(verifications)}
            isUserTrader={verifications.isTrader}
            isLoading={isVerficationsLoading}
            refetchVerifications={refetchVerifications}
          />
        </S.MasonryGrid>

        <LinkBroker refetchVerifications={refetchVerifications} />
      </S.Content>
    </PageLayout>
  )
}

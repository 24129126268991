import styled from 'styled-components'

import { media } from '@styled/media'

export const InvestorsCarousel = styled.div`
  width: 100%;

  .swiper {
    margin-top: 20px;
    height: 100%;

    &-wrapper {
      transition-timing-function: linear;
    }
  }

  ${media.createMedia(600)} {
    .swiper {
      overflow: visible;

      &-slide {
        width: 232px;
      }
    }
  }
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-size: var(--font-size-h6);
  }

  ${media.mobile} {
    h2 {
      font-size: var(--font-size-md);
    }
  }
`

export const SliderNav = styled.div`
  button {
    width: 24px;
    height: 24px;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:first-child {
      margin-right: 20px;
    }

    &:last-child {
      transform: rotate(-180deg);
    }
  }

  ${media.mobile} {
    flex-shrink: 0;

    button {
      &:first-child {
        margin-right: 12px;
      }
    }
  }
`

import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { ContentDisplay } from '@components'
import { AddCreditCardModal } from '../AddCreditCardModal'
import { Button, Skeleton, Tooltip } from '@ui'

import { UsersAPI, PaymentsAPI } from '@api'

import * as S from './BillingPayments.styled'

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { ReactComponent as VisaIcon } from '@assets/icons/visa.svg'
import { ReactComponent as MastercardIcon } from '@assets/icons/mastercard.svg'
import { ReactComponent as TrashIcon } from '@assets/icons/trash.svg'

export const BillingPayments = ({ refetchVerifications }) => {
  const { t } = useTranslation()

  const [isAddCreditCardModalOpen, setCreditCardModalOpen] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState('')

  const {
    data: balance = {},
    isLoading: isBalanceLoading,
    refetch: refetchBalance
  } = useQuery('balance', UsersAPI.getBalance)

  const {
    data: cards = [],
    isLoading: isCardsLoading,
    refetch: refetchCards
  } = useQuery('cards', PaymentsAPI.getCards, {
    onSuccess: (cards) => {
      if (cards.length) {
        setSelectedCardId(cards[0].id)
      } else {
        setSelectedCardId('')
      }
    }
  })

  const { mutate: deleteCard, isLoading: isCardDeleting } = useMutation(
    (cardId) => PaymentsAPI.deleteCard(cardId),
    {
      onSuccess: () => {
        refetchCards()
        refetchVerifications()
        toast.success('Card successfully deleted')
      },
      onError: () => {
        toast.error('Failed to delete card')
      }
    }
  )

  const { mutate: makePayment, isLoading: isPaymentProcessing } = useMutation(
    ({ cardId, amount }) => PaymentsAPI.makePayment(cardId, amount),
    {
      onSuccess: () => {
        refetchBalance()
        toast.success('Payment successfully completed')
      },
      onError: () => {
        toast.error('Failed to make payment')
      }
    }
  )

  const {
    totalBalance,
    totalDesc,
    signalDesc,
    signalBalance,
    performanceDesc,
    performanceBalance
  } = balance

  const getCardIcon = (brand) => {
    switch (brand) {
      case 'Visa':
        return <VisaIcon />
      case 'MasterCard':
        return <MastercardIcon />
      default:
        return null
    }
  }

  const cardsItems = cards.map(({ id, brand, expMonth, expYear, last4 }) => {
    const expMonthStr = String(expMonth).length === 1 ? `0${expMonth}` : expMonth
    const expYearStr = String(expYear).slice(2)

    return (
      <S.CardItem
        key={id}
        onClick={() => {
          setSelectedCardId(id)
        }}
        selected={id === selectedCardId}
      >
        {getCardIcon(brand)}

        <S.CardNumber>
          <MediaQuery minWidth={480}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>

            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </MediaQuery>

          <span></span>
          <span></span>
          <span></span>
          <span></span>

          <span>{last4}</span>
        </S.CardNumber>

        <S.CardExp>
          exp {expMonthStr}/{expYearStr}
        </S.CardExp>

        <Button
          variant="inline"
          onClick={(e) => {
            e.stopPropagation()
            deleteCard(id)
          }}
        >
          <TrashIcon />
        </Button>
      </S.CardItem>
    )
  })

  return (
    <S.BillingPayments>
      <ContentDisplay
        isLoading={isBalanceLoading || isCardsLoading || isCardDeleting}
        skeleton={<Skeleton width="100%" height={396} />}
      >
        <S.Head>
          <Typography variant="h6">{t('Billing & Payments')}</Typography>

          <Button
            variant="bordered"
            onClick={() => {
              setCreditCardModalOpen(true)
            }}
          >
            {t('+ add')}
          </Button>
        </S.Head>

        <S.CardsList>{cardsItems}</S.CardsList>

        <S.BalanceRow>
          <S.Balance>
            {totalDesc}
            <span>{totalBalance}$</span>
          </S.Balance>

          {signalDesc || performanceDesc ? (
            <Tooltip
              arrow
              title={`${signalDesc} ${signalBalance}. ${performanceDesc} ${performanceBalance}`}
              placement="top"
            >
              <InfoIcon />
            </Tooltip>
          ) : null}
        </S.BalanceRow>

        <Button
          variant="lime"
          disabled={!selectedCardId}
          isLoading={isPaymentProcessing}
          onClick={() => {
            makePayment({ cardId: selectedCardId, amount: balance?.totalBalance })
          }}
        >
          {t('Pay')}
        </Button>

        <Typography variant="body2">
          {t('Amount due is auto-billed as the month finished.')}
        </Typography>
      </ContentDisplay>

      <AddCreditCardModal
        open={isAddCreditCardModalOpen}
        refetchCards={refetchCards}
        refetchVerifications={refetchVerifications}
        onClose={() => {
          setCreditCardModalOpen(false)
        }}
      />
    </S.BillingPayments>
  )
}

import styled from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;
  background: #f7f7f7;

  p {
    margin-top: 20px;
    text-align: center;
    line-height: 24px;

    &:nth-of-type(2) {
      max-width: 434px;
    }
  }
`

export const Img = styled.div``

export const ButtonContainer = styled.div`
  margin-top: 30px;

  ${Button} {
    &.button {
      min-width: 240px;

      &::before {
        background: #f7f7f7;
      }
    }
  }
`

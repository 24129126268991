import styled from 'styled-components'

import { BoxGray } from '@styled/components'

import { Button } from '@ui/Button/Button.styled'

export const Pitch = styled(BoxGray)`
  display: flex;
  flex-direction: column;
  width: 560px;
  min-height: 631px;
  padding: 30px 40px;
  box-sizing: border-box;

  & > p {
    margin-top: 20px;
    line-height: 24px;
    white-space: pre-wrap;
  }
`

export const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${Button} {
    width: 76px;
    height: 32px;

    &:hover {
      svg {
        fill: var(--color-primary);
      }
    }

    svg {
      margin-left: 5px;
    }
  }
`

export const MinimalCapitalRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 38px;

  p {
    &:nth-child(1) {
      font-family: 'Montserrat-SemiBold';
      font-size: var(--font-size-md);
      line-height: 24px;
    }

    &:nth-child(2) {
      margin-left: 10px;
      line-height: 36px;
    }
  }
`

export const Bottom = styled.div`
  margin-top: auto;
`

export const SocialList = styled.ul`
  display: flex;
  margin-top: 25px;

  li {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
`

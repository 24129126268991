import styled, { css } from 'styled-components'

export const GetStartedText = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${({ animated }) =>
    animated &&
    css`
      animation: rotating 7s linear infinite;
    `}
`

export const CircleLime = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background: var(--color-primary);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
`

export const GetStartedCircle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 160px;
  border: 3px solid #000;
  border-radius: 50%;
  box-sizing: border-box;

  ${({ big }) =>
    big &&
    css`
      width: 283.3px;
      height: 283.3px;

      ${CircleLime} {
        width: 113.32px;
        height: 113.32px;

        svg {
          display: none;
        }
      }
    `}
`

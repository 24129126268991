import styled from 'styled-components'

import { TradersSlider } from '@components/TradersSlider/TradersSlider.styled'
import { GetStartedCircle } from '@components/GetStartedCircle/GetStartedCircle.styled'
import { SocialButtons } from '@components/SocialButtons/SocialButtons.styled'

import { media } from '@styled/media'

const media1120 = media.createMedia(1120)

export const EntryPage = styled.div`
  position: relative;

  h2 {
    margin-bottom: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50vw - 27px);
    min-height: 100vh;
    height: 100%;
    background: #f7f7f7;
  }

  ${media1120} {
    &::before {
      width: 100vw;
    }
  }

  ${media.mobile} {
    h2 {
      margin-bottom: 20px;
    }
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;

  ${GetStartedCircle} {
    position: absolute;
    left: 219px;
    bottom: 271px;
  }
`

export const TradersSliderContainer = styled.div`
  position: relative;
  z-index: 20;

  ${TradersSlider} {
    width: 362px;
    height: 412px;
    margin-top: 296px;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 362px;
      height: 203px;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(10px);
      z-index: 10;
    }
  }

  h2 {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 362px;
    height: 203px;
    padding-left: 20px;
    box-sizing: border-box;
    z-index: 15;
  }
`

export const Left = styled.div`
  width: 600px;
`

export const Right = styled.div`
  width: 560px;
  padding-left: 100px;
  box-sizing: border-box;

  ${media1120} {
    padding-left: 0;
    margin: 0 auto;
  }

  ${media.mobile} {
    h2 {
      font-size: var(--font-size-h3);
    }
  }
`

export const SocialSignUp = styled.div`
  margin-top: 40px;

  p {
    position: relative;
    font-family: 'Montserrat-Medium';
    color: #000;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 119px;
      height: 1px;
      background: #d2d3da;
      transform: translateY(-50%);
    }

    &::after {
      left: auto;
      right: 0;
    }
  }

  ${SocialButtons} {
    margin-top: 44px;
  }

  ${media.mobile} {
    margin-top: 30px;

    p {
      &::before,
      &::after {
        background: var(--color-gray-light);
      }
    }

    ${SocialButtons} {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 24px;
      margin-top: 30px;

      li {
        width: 100%;
      }
    }
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

import { CodeInput, Button } from '@ui'

import * as S from './Code.styled'

export const CodeStep = ({
  email,
  isLoading,
  code,
  onCodeChange,
  onCodeComplete,
  onCodeResend
}) => {
  return (
    <S.CodeStep>
      <Typography variant="body2">
        We sent you a confirmation code to your email - {email}
      </Typography>

      {!isLoading ? (
        <CodeInput
          autoFocus
          value={code}
          fields={6}
          onChange={onCodeChange}
          onComplete={onCodeComplete}
        />
      ) : (
        <S.Progress>
          <CircularProgress />
        </S.Progress>
      )}

      <Button variant="inline" onClick={onCodeResend}>
        send again
      </Button>
    </S.CodeStep>
  )
}

import styled, { css } from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

export const AddWatchlistButton = styled(Button)`
  && {
    min-width: 50px;
    width: 50px;
    height: 50px;
  }

  &:hover {
    svg {
      fill: var(--color-primary);
    }
  }

  ${({ small }) =>
    small &&
    css`
      &.button {
        min-width: 28px;
        width: 28px;
        height: 28px;

        svg {
          width: 14px;
          height: 14px;
        }
      }
    `}
`

import { createGlobalStyle } from 'styled-components'

import { resetStyles } from './resetStyles'
import { variablesStyles } from './variables'

export const GlobalStyles = createGlobalStyle`
  ${resetStyles}
  ${variablesStyles}

  body {
    font-family: 'Montserrat-Regular';
    font-size: var(--font-size-reg);
    color: #000;
  }

  html, #root, body {
    min-height: 100vh;
  }
  
  #root {
    scroll-behavior: smooth;
  }
`

import styled from 'styled-components'

const getRiskColor = (risk) => {
  switch (risk) {
    case 'LOW':
      return 'var(--color-green)'
    case 'MODERATE':
      return '#ACACAC'
    case 'HIGH':
      return 'var(--color-red)'
    default:
      return
  }
}

export const InvestorCard = styled.div`
  background: #fff;
`

export const Top = styled.div`
  display: flex;
  padding: 20px;
`

export const Avatar = styled.div`
  width: 75px;
  height: 75px;
  flex-shrink: 0;

  img {
    max-width: 100%;
  }
`

export const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 13px;
  overflow: hidden;

  p {
    font-size: var(--font-size-reg);
    line-height: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const Username = styled.p`
  display: flex;
  align-items: center;
  font-family: 'Montserrat-SemiBold';

  a {
    color: #000;
    text-decoration: none;
  }
`

export const FullName = styled.p``

export const Risk = styled.p`
  margin-top: auto;
  font-size: var(--font-size-sm);
  line-height: 18px;
  text-transform: uppercase;

  span {
    font-family: 'Montserrat-Bold';
    color: ${({ risk }) => getRiskColor(risk)};
  }
`

export const Star = styled.span`
  padding-right: 5px;
`

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  border-top: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;

  p {
    margin-top: 5px;
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-sm);
    line-height: 18px;
  }
`

export const StatsCol = styled.div``

export const Info = styled.div`
  margin-top: 14px;
  padding: 0 20px;
`

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  span {
    line-height: 21px;
  }
`

export const InfoLabel = styled.span``

export const InfoValue = styled.span`
  font-family: 'Montserrat-Medium';
`

export const Bottom = styled.div`
  margin-top: 20px;
  padding: 0 20px 20px;
`

export const Actions = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 10px;
`

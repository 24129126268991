import styled from 'styled-components'

export const AgreeText = styled.span`
  font-size: var(--font-size-md);

  a {
    font-family: 'Montserrat-Medium';
    color: #000;

    &:hover {
      text-decoration: none;
    }
  }
`

import styled, { css } from 'styled-components'
import MuiButton from '@mui/material/Button'

export const getVariantStyles = (variant) => {
  switch (variant) {
    case 'lime':
      return css`
        color: #000;
        background: #000;

        &::before {
          background: var(--color-primary);
        }

        &:hover {
          background: #000;
          color: var(--color-primary);
        }
      `

    case 'bordered':
      return css`
        background: transparent;
        border: 2px solid #000;

        &::before {
          background: transparent;
        }

        &:hover {
          background: #000;
          color: var(--color-primary);
        }
      `

    case 'inline':
      return css`
        width: auto;
        height: auto;
        background: transparent;
        color: #000;
        transition: 0.3s;

        &::before,
        &::after {
          display: none;
        }

        &:hover {
          background: transparent;
          opacity: 0.6;

          & > span > span {
            animation: none;
          }
        }
      `

    default:
      return css`
        background: var(--color-primary);
        color: var(--color-primary);

        &:hover {
          background: var(--color-primary);
          color: #000;
        }
      `
  }
}

export const getHeightStyles = (height) => {
  switch (height) {
    case 'big':
      return css`
        height: 64px;
      `

    case 'medium':
      return css`
        height: 50px;
      `

    default:
      return css`
        height: 60px;
      `
  }
}

export const Button = styled((props) => (
  <MuiButton {...props} classes={{ root: 'button', disabled: 'disabled' }} />
))`
  &.button {
    width: 100%;
    padding: 0;
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-md);
    border: none;
    border-radius: 0;
    overflow: hidden;

    ${({ height }) => getHeightStyles(height)}
    ${({ variant }) => getVariantStyles(variant)}
  }

  &.button.disabled {
    background: transparent;
    border: 1px solid #dddee6;
    color: rgba(0, 0, 0, 0.3);

    &::before,
    &::after {
      display: none;
    }

    /* circular progress */
    svg {
      color: #000;
    }
  }

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &::before {
    content: '';
    background: #000;
    transition: transform 0.3s cubic-bezier(0.7, 0, 0.2, 1);
    transform-origin: 100% 50%;
  }

  &:hover {
    & > span > span {
      animation: MoveUpInitial 0.2s forwards, MoveUpEnd 0.2s forwards 0.2s;
    }

    &::before {
      transform: scale3d(0, 1, 1);
      transform-origin: 0% 50%;
    }
  }

  span {
    position: relative;
    display: block;
    z-index: 10;
  }

  & > span {
    overflow: hidden;
  }

  & > span > span {
    display: flex;
    align-items: center;
    overflow: hidden;
    mix-blend-mode: difference;
  }

  @keyframes MoveUpInitial {
    to {
      transform: translate3d(0, -105%, 0);
    }
  }

  @keyframes MoveUpEnd {
    from {
      transform: translate3d(0, 100%, 0);
    }

    to {
      transform: translate3d(0, 0, 0);
    }
  }
`

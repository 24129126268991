import React, { forwardRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import * as S from './Checkbox.styled'

export const Checkbox = forwardRef(({ name, label, required = true, ...props }, ref) => {
  const { control } = useFormContext()

  return (
    <S.Checkbox>
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field, fieldState }) => {
          const { error } = fieldState

          const checkbox = (
            <S.CheckboxInput
              {...props}
              {...field}
              checked={field.value}
              ref={ref}
              error={error?.type}
            />
          )

          return label ? <S.Label label={label} control={checkbox} /> : checkbox
        }}
      />
    </S.Checkbox>
  )
})

import styled from 'styled-components'

export const PercentStats = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Montserrat-Medium';
  font-size: var(--font-size-reg);

  span {
    padding-left: 5px;
    font-family: 'Montserrat-SemiBold';
    line-height: 24px;
    color: ${({ trend }) => {
      return trend === 'up' ? 'var(--color-green)' : 'var(--color-red)'
    }};
  }
`

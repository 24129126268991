import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

import { TextField, PasswordField, Button } from '@ui'

import { useAuth } from '@contexts/auth'
import { ROUTE_NAMES } from '@core/routes'
import * as AuthAPI from '@api/auth'

import * as S from './LoginForm.styled'

export const LoginForm = () => {
  const { t } = useTranslation()

  const useFormProps = useForm()
  const { setAuth } = useAuth()
  const navigate = useNavigate()
  const { handleSubmit } = useFormProps

  const login = useMutation(({ login, password }) => AuthAPI.login(login, password), {
    onError: ({ message }) => {
      toast.error(message)
    },
    onSuccess: () => {
      setAuth(true)
      navigate(ROUTE_NAMES.ACCOUNT_SETTINGS)
    }
  })

  return (
    <FormProvider {...useFormProps}>
      <S.LoginForm onSubmit={handleSubmit(login.mutate)}>
        <TextField name="login" label={t('Login')} />

        <PasswordField name="password" label={t('Password')} />

        <Link to={ROUTE_NAMES.FORGOT_PASSWORD}>{t('Forgot password?')}</Link>

        <Button type="submit" variant="lime" isLoading={login.isLoading}>
          {t('Login')}
        </Button>
      </S.LoginForm>
    </FormProvider>
  )
}

import styled from 'styled-components'

import { media } from '@styled/media'

const media1010 = media.createMedia(1010)

export const Footer = styled.footer`
  position: relative;
  z-index: 100;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  .react-reveal {
    animation: ${({ animated }) => !animated && 'none !important'};
  }

  ${media1010} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Copyright = styled.div``

export const NavList = styled.ul`
  display: flex;
  margin-left: auto;
  height: 100%;

  li {
    display: flex;
    align-items: center;
    height: 39px;

    &:not(:last-child) {
      margin-right: 56px;
    }

    a {
      padding: 10px;
      font-size: var(--font-size-md);
      color: #000;
      text-decoration: none;
    }
  }

  ${media1010} {
    margin-top: 10px;
    margin-left: -10px;
  }

  ${media.createMedia(832)} {
    justify-content: space-between;
    width: 100%;
    margin-left: 0;

    li {
      &:not(:last-child) {
        margin-right: 0;
      }

      a {
        padding: 0;
        font-size: var(--font-size-reg);
      }
    }
  }

  ${media.createMedia(660)} {
    flex-wrap: wrap;
  }
`

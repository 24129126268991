import React, { forwardRef } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import * as S from './TextField.styled'

export const TextField = forwardRef(
  ({ a, name, defaultValue, required = true, rules, ...props }, ref) => {
    const { control } = useFormContext()

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={{ required, ...rules }}
        transform
        render={({ field, fieldState }) => {
          const { error } = fieldState

          return (
            <S.TextField
              {...props}
              {...field}
              ref={ref}
              name={name}
              error={error?.type}
              placeholder={typeof props.placeholder === 'string' ? props.placeholder : props.label}
              helperText={error?.message}
              defaultValue={defaultValue}
              labelShrink={field.value}
            />
          )
        }}
      />
    )
  }
)

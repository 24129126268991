import styled from 'styled-components'

import { SelectFormControl, SelectInput } from '@ui/Select/Select.styled'

import { BoxGray } from '@styled/components'

export const MonthlyPnl = styled(BoxGray)`
  padding: 30px;
`

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${SelectFormControl}, ${SelectInput} .select-menu-paper {
    width: 180px;
  }
`

export const Bar = styled.div`
  background: #fff;
  margin-left: 50px;
`

export const BarContainer = styled.div`
  position: relative;
  height: 200px;
  margin-top: 20px;
  padding: 5px;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: calc(100% - 5px);
    height: 1px;
    background: #9da0a8;
    transform: translateY(-50%);
    z-index: 10;
  }
`

export const BarList = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 7px;
  height: 100%;
`

export const BarItem = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;

  span {
    width: 100%;
  }
`

export const BarUp = styled.div`
  position: relative;
  background: #e9f8d9;

  span {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    background: #a0f64a;
  }
`

export const BarDown = styled.div`
  position: relative;
  background: #ffe8e8;

  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #ff6e66;
  }
`

export const MonthStatList = styled.ul`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  justify-content: space-between;
  margin-top: 5px;
  padding: 0 5px;
`

export const MonthReturnItem = styled.li`
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #f7f7f7;
  font-size: var(--font-size-md);
  text-align: center;
`

export const Month = styled.p`
  width: 100%;
  padding: 10px 0;
  border-bottom: 2px solid #f7f7f7;
  font-family: 'Montserrat-SemiBold';
  color: #a5a5b1;
  box-sizing: border-box;
`

export const Return = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  height: 100%;
  min-height: 24px;
  font-family: 'RobertSans-Medium';
  color: ${({ color }) => (color === 'green' ? '#69D200' : '#FF6E66')};
`

export const TotalRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 25px;

  p {
    font-family: 'Montserrat-SemiBold';
    line-height: 24px;

    &:nth-child(1) {
      color: #a5a5b1;
      text-transform: uppercase;
    }
  }
`

export const TotalReturnValue = styled.p`
  margin-left: 12px;
  font-size: var(--font-size-md);
  color: ${({ color }) => (color === 'green' ? '#69D200' : '#FF6E66')};
`

export const Pnl = styled.span`
  position: absolute;
  left: -50px;
  color: #9da0a8;
  line-height: 21px;
`

export const PnlMax = styled(Pnl)`
  top: 0;
`

export const PnlMin = styled(Pnl)`
  bottom: 0;
`

export const ZeroLine = styled.div``

export const Zero = styled(Pnl)`
  top: 50%;
  left: -22px;
  transform: translateY(-50%);
`

import React from 'react'
import { useTranslation } from 'react-i18next'

import { TextField, PasswordField, Checkbox, Button } from '@ui'

import * as S from './Data.styled'

export const DataStep = ({ isLoading }) => {
  const { t } = useTranslation()

  return (
    <>
      <TextField name="login" label={t('Login')} />

      <TextField type="email" name="email" label="Email" />

      <PasswordField name="password" label={t('Password')} />

      <Checkbox
        name="agreement"
        label={
          <S.AgreeText>
            {t('I agree to')}{' '}
            <a href="https://helperproducts.notion.site/Terms-Conditions-f1eb66866c5b4e948a75d9d1c75ca80c">
              {t('Terms&Conditions')}
            </a>{' '}
            {t('and')}{' '}
            <a href="https://helperproducts.notion.site/Privacy-policy-60612fd4802748d18adc48ecb70cfef8">
              {t('Privacy')}.
            </a>
          </S.AgreeText>
        }
      />

      <Button type="submit" variant="lime" isLoading={isLoading}>
        {t('Create account')}
      </Button>
    </>
  )
}

import styled from 'styled-components'

import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

export const Tabs = styled((props) => (
  <MuiTabs
    classes={{
      root: 'tabs',
      scroller: 'tabs-scroller',
      indicator: 'tabs-indicator',
      flexContainer: 'tabs-container'
    }}
    {...props}
  />
))`
  &.tabs {
    height: 80px;
    padding: 0 4px;
    background: #f7f7f7;
  }

  .tabs {
    &-scroller {
    }

    &-indicator {
      height: 4px;
      background: #000;
    }

    &-container {
      height: 100%;
    }
  }
`

export const Tab = styled((props) => (
  <MuiTab
    classes={{ root: 'tab', selected: 'tab-selected' }}
    disableFocusRipple
    disableRipple
    {...props}
  />
))`
  &.tab {
    width: 148px;
    min-height: 100%;
    padding: 0;
    font-family: 'Montserrat-SemiBold';
    font-size: var(--font-size-md);
    color: #a5a5b1;
    transition: 0.3s;

    &.tab-selected {
      color: #000;
    }
  }
`

import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TextField, Select, Checkbox, Button, Skeleton, MenuItem, Tooltip } from '@ui'

import { ROUTE_NAMES } from '@core/routes'
import * as UsersAPI from '@api/users'
import { getCurrencySymbol } from '@utils'

import * as S from './InvestorCopyModal.styled'

import { ReactComponent as InfoIcon } from '@assets/icons/info.svg'
import { ReactComponent as PlusIcon } from '@assets/icons/plus.svg'
import { ReactComponent as MinusIcon } from '@assets/icons/minus.svg'

export const InvestorCopyModal = ({
  open,
  avatar,
  username,
  fullName,
  pnlYear,
  minimalCapital = 0,
  currency,
  onClose,
  onCopy
}) => {
  const { t } = useTranslation()
  const useFormProps = useForm({
    mode: 'onChange'
  })
  const { setValue, reset, watch, handleSubmit } = useFormProps
  const { copyAmount, freeAmount, brokername } = watch()

  const { isLoading: isBrokerBalanceLoading, mutate: getBrokerBalance } = useMutation(
    (username) => UsersAPI.getBrokerBalance(username),
    {
      onSuccess: (brokerBalance) => {
        const brokerBalanceValue = brokerBalance.find(
          (balance) => balance.currency === currency
        )?.value

        setValue('freeAmount', brokerBalanceValue || 0)
      }
    }
  )

  const { data: brokersConnected = [], isLoading: isBrokersConnectedLoading } = useQuery(
    'brokers-connected',
    UsersAPI.getConntectedBrokers
  )

  const copyInvestor = useMutation(
    ({ username, brokername, mapping, copyAmount }) =>
      UsersAPI.addUserToPortfolio({ username, brokername, mapping, copyAmount }),
    {
      onError: () => {
        toast.error('Failed to copy')
      },
      onSuccess: () => {
        toast.success('Successfully copied')
        onCopy()
        onClose()
      }
    }
  )

  const currencySymbol = currency ? getCurrencySymbol(currency) : null

  useEffect(() => {
    if (brokername) {
      getBrokerBalance(brokername)
    }
  }, [brokername, getBrokerBalance])

  useEffect(() => {
    if (typeof copyAmount === 'undefined') {
      reset({
        copyAmount: minimalCapital
      })
    }
  }, [copyAmount, minimalCapital, reset])

  const decreaseAmount = () => {
    const value = Number(copyAmount) - 1000

    if (value < 0) {
      setValue('copyAmount', 0)
    } else {
      setValue('copyAmount', value)
    }
  }

  const increaseAmount = () => {
    setValue('copyAmount', Number(copyAmount) + 1000)
  }

  const brokersItems = brokersConnected.map(({ name }) => {
    return (
      <MenuItem key={name} value={name}>
        {name}
      </MenuItem>
    )
  })

  const onCopySubmit = ({ brokername, mapping, copyAmount }) => {
    copyInvestor.mutate({ username, brokername, mapping, copyAmount })
  }

  return (
    <S.InvestorCopyModal title="Copy" open={open} onClose={onClose}>
      <FormProvider {...useFormProps}>
        <S.Form onSubmit={handleSubmit(onCopySubmit)}>
          <S.UserRow>
            <S.User>
              <S.Avatar>
                <img src={avatar} alt={fullName} />
              </S.Avatar>

              <S.UserRight>
                <Typography>{username}</Typography>
                <Typography>{fullName}</Typography>
              </S.UserRight>
            </S.User>

            <S.Stats>
              <S.ReturnValue color={pnlYear > 0 ? 'green' : 'red'}>{pnlYear}%</S.ReturnValue>
              <Typography>12 months return</Typography>
            </S.Stats>
          </S.UserRow>

          <S.ParamsRow>
            {!isBrokerBalanceLoading ? (
              <TextField
                name="freeAmount"
                label={t('Free Amount')}
                placeholder=""
                InputProps={{
                  startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
                }}
                disabled
              />
            ) : (
              <Skeleton height={64} />
            )}

            {!isBrokersConnectedLoading ? (
              <Select name="brokername" label={t('Account to trade')} rules={{ required: true }}>
                {brokersItems}
              </Select>
            ) : (
              <Skeleton height={64} />
            )}
          </S.ParamsRow>

          <S.InstrumentsMapping>
            <Checkbox
              required={false}
              name="mapping"
              label={t('use instruments mapping')}
              checked
            />

            <Tooltip
              placement="top"
              title={
                <>
                  If there is no exact same instruments in traders portfolio you try to copy and
                  available from your broker, our algorithm finds the best match from your broker’s
                  available instruments. More in <Link to={ROUTE_NAMES.HELP}>FAQ</Link>.
                </>
              }
              arrow
            >
              <InfoIcon />
            </Tooltip>
          </S.InstrumentsMapping>

          <S.CopyAmountRow>
            <Button variant="lime" onClick={decreaseAmount}>
              <MinusIcon />
            </Button>

            <TextField
              type="number"
              name="copyAmount"
              label={t('Copy Amount')}
              placeholder=""
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>
              }}
              rules={{ required: true, maxLength: 6 }}
            />

            <Button variant="lime" onClick={increaseAmount}>
              <PlusIcon />
            </Button>
          </S.CopyAmountRow>

          <Button
            type="submit"
            variant="lime"
            isLoading={copyInvestor.isLoading}
            disabled={!freeAmount || !Number(copyAmount)}
          >
            {t('Copy')}
          </Button>
        </S.Form>
      </FormProvider>
    </S.InvestorCopyModal>
  )
}

import styled from 'styled-components'

import { Modal } from '@ui'

import { ModalContent } from '@ui/Modal/Modal.styled'
import { PasswordField } from '@ui/PasswordField/PasswordField.styled'
import { Button } from '@ui/Button/Button.styled'

export const ChangePasswordModal = styled(Modal)`
  ${ModalContent} {
    width: 460px;
  }
`

export const Form = styled.form`
  ${PasswordField} {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

  ${Button} {
    margin-top: 30px;
  }
`

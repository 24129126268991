import React, { useState } from 'react'
import Typography from '@mui/material/Typography'

import * as S from './WithViewToggler.styled'

import { ReactComponent as ListViewIcon } from '@assets/icons/list.svg'
import { ReactComponent as GridViewIcon } from '@assets/icons/grid.svg'

export const WithViewToggler = ({ heading, list, grid }) => {
  const [view, setView] = useState('grid')

  const togglers = [
    { icon: <ListViewIcon />, value: 'list' },
    { icon: <GridViewIcon />, value: 'grid' }
  ]

  const toggleButtons = togglers.map(({ value, icon }) => (
    <S.ToggleButton key={value} active={view === value} onClick={() => setView(value)}>
      {icon}
    </S.ToggleButton>
  ))

  return (
    <S.WithViewToggler>
      <S.Top>
        {typeof heading === 'string' ? (
          <Typography variant="h3" component="h1">
            {heading}
          </Typography>
        ) : (
          heading
        )}

        <S.Togglers>{toggleButtons}</S.Togglers>
      </S.Top>

      {view === 'grid' ? grid : list}
    </S.WithViewToggler>
  )
}

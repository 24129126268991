import React from 'react'
import Typography from '@mui/material/Typography'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { PageLayout, NoData } from '@components'
import { Section, Chat } from './components'
import { Autocomplete } from '@ui'

import * as S from './Help.styled'

import { ReactComponent as CheckmarkIcon } from '@assets/icons/checkmark-circle-outline.svg'
import { ReactComponent as FileCopyIcon } from '@assets/icons/file-copy.svg'
import { ReactComponent as DollarIcon } from '@assets/icons/dollar.svg'
import { ReactComponent as WarningIcon } from '@assets/icons/warning.svg'
import { ReactComponent as UserIcon } from '@assets/icons/user.svg'
import { ReactComponent as PulseIcon } from '@assets/icons/pulse.svg'

export const HelpPage = () => {
  const { t } = useTranslation()

  const useFormProps = useForm({
    defaultValues: {
      questionSearch: ''
    }
  })
  const { watch } = useFormProps

  const questionSearch = watch('questionSearch')

  const sections = [
    {
      icon: <CheckmarkIcon />,
      label: t('Automate & Research'),
      qa: [
        {
          q: t('Do you have an OpenAPI?'),
          a: t('help-ar-answer-1')
        }
      ]
    },
    {
      icon: <FileCopyIcon />,
      label: t('Copy Trading'),
      qa: [
        {
          q: t('How to copy traders works?'),
          a: t('help-ct-answer-1')
        },
        {
          q: t('How instruments matching works cross-brokers?'),
          a: t('help-ct-answer-2')
        }
      ]
    },
    {
      icon: <DollarIcon />,
      label: t('Fees & Rewards'),
      qa: [
        {
          q: t('What reward a trader gets?'),
          a: t('help-fr-answer-1')
        },
        {
          q: t('What are investor fees?'),
          a: t('help-fr-answer-2')
        }
      ]
    },
    {
      icon: <WarningIcon />,
      label: t('Security'),
      qa: [
        {
          q: t('Are my credit card data safe?'),
          a: t('help-sec-answer-1')
        },
        {
          q: t('Are my broker account data safe?'),
          a: t('help-sec-answer-2')
        }
      ]
    },
    {
      icon: <UserIcon />,
      label: t('My Account'),
      qa: [
        {
          q: t('How many brokers can I connect?'),
          a: t('help-acc-answer-1')
        },
        {
          q: t('Deleting account'),
          a: t('help-acc-answer-2')
        }
      ]
    },
    {
      icon: <PulseIcon />,
      label: t('Trading & Investing'),
      qa: [
        {
          q: t('How to start investing (verify account)?'),
          a: t('help-ti-answer-1')
        },
        {
          q: t('How to become a trader?'),
          a: t('help-ti-answer-2')
        }
      ]
    }
  ]

  const withSearch = (sections, search) => {
    if (!search.length) {
      return sections
    }

    return sections.filter(({ qa }) => {
      return qa.some(({ q }) => q.includes(search))
    })
  }

  const sectionsItems = withSearch(sections, questionSearch).map(({ icon, label, qa }) => (
    <Section key={label} icon={icon} heading={label} qa={qa} />
  ))

  return (
    <PageLayout>
      <S.Content>
        <Typography variant="h1">{t('Have Any Questions?')}</Typography>

        <FormProvider {...useFormProps}>
          <Autocomplete
            name="questionSearch"
            options={[]}
            placeholder={t('Type Your Question Here')}
            freeSolo
          />
        </FormProvider>

        {sectionsItems.length ? (
          <S.SectionsList>{sectionsItems}</S.SectionsList>
        ) : (
          <NoData heading={t('Questions not found')} />
        )}

        <Chat />
      </S.Content>
    </PageLayout>
  )
}

import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import TimeAgo from 'react-timeago'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import * as S from './PostPreview.styled'

import { ReactComponent as ArrowUpIcon } from '@assets/icons/arrow-up.svg'
import { ReactComponent as MessageIcon } from '@assets/icons/message.svg'
import { ReactComponent as ShareIcon } from '@assets/icons/share.svg'

import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import enStrings from 'react-timeago/lib/language-strings/en'
import ruStrings from 'react-timeago/lib/language-strings/ru'

export const PostPreview = ({
  datetime,
  category,
  postBy,
  title,
  photo,
  body,
  commentsCount,
  likesCount,
  liked,
  onLike,
  onLikeDelete,
  onPostOpen
}) => {
  const {
    t,
    i18n: { language }
  } = useTranslation()

  const timeFormatter = buildFormatter(language === 'en' ? enStrings : ruStrings)

  const { pathname } = useLocation()
  const [likeStatus, setLikeStatus] = useState(liked)
  const [likes, setLikes] = useState(likesCount)
  const commentsLabel =
    commentsCount.length === 1 ? '1 comment' : `${commentsCount} ${t('comments')}`

  const onLikeClick = () => {
    if (likeStatus) return

    setLikeStatus(true)
    setLikes(likes + 1)
    onLike()
  }

  const onDislikeClick = () => {
    if (likeStatus) {
      setLikeStatus(false)
      setLikes(likes - 1)
      onLikeDelete()
    }
  }

  const onShare = () => {
    navigator.share({
      url: pathname,
      title,
      text: body
    })
  }

  return (
    <S.PostPreview>
      <S.Content>
        <S.Top>
          <S.Category>{category}</S.Category>

          <S.PostedBy>
            <Typography>{t('posted by')}</Typography>
            <Typography>{postBy}</Typography>
          </S.PostedBy>

          <S.Datetime>
            <TimeAgo date={datetime} formatter={timeFormatter} />
          </S.Datetime>
        </S.Top>

        <S.Body>
          <Typography variant="h6" onClick={onPostOpen}>
            {title}
          </Typography>

          {photo ? (
            <img src={photo} alt="photo" />
          ) : (
            <Typography variant="body2">
              <span dangerouslySetInnerHTML={{ __html: body }} />
            </Typography>
          )}
        </S.Body>

        <S.Bottom>
          <S.Comments onClick={onPostOpen}>
            <MessageIcon />

            <Typography component="span">{commentsLabel}</Typography>
          </S.Comments>

          <S.Share onClick={onShare}>
            <ShareIcon />
            <Typography component="span">{t('Share')}</Typography>
          </S.Share>
        </S.Bottom>
      </S.Content>

      <S.Likes>
        <S.LikeButton onClick={onLikeClick}>
          <ArrowUpIcon />
        </S.LikeButton>

        <S.LikesCount>{likes}</S.LikesCount>

        <S.DislikeButton onClick={onDislikeClick}>
          <ArrowUpIcon />
        </S.DislikeButton>
      </S.Likes>
    </S.PostPreview>
  )
}

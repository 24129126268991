import styled from 'styled-components'
import ReactQuill from 'react-quill'

export const TextEditor = styled(ReactQuill)`
  background: #fff;
  font-family: 'Montserrat-Regular';

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  .ql {
    &-toolbar {
      padding: 20px;
      border-color: var(--color-gray-light);

      button {
        width: 24px;
        padding: 0;

        &:hover {
          svg {
            fill: #06c;
          }
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    &-container {
      border-color: var(--color-gray-light);
    }

    &-editor {
      min-height: 180px;
      padding: 20px;
      font-family: 'Montserrat-Regular';
      font-size: var(--font-size-md);
      color: #000;
      line-height: 24px;
    }

    &-active {
      svg {
        fill: #06c;
      }
    }

    &-tooltip {
      transform: translate(140px, -30px);
    }

    &-blank {
      &::before {
        left: 20px;
        font-family: 'Montserrat-Regular';
        font-size: var(--font-size-md);
        font-style: normal;
        color: var(--color-gray-dark);
      }
    }
  }
`

import styled from 'styled-components'

import { AutocompleteContainer } from '@ui/Autocomplete/Autocomplete.styled'
import { InvestorCopyButton } from '@ui/InvestorCopyButton/InvestorCopyButton.styled'

export const Content = styled.div``

export const HeadingRow = styled.div`
  display: flex;
  justify-content: space-between;

  h3 {
    line-height: 45px;
  }

  ${AutocompleteContainer} {
    width: 860px;
  }
`

export const Top = styled.div`
  display: grid;
  grid-template-columns: 285px 452px 1fr;
  grid-gap: 120px;
  margin-top: 60px;

  ${InvestorCopyButton} {
    max-width: 160px;
    justify-self: flex-end;
  }
`

export const TabContent = styled.div`
  margin-top: 40px;
`

import React, { useEffect } from 'react'
import Typography from '@mui/material/Typography'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Select, Skeleton, MenuItem } from '@ui'

import * as S from './MonthlyPnl.styled'

export const MonthlyPnl = ({ isLoading, pnlMonthly = [] }) => {
  const { t } = useTranslation()

  const useFormProps = useForm({
    defaultValues: {
      year: ''
    }
  })
  const { watch, reset } = useFormProps

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]

  const year = watch('year')

  useEffect(() => {
    if (pnlMonthly.length) {
      reset({
        year: pnlMonthly[0].year
      })
    }
  }, [pnlMonthly, reset])

  const getMonthlyRetunColor = (value) => {
    return value > 0 ? 'green' : 'red'
  }

  const getMonthReturnValue = (month, pnl) => {
    return pnl?.find(({ closingDate }) => {
      const monthLetters = new Date(closingDate).toLocaleString('en-us', { month: 'short' })
      return month === monthLetters
    })?.monthlyReturn
  }

  const pnlYearSelected = pnlMonthly.find((pnl) => pnl.year === year)?.pnl
  const pnlYearTotal = pnlYearSelected?.reduce((sum, { monthlyReturn }) => sum + monthlyReturn, 0)

  const pnlMax = pnlYearSelected?.length
    ? Math.ceil(Math.max(...pnlYearSelected?.map(({ monthlyReturn }) => Math.abs(monthlyReturn))))
    : 0

  const yearMenuItems = pnlMonthly.map(({ year }) => {
    return (
      <MenuItem key={year} value={year}>
        {year}
      </MenuItem>
    )
  })

  const monthStatItems = months?.map((month) => {
    const currMonthPnl = getMonthReturnValue(month, pnlYearSelected)

    return (
      <S.MonthReturnItem key={month}>
        <S.Month>{month}</S.Month>

        <S.Return color={getMonthlyRetunColor(currMonthPnl)}>{currMonthPnl}</S.Return>
      </S.MonthReturnItem>
    )
  })

  const barItems = months?.map((month) => {
    const currMonthPnl = getMonthReturnValue(month, pnlYearSelected)

    if (!currMonthPnl) {
      return (
        <S.BarItem key={month}>
          <S.BarUp />
          <S.BarDown />
        </S.BarItem>
      )
    }

    return (
      <S.BarItem key={month}>
        {currMonthPnl > 0 ? (
          <>
            <S.BarUp>
              <span
                style={{ height: `${(Math.abs(currMonthPnl) / Math.abs(pnlMax)) * 100}%` }}
              ></span>
            </S.BarUp>

            <S.BarDown />
          </>
        ) : (
          <>
            <S.BarUp />

            <S.BarDown>
              <span style={{ height: `${(Math.abs(currMonthPnl) / Math.abs(pnlMax)) * 100}%` }} />
            </S.BarDown>
          </>
        )}
      </S.BarItem>
    )
  })

  return (
    <S.MonthlyPnl>
      <S.Top>
        <Typography variant="h4">{t('Monthly PNL')}</Typography>

        <FormProvider {...useFormProps}>
          {!isLoading ? (
            <Select name="year" label={t('Year')}>
              {yearMenuItems}
            </Select>
          ) : (
            <Skeleton width={180} height={73} />
          )}
        </FormProvider>
      </S.Top>

      {!isLoading ? (
        <S.Bar>
          <S.BarContainer>
            <S.PnlMax>{pnlMax}</S.PnlMax>

            <S.BarList>{barItems}</S.BarList>

            <S.PnlMin>-{pnlMax}</S.PnlMin>

            <S.Zero>0</S.Zero>

            <S.ZeroLine />
          </S.BarContainer>

          <S.MonthStatList>{monthStatItems}</S.MonthStatList>

          <S.TotalRow>
            <Typography variant="body2">{t('Year total:')}</Typography>

            <S.TotalReturnValue color={getMonthlyRetunColor(pnlYearTotal)}>
              {pnlYearTotal?.toFixed(2)}
            </S.TotalReturnValue>
          </S.TotalRow>
        </S.Bar>
      ) : (
        <Skeleton height={362} style={{ marginTop: 20 }} />
      )}
    </S.MonthlyPnl>
  )
}

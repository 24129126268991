import React from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { Stepper, Step, StepLabel, Skeleton } from '@ui'

import * as S from './InvestVerifySteps.styled'

import { ReactComponent as CheckmarkCircleIcon } from '@assets/icons/checkmark-circle.svg'

export const InvestVerifySteps = ({ isLoading, verifications }) => {
  const { t } = useTranslation()

  const { investVerified, isInvestor, emailVerified, personalInfo, brokerLinked, billingLinked } =
    verifications

  const steps = [
    { label: t('verify email'), completed: emailVerified },
    { label: t('fill My info'), completed: personalInfo },
    { label: t('connect with minimum 1 broker'), completed: brokerLinked },
    { label: t('in Billing & Payments add card'), completed: billingLinked }
  ]

  const isVerify = investVerified && isInvestor

  return (
    <S.InvestVerifySteps>
      <Typography variant="h6">{t('Verify to invest')}</Typography>

      {!isLoading ? (
        <Stepper orientation="vertical">
          {steps.map(({ label, completed }) => (
            <Step key={label} completed={completed}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Skeleton height={220} />
      )}

      {isVerify && (
        <S.VerifiedRow>
          <CheckmarkCircleIcon />
          <Typography variant="body2">{t('Verified')}</Typography>
        </S.VerifiedRow>
      )}
    </S.InvestVerifySteps>
  )
}

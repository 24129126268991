import styled from 'styled-components'

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.5px 3.5px 3.5px;
  border-color: transparent transparent #dddee6 transparent;
  cursor: pointer;
`

export const SortTriangles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 7px;
  height: 14px;

  ${Triangle}:last-child {
    transform: rotate(180deg);
  }
`

import styled from 'styled-components'
import MuiAutocomplete from '@mui/material/Autocomplete'

import { TextField } from '@ui/TextField/TextField.styled'

import { customScrollStyles } from '@styled/customScrollStyles'
import { media } from '@styled/media'

export const Autocomplete = styled((props) => (
  <MuiAutocomplete
    {...props}
    classes={{
      root: 'autocomplete-root',
      popper: 'autocomplete-popper',
      paper: 'autocomplete-paper',
      noOptions: 'autocomplete-no-options',
      listbox: 'autocomplete-listbox',
      loading: 'autocomplete-loading'
    }}
    popupIcon={null}
    clearIcon={null}
    disablePortal
  />
))`
  width: 100%;

  &.autocomplete-root {
    .input-root {
      border: none;
      border-left: 1px solid var(--color-gray-light);

      input {
        margin-top: 0;
        padding: 0;
      }
    }
  }
`

export const AutocompleteContainer = styled.div`
  display: flex;
  border: 1px solid var(--color-gray-light);

  .autocomplete {
    &-paper {
      width: 100%;
      border-radius: 0;
      border: 1px solid var(--color-gray-light);
      box-shadow: var(--box-shadow);
    }

    &-no-options,
    &-loading {
      padding: 20px;
      font-size: var(--font-size-reg);
      color: #000;
    }

    &-listbox {
      padding-top: 20px;
      padding-bottom: 20px;
      border: 1px solid var(--color-gray-light);
      border-top: none;
      box-sizing: border-box;

      ${customScrollStyles}

      li {
        padding: 0 20px;
        font-family: 'Montserrat-Regular';
        font-size: var(--font-size-md);

        &[aria-selected='true'],
        &.Mui-focused[aria-selected='true'],
        &.Mui-focused,
        &.Mui-selected,
        &:hover {
          background: #fff;
        }

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }
  }

  ${media.mobile} {
    .autocomplete {
      &-no-options,
      &-loading {
        padding: 15px;
      }

      &-listbox {
        padding-top: 10px;
        padding-bottom: 10px;

        li {
          min-height: 30px;
          padding: 0 10px;
        }
      }
    }

    ${TextField} {
      height: 60px;

      .input-root {
        padding: 0 10px;

        input {
          font-size: var(--font-size-reg);
        }
      }
    }
  }
`

export const Loupe = styled.div`
  padding: 19px;
  background: #fff;

  ${media.mobile} {
    padding: 17px 18px;
  }
`

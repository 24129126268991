import React from 'react'

import { Skeleton } from '@ui'

import * as S from './User.styled'

import { ReactComponent as StarFilledIcon } from '@assets/icons/star-filled.svg'

export const User = ({ isLoading, avatar, username, fullName, isTrader }) => {
  if (isLoading) {
    return <Skeleton height={80} />
  }

  return (
    <S.User>
      <S.Avatar>
        <img src={avatar} alt={fullName} />
      </S.Avatar>

      <S.UserRight>
        <S.FullName>
          {fullName}

          {isTrader && (
            <S.Star>
              <StarFilledIcon />
            </S.Star>
          )}
        </S.FullName>

        <S.Username>{username}</S.Username>
      </S.UserRight>
    </S.User>
  )
}

import React, { useRef } from 'react'
import Typography from '@mui/material/Typography'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'

import { InvestorCard } from '@components'

import * as S from './InvestorsCarousel.styled'

import { ReactComponent as ArrowLeftGrayIcon } from '@assets/icons/arrow-left-gray.svg'

SwiperCore.use([Navigation])

export const InvestorsCarousel = ({ heading, investors, onInvestorCopy }) => {
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  if (!investors.length) {
    return null
  }

  const investorsCardSlides = investors.map(({ username, watching, copied, info, performance }) => {
    return (
      <SwiperSlide key={`${heading}${username}`}>
        <InvestorCard
          watching={watching}
          copied={copied}
          info={info}
          performance={performance}
          onCopy={onInvestorCopy}
        />
      </SwiperSlide>
    )
  })

  return (
    <S.InvestorsCarousel>
      <S.Top>
        <Typography variant="h6" component="h2">
          {heading}
        </Typography>

        <S.SliderNav>
          <button ref={prevRef}>
            <ArrowLeftGrayIcon />
          </button>

          <button ref={nextRef}>
            <ArrowLeftGrayIcon />
          </button>
        </S.SliderNav>
      </S.Top>

      <Swiper
        onInit={({ params, navigation }) => {
          params.navigation.prevEl = prevRef.current
          params.navigation.nextEl = nextRef.current
          navigation.init()
          navigation.update()
        }}
        slidesPerView={3}
        spaceBetween={20}
        breakpoints={{
          0: {
            slidesPerView: 'auto'
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 15
          },
          760: {
            slidesPerView: 3
          },
          870: {
            slidesPerView: 2
          },
          1070: {
            slidesPerView: 3
          }
        }}
      >
        {investorsCardSlides}
      </Swiper>
    </S.InvestorsCarousel>
  )
}

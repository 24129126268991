import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import en from '@assets/locales/en.json'
import ru from '@assets/locales/ru.json'

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en
    },
    ru: {
      translation: ru
    }
  },
  fallbackLng: 'en',
  debug: true,
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  }
})

export default i18n

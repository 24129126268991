import styled, { css } from 'styled-components'

import { BoxGray } from '@styled/components'
import { media } from '@styled/media'

import { NoData } from '@components/NoData/NoData.styled'

export const LinkBroker = styled(BoxGray)`
  padding: 50px 60px;

  h3 {
    font-family: 'Montserrat-Bold';
    margin-bottom: 30px;
  }

  & > h6:last-of-type {
    margin-top: 50px;
  }

  & > p {
    margin-top: 20px;
    font-size: var(--font-size-md);
    text-align: center;
  }

  ${NoData} {
    margin-top: 20px;
    padding-bottom: 0;
  }

  ${media.mobile} {
    h3 {
      margin-bottom: 15px;
      font-size: var(--font-size-md);
    }

    h6 {
      font-size: var(--font-size-reg);

      &:last-of-type {
        margin-top: 30px;
      }
    }
  }
`

export const BrokersList = styled.ul`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 35px;
  margin-top: 20px;

  &:not(:last-child) {
    margin-bottom: 50px;
  }

  ${media.createMedia(1000)} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;

    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  ${media.createMedia(730)} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 8px;
  }
`

export const BrokerLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.3s;

  ${({ url }) =>
    url &&
    css`
      background: url(${url}) #fff 50% 50% no-repeat;
    `}
`

export const BrokerItemLogoBW = styled(BrokerLogo)`
  opacity: 1;
`

export const BrokerItemLogoColored = styled(BrokerLogo)`
  opacity: 0;
`

export const BrokerItem = styled.li`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 90px;
  transition: 0.3s;
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    ${BrokerItemLogoBW} {
      opacity: 0;
    }

    ${BrokerItemLogoColored} {
      opacity: 1;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid var(--color-primary);
    `}

  ${media.createMedia(500)} {
    span {
      font-size: var(--font-size-reg);
    }
  }
`

export const Actions = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;

  button {
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 7px;
    }
  }
`

export const SkeletonGrid = styled.div`
  margin-top: 20px;

  & > span {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 35px;

    span {
      height: 90px;
    }
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'
import Fade from 'react-reveal/Fade'
import { useTranslation } from 'react-i18next'

import * as S from './Footer.styled'

export const Footer = ({ animated }) => {
  const { t } = useTranslation()

  const nav = [
    {
      label: t('Privacy'),
      link: 'https://helperproducts.notion.site/Privacy-policy-60612fd4802748d18adc48ecb70cfef8'
    },
    {
      label: t('Terms&Conditions'),
      link: 'https://helperproducts.notion.site/Terms-Conditions-f1eb66866c5b4e948a75d9d1c75ca80c'
    },
    {
      label: t('Disclosure'),
      link: 'https://helperproducts.notion.site/Disclosure-ebdc32042e8042abb6864bb4f95fe9f5'
    }
  ]

  const navList = nav.map(({ label, link }, idx) => {
    return (
      <li key={link}>
        <Fade bottom delay={5100 + idx * 300}>
          <a href={link}>{label}</a>
        </Fade>
      </li>
    )
  })

  return (
    <S.Footer animated={animated}>
      <S.Copyright>
        <Fade bottom delay={5000}>
          <Typography variant="body1">2021 HelperProducts OU, Tallinn.</Typography>
        </Fade>
      </S.Copyright>

      <S.NavList>{navList}</S.NavList>
    </S.Footer>
  )
}

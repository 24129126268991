import styled, { css } from 'styled-components'
import MuiCheckbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { ReactComponent as CheckboxIcon } from '@assets/icons/checkbox.svg'
import { ReactComponent as RectangleIcon } from '@assets/icons/rectangle.svg'

export const CheckboxInput = styled(({ error, ...props }) => (
  <MuiCheckbox
    disableRipple
    classes={{ root: 'checkbox-root' }}
    checkedIcon={<CheckboxIcon />}
    icon={<RectangleIcon />}
    {...props}
  />
))`
  &.checkbox-root {
    padding: 0;
    margin-right: 10px;
  }

  ${({ error }) => {
    return (
      error &&
      css`
        svg rect {
          stroke: var(--color-red);
        }
      `
    )
  }}
`

export const Label = styled((props) => (
  <FormControlLabel {...props} classes={{ root: 'form-control-label-root' }} />
))`
  &.form-control-label-root {
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    line-height: 20px;
  }
`

export const Checkbox = styled.div``

import React, { forwardRef } from 'react'
import ReactPhoneNumberInput from 'react-phone-number-input'
import { Controller, useFormContext } from 'react-hook-form'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import { useTranslation } from 'react-i18next'

import * as S from './PhoneField.styled'
import 'react-phone-number-input/style.css'

const PhoneTextFieldInput = forwardRef((props, ref) => {
  const { t } = useTranslation()
  return <S.PhoneTextFieldInput {...props} inputRef={ref} label={t('Phone Number')} name="phone" />
})

const CountrySelect = ({ options, onChange, iconComponent: Icon, ...props }) => {
  return (
    <S.CountrySelect
      {...props}
      IconComponent={undefined}
      defaultValue="EU"
      renderValue={(selected) => Icon({ country: selected })}
      onChange={(event) => event.target.value || ''}
    >
      {options?.map(({ label, value }) => {
        return (
          <MenuItem key={value + label} value={value}>
            <ListItemIcon>{Icon({ country: value })}</ListItemIcon>
            {label}
          </MenuItem>
        )
      })}
    </S.CountrySelect>
  )
}

export const PhoneField = ({ name, required = true, ...props }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required }}
      render={({ field }) => {
        return (
          <S.PhoneField>
            <ReactPhoneNumberInput
              {...props}
              {...field}
              international
              name={name}
              onChange={(value) => field.onChange(value)}
              inputComponent={PhoneTextFieldInput}
              countrySelectComponent={(selectProps) => {
                return <CountrySelect {...selectProps} disabled={props.disabled} />
              }}
            />
          </S.PhoneField>
        )
      }}
    />
  )
}

import styled from 'styled-components'

import { Modal } from '@ui'

import { ModalContent } from '@ui/Modal/Modal.styled'
import { Button } from '@ui/Button/Button.styled'
import { TextField } from '@ui/TextField/TextField.styled'

export const BrokerConnectModal = styled(Modal)`
  ${ModalContent} {
    width: 480px;
  }
`

export const Form = styled.form`
  ${TextField} {
    margin-bottom: 20px;
  }

  & > a {
    display: inline-block;
    font-family: 'Montserrat-Medium';
    font-size: var(--font-size-reg);
    color: #000;
    line-height: 21px;
    margin-bottom: 20px;

    &:hover {
      text-decoration: none;
    }
  }

  p {
    display: flex;
    align-items: center;

    svg {
      margin-left: 5px;
    }
  }

  ${Button} {
    margin-top: 30px;
  }
`

export const FieldsGrid = styled.div`
  display: flex;
`

export const Connected = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: var(--font-size-md);

  svg {
    margin-right: 14px;
  }
`

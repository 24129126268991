import styled, { css } from 'styled-components'

export const Dropzone = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  padding: 10px;
  border: 1px dashed var(--color-gray-dark);
  background: #fff;
  border-radius: 6px;
  cursor: pointer;
  transition: 0.3s;
  box-sizing: border-box;

  img {
    max-width: 100%;
  }

  ${({ isDragAccept }) =>
    isDragAccept &&
    css`
      border-color: var(--color-green);
    `}

  ${({ isDragReject }) =>
    isDragReject &&
    css`
      border-color: var(--color-red);
    `}
`

import styled, { css } from 'styled-components'

import { WithViewToggler } from '@components/WithViewToggler/WithViewToggler.styled'
import { InvestorsTable } from '@components/InvestorsTable/InvestorsTable.styled'
import { NoData } from '@components/NoData/NoData.styled'
import { Skeleton } from '@ui/Skeleton/Skeleton.styled'

export const gridStyles = css`
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 20px;
  margin-top: 20px;
  padding: 20px;
  background: #f7f7f7;
`

export const WatchlistPage = styled.div``

export const Content = styled.div`
  display: grid;
  grid-template-columns: 260px calc(100% - 300px);
  grid-gap: 40px;
  align-items: flex-start;
`

export const InvestorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${WithViewToggler} {
    margin-top: 40px;
  }

  ${InvestorsTable}, ${NoData} {
    margin-top: 20px;
  }
`

export const InvestorsCardsGrid = styled.div`
  ${gridStyles}
`

export const SkeletonGrid = styled.div`
  & > span {
    ${gridStyles}

    ${Skeleton} {
      height: 373px;
    }
  }
`

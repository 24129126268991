import React from 'react'
import Typography from '@mui/material/Typography'
import { Link } from 'react-router-dom'

import * as S from './Logo.styled'

export const Logo = () => {
  return (
    <S.Logo>
      <Link to="/">
        <S.Square>4</S.Square>

        <Typography variant="h5" component="span">
          turtles
        </Typography>
      </Link>
    </S.Logo>
  )
}

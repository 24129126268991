import styled from 'styled-components'

import { BoxGray } from '@styled/components'

import { UploadAvatar } from '@components/UploadAvatar/UploadAvatar.styled'
import { TextField } from '@ui/TextField/TextField.styled'
import { PasswordField } from '@ui/PasswordField/PasswordField.styled'
import { ButtonEdit } from '@ui/ButtonEdit/ButtonEdit.styled'
import { PhoneField } from '@ui/PhoneField/PhoneField.styled'
import { Button } from '@ui/Button/Button.styled'

import { media } from '@styled/media'

export const EditRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 90px;
  grid-gap: 20px;

  ${PasswordField} {
    .input-root input {
      font-size: 30px;
    }
  }

  ${ButtonEdit} {
    height: 100%;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
    margin-bottom: 20px;

    ${ButtonEdit} {
      height: 41px;
    }
  }
`

export const MyInfo = styled(BoxGray).attrs({ as: 'form' })`
  ${UploadAvatar}, & > ${TextField}, h6, ${EditRow} {
    margin-bottom: 30px;
  }

  h6:nth-of-type(2) {
    margin-top: 10px;
  }

  ${media.mobile} {
    h6 {
      font-size: var(--font-size-md);

      &:first-child {
        margin-bottom: 15px;
      }

      &:nth-of-type(2) {
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }

    ${UploadAvatar} {
      margin-bottom: 20px;
    }

    ${TextField} {
      margin-bottom: 10px;
    }

    ${PhoneField} {
      margin-bottom: 10px;

      ${TextField} {
        margin-bottom: 0;
      }
    }
  }
`

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${Button} {
    &:first-child {
      color: var(--color-red);
      text-decoration: none;

      &::before {
        display: none;
      }

      &:hover {
        color: var(--color-red);
      }
    }

    &:last-child {
      width: 250px;
    }
  }

  ${media.mobile} {
    flex-direction: column-reverse;
    margin-top: 30px;

    ${Button} {
      &:first-child {
        margin-top: 30px;
      }

      &:last-child {
        width: 100%;
        font-size: var(--font-size-reg);
      }
    }
  }
`

import styled from 'styled-components'

import { Accordion } from '@ui/Accordion/Accordion.styled'

import { BoxGray } from '@styled/components'

export const Section = styled(BoxGray).attrs({ as: 'li' })`
  padding: 30px 40px;

  h6 {
    margin-top: 20px;
    line-height: 24px;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;

  svg {
    width: 36px;
    height: 36px;
  }
`

export const QaList = styled.div`
  margin-top: 25px;

  ${Accordion} {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`

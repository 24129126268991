import styled from 'styled-components'

import { BoxGray } from '@styled/components'

import { Button } from '@ui/Button/Button.styled'
import { TextEditor } from '@ui/TextEditor/TextEditor.styled'
import { SelectInput } from '@ui/Select/Select.styled'
import { Dropzone } from '@ui/Dropzone/Dropzone.styled'

export const CreatePost = styled(BoxGray)`
  display: grid;
  grid-template-columns: 64px 548px 1fr;
  grid-gap: 20px;
  padding: 20px;

  ${Button} {
    width: 117px;
  }

  ${TextEditor}, ${Dropzone} {
    margin-top: 20px;
  }

  ${SelectInput} {
    .select-menu-paper {
      width: 378px;
    }
  }
`

export const Avatar = styled.div`
  width: 64px;
  height: 64px;

  img {
    max-width: 100%;
  }
`

export const PostText = styled.div`
  width: 100%;
  margin-left: 10px;
`

export const Form = styled.form`
  width: 100%;
`

export const Bottom = styled.div`
  display: flex;
  margin-top: 20px;

  ${Button} {
    width: 200px;
    margin-left: 32px;
  }
`

export const ViewToggler = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  align-self: flex-start;
  width: 24px;
  height: 24px;
  padding-top: 20px;
  font-family: 'Montserrat-Bold';
  font-size: var(--font-size-h6);
  cursor: pointer;
`

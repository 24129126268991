import React, { useState, useEffect, cloneElement } from 'react'
import Typography from '@mui/material/Typography'
import { FormProvider, useForm } from 'react-hook-form'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ChangePasswordModal, UploadAvatar } from '@components'
import { TextField, PasswordField, PhoneField, Button, ButtonEdit, Skeleton } from '@ui'

import * as UsersAPI from '@api/users'
import { useAuth } from '@contexts/auth'
import { ROUTE_NAMES } from '@core/routes'

import * as S from './MyInfo.styled'

export const MyInfo = ({ phone, refetchVerifications }) => {
  const { t } = useTranslation()

  const useFormProps = useForm()
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = useFormProps
  const { user, userCognito } = useAuth()

  const [allowedFieldsEditing, setAllowedFieldsEditing] = useState({
    email: false,
    phone: false
  })
  const [isChangePasswordModalOpen, setChangePaswwordModalOpen] = useState(false)

  const {
    data: info = {},
    isLoading: isInfoLoading,
    refetch: refetchInfo,
    isRefetching: isInfoRefetching
  } = useQuery('info', () => UsersAPI.getInfo({ username: user.login }), { cacheTime: 0 })

  const changeInfo = useMutation(
    ({ fullName, country }) => UsersAPI.changeInfo({ fullName, country }),
    {
      onError: () => {
        toast.error('Failed to update info')
      },
      onSuccess: () => {
        toast.success('Info successfully updated')
        refetchInfo()
        refetchVerifications()
      }
    }
  )

  const changeEmail = useMutation((email) => UsersAPI.changeEmail(userCognito, email), {
    onError: () => {
      toast.error('Failed to update email')
    },
    onSuccess: () => {
      setAllowedFieldsEditing({
        email: false,
        phone: false
      })
    }
  })

  const changePhone = useMutation((phone) => UsersAPI.changePhone(phone), {
    onError: () => {
      toast.error('Failed to update phone')
    },
    onSuccess: () => {
      toast.success('Phone successfully updated')
      setAllowedFieldsEditing({
        email: false,
        phone: false
      })
      reset()
      refetchVerifications()
    }
  })

  const uploadAvatar = useMutation((img) => UsersAPI.uploadAvatar(img), {
    onError: () => {
      toast.error('Failed to upload avatar')
    }
  })

  const deleteAvatar = useMutation(UsersAPI.deleteAvatar, {
    onSuccess: () => {
      refetchInfo()
    }
  })

  const { login, email } = user
  const { fullName, country, avatar } = info

  useEffect(() => {
    reset({ fullName, country, login, email, phone: `+${phone}` })
  }, [fullName, country, login, email, phone, reset])

  const openChangePasswordModal = () => {
    setChangePaswwordModalOpen(true)
  }

  const closeChangePasswordModal = () => {
    setChangePaswwordModalOpen(false)
  }

  const editableFields = [
    {
      name: 'password',
      field: (
        <PasswordField
          name="password"
          label={t('Password')}
          required={false}
          defaultValue="**********"
          disabled
          iconDisabled
        />
      )
    },
    {
      name: 'email',
      field: <TextField type="email" name="email" label={t('Email')} required={false} />
    },
    { name: 'phone', field: <PhoneField name="phone" required={false} /> }
  ]

  const editableFieldRows = editableFields.map(({ name, field }) => {
    if (name === 'password') {
      return (
        <S.EditRow key={name}>
          {field}
          <ButtonEdit onClick={openChangePasswordModal} />
        </S.EditRow>
      )
    }

    return (
      <S.EditRow key={name}>
        {cloneElement(field, { ...field.props, disabled: !allowedFieldsEditing[name] })}

        <ButtonEdit
          onClick={() =>
            setAllowedFieldsEditing({
              ...allowedFieldsEditing,
              [name]: !allowedFieldsEditing[name]
            })
          }
        />
      </S.EditRow>
    )
  })

  const onInfoSubmit = ({ fullName, country, email, phone }) => {
    const {
      country: isCountryDirty,
      fullName: isFullNameDirty,
      phone: isPhoneDirty,
      email: isEmailDirty
    } = dirtyFields

    if (isFullNameDirty || isCountryDirty) {
      changeInfo.mutate({ fullName, country })
      reset({ fullName, country })
    }

    if (isPhoneDirty) {
      const phoneWithoutPlus = phone ? phone.slice(1) : null
      changePhone.mutate(phoneWithoutPlus)
      reset({ phone })
    }

    if (isEmailDirty) {
      changeEmail.mutate(email)
      reset({ email })
    }
  }

  if (isInfoLoading || isInfoRefetching) {
    return (
      <S.MyInfo>
        <Typography variant="h6">{t('My info')}</Typography>
        <Skeleton height={814} />
      </S.MyInfo>
    )
  }

  return (
    <FormProvider {...useFormProps}>
      <S.MyInfo onSubmit={handleSubmit(onInfoSubmit)}>
        <Typography variant="h6">{t('My info')}</Typography>

        <UploadAvatar
          avatar={avatar}
          onUploadAvatar={uploadAvatar.mutate}
          onDeleteAvatar={deleteAvatar.mutate}
        />

        <TextField
          name="fullName"
          label={t('Full Name')}
          rules={{
            required: true,
            maxLength: { value: 20, message: 'Full name must be no more than 20 characters' }
          }}
        />
        <TextField
          name="country"
          label={t('Country')}
          rules={{
            required: true,
            maxLength: { value: 20, message: 'Country must be no more than 20 characters' }
          }}
        />

        <Typography variant="h6">{t('Credentials')}</Typography>

        <TextField name="login" label={t('Login')} disabled required={false} />

        {editableFieldRows}

        <S.Buttons>
          <Button variant="inline" as={Link} to={ROUTE_NAMES.HELP}>
            {t('Delete account')}
          </Button>

          <Button
            type="submit"
            variant="lime"
            isLoading={changeInfo.isLoading || changeEmail.isLoading || changePhone.isLoading}
          >
            {t('Save profile')}
          </Button>
        </S.Buttons>
      </S.MyInfo>

      <ChangePasswordModal
        title={t('Change Password')}
        open={isChangePasswordModalOpen}
        onClose={closeChangePasswordModal}
      />
    </FormProvider>
  )
}

import styled from 'styled-components'

import { AnnualizedTotalReturns } from '../AnnualizedTotalReturns/AnnualizedTotalReturns.styled'
import { Pitch } from '../Pitch/Pitch.styled'
import { Counter } from '../Counter/Counter.styled'

export const Performance = styled.div``

export const Grid = styled.div`
  display: grid;
  grid-template-areas: 'a a b b' 'a a b b' 'c d b b';
  grid-template-columns: repeat(3, 260px);
  grid-gap: 40px;
  margin-top: 40px;

  ${AnnualizedTotalReturns} {
    grid-area: a;
  }

  ${Pitch} {
    grid-area: b;
  }

  ${Counter} {
    &:nth-child(3) {
      grid-area: c;
    }

    &:nth-child(4) {
      grid-area: d;
    }
  }
`

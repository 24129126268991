import styled from 'styled-components'

import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

import { ReactComponent as ArrowRight } from '@assets/icons/arrow-right-green.svg'

export const ExpandIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
`

export const Accordion = styled((props) => (
  <MuiAccordion classes={{ root: 'accordion' }} square disableGutters {...props} />
))`
  &.accordion {
    box-shadow: none;
    background: transparent;

    &::before {
      display: none;
    }

    &:first-child,
    &:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
`

export const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    classes={{
      root: 'accordion-summary',
      content: 'accordion-summary-content',
      expanded: 'accordion-summary-expanded',
      expandIconWrapper: 'accordion-expand-icon-container'
    }}
    expandIcon={
      <ExpandIcon>
        <ArrowRight />
      </ExpandIcon>
    }
    {...props}
  />
))`
  &.accordion-summary {
    flex-direction: row-reverse;
    align-items: flex-start;
    min-height: 24px;
    padding: 0;
  }

  .accordion-summary-content {
    margin: 0;
    margin-left: 10px;
    font-size: var(--font-size-md);
    color: #1b1b1b;
    line-height: 24px;
  }

  .accordion-expand-icon-container.accordion-summary-expanded {
    transform: rotate(90deg);
  }
`

export const AccordionDetails = styled((props) => (
  <MuiAccordionDetails classes={{ root: 'accordion-details' }} {...props} />
))`
  &.accordion-details {
    padding: 15px 0 15px 30px;
    color: #000;
    line-height: 21px;
  }
`

import styled from 'styled-components'

import { Button } from '@ui/Button/Button.styled'

export const ButtonEdit = styled(Button)`
  && {
    display: flex;
    background: #f7f7f7;

    &::before {
      background: #f7f7f7;
    }

    &:hover {
      svg {
        fill: var(--color-primary);
      }
    }

    svg {
      margin-left: 3px;
      transition: 0.3s;
    }
  }
`

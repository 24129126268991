import styled from 'styled-components'

import MuiSelect from '@mui/material/Select'
import MuiInputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { customScrollStyles } from '@styled/customScrollStyles'

import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down-gray.svg'

export const SelectFormControl = styled((props) => (
  <FormControl classes={{ root: 'form-control ' }} {...props} />
))`
  &.form-control {
    position: 'relative';
  }
`

export const InputLabel = styled((props) => (
  <MuiInputLabel
    {...props}
    classes={{
      root: 'input-label-root',
      shrink: 'input-label-shrink',
      focused: 'input-label-focused'
    }}
  />
))`
  &.input-label-root {
    margin-left: 20px;
    font-family: 'Montserrat-Regular';
    font-size: var(--font-size-md);
    color: var(--color-gray-dark);
    line-height: 24px;
    transform: translate(0, 20px) scale(1);

    &.input-label-focused {
      color: var(--color-gray-dark);
    }

    &.input-label-shrink {
      transform: translate(0, 6px);
      font-size: var(--font-size-sm);
    }
  }
`

export const SelectInput = styled((props) => (
  <MuiSelect
    IconComponent={ArrowDownIcon}
    autoWidth
    classes={{
      select: 'select-root',
      outlined: 'select-outlined',
      icon: 'select-icon',
      iconOpen: 'select-icon-open',
      disabled: 'select-disabled'
    }}
    MenuProps={{
      ...props.MenuProps,
      classes: { paper: 'select-menu-paper', list: 'select-menu-list' },
      getContentAnchorEl: null,
      disablePortal: true
    }}
    inputProps={{
      ...props.InputProps,
      classes: { outlined: 'select-input-outlined' }
    }}
    {...props}
  />
))`
  width: 100%;

  && {
    .select-root.select-input-outlined {
      padding-right: 33px;
    }
  }

  .select {
    &-root {
      padding: 29px 20px 9px;
      background: #fff;
      border: 1px solid var(--color-gray-light);
      border-radius: 0;
      font-family: 'Montserrat-Regular';
      font-size: var(--font-size-md);
      line-height: 24px;
      box-sizing: border-box;

      &:hover {
        border-color: var(--color-gray-light);
      }
    }

    &-disabled {
      background: var(--color-gray-light);
    }

    &-icon {
      right: 20px;
      top: 50%;
      fill: var(--color-gray-dark);
      transform: translateY(-50%);
      transition: 0.3s;

      &-open {
        transform: rotate(-180deg) translateY(50%);
        fill: #000;
      }
    }

    &-input-outlined {
      border-radius: 0;
    }

    &-menu-paper {
      min-height: auto;
      border-radius: 0;
      box-shadow: var(--box-shadow);
      border: 1px solid var(--color-gray-light);
      border-top: none;
      box-sizing: border-box;

      ${customScrollStyles}
    }

    &-menu-list {
      max-height: 184px;
      padding-top: 0;
      padding-bottom: 0;
      box-sizing: border-box;

      li {
        padding: 6px 20px;
        font-family: 'Montserrat-Regular';
        font-size: var(--font-size-md);

        &:hover {
          background: #fff;
        }

        &:first-child {
          padding-top: 20px;
        }

        &:last-child {
          padding-bottom: 20px;
        }

        &.Mui-selected {
          background: #fff;
        }
      }
    }
  }

  fieldset {
    display: none;
  }
`

export const RemoveIcon = styled.div`
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }
`

import React from 'react'
import Typography from '@mui/material/Typography'
import Fade from 'react-reveal/Fade'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'

import { PageLayout, BrokersSlider, TradersSlider, GetStartedCircle } from '@components'

import { ROUTE_NAMES } from '@core/routes'

import * as S from './Main.styled'

import { ReactComponent as LineFirst } from '@assets/img/line-1.svg'
import { ReactComponent as ArrowFirst } from '@assets/img/arrow-1.svg'
import { ReactComponent as RectangleFirst } from '@assets/img/rectangle-1.svg'

import { ReactComponent as LineMobileFirst } from '@assets/img/line-mobile-1.svg'
import { ReactComponent as ArrowMobileFirst } from '@assets/img/arrow-mobile-1.svg'
import { ReactComponent as RectangleMobileFirst } from '@assets/img/rectangle-mobile-1.svg'

import { ReactComponent as LineSecond } from '@assets/img/line-2.svg'
import { ReactComponent as ArrowSecond } from '@assets/img/arrow-2.svg'
import { ReactComponent as RectangleSecond } from '@assets/img/rectangle-2.svg'

import { ReactComponent as LineMobileSecond } from '@assets/img/line-mobile-2.svg'
import { ReactComponent as ArrowMobileSecond } from '@assets/img/arrow-mobile-2.svg'
import { ReactComponent as RectangleMobileSecond } from '@assets/img/rectangle-mobile-2.svg'

export const MainPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <S.MainPage>
      <PageLayout animated>
        <S.Content>
          <S.Top>
            <S.Brokers>
              <Fade bottom delay={1050} distance="40px">
                <BrokersSlider />
              </Fade>

              <Typography variant="h1" component="h2">
                <Fade bottom delay={1050} cascade>
                  <div>
                    <span>{t('Connect your')}</span>
                    <span>{t('stocks&crypto')}</span>
                    <span>{t('broker')}</span>
                  </div>
                </Fade>
              </Typography>
            </S.Brokers>

            <MediaQuery minWidth={1171}>
              <S.LineFirst>
                <RectangleFirst />
                <LineFirst />
                <ArrowFirst />
              </S.LineFirst>
            </MediaQuery>

            <MediaQuery maxWidth={500}>
              <S.LineFirst>
                <RectangleMobileFirst />
                <LineMobileFirst />
                <ArrowMobileFirst />
              </S.LineFirst>
            </MediaQuery>

            <S.Traders>
              <Typography variant="h1" component="h2">
                <Fade bottom delay={2900} cascade>
                  <div>
                    <span>{t('Choose traders')}</span>
                    <span>{t('to mimic their')}</span>
                    <span>{t('portfolios')}</span>
                  </div>
                </Fade>
              </Typography>

              <Fade bottom delay={2800} distance="40px">
                <TradersSlider />
              </Fade>
            </S.Traders>
          </S.Top>

          <S.Bottom>
            <Fade bottom delay={4700}>
              <Typography variant="h1" component="h2">
                {t('Invest now')}
              </Typography>
            </Fade>

            <Fade bottom delay={4500} distance="50px">
              <GetStartedCircle
                animated
                onClick={() => {
                  navigate(ROUTE_NAMES.DISCOVER)
                }}
              />
            </Fade>

            <MediaQuery minWidth={1171}>
              <S.LineSecond>
                <RectangleSecond />
                <LineSecond />
                <ArrowSecond />
              </S.LineSecond>
            </MediaQuery>

            <MediaQuery maxWidth={500}>
              <S.LineSecond>
                <RectangleMobileSecond />
                <LineMobileSecond />
                <ArrowMobileSecond />
              </S.LineSecond>
            </MediaQuery>
          </S.Bottom>
        </S.Content>
      </PageLayout>
    </S.MainPage>
  )
}

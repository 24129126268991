import { API } from '@core/axios'

const transformConstants = (constants) => {
  const { asset_type, currency, forum_category, risk, usernames_group_tag } = constants

  return {
    assetsTypes: asset_type,
    currencies: currency,
    forumCategories: forum_category,
    riskTypes: risk,
    userGroupTags: usernames_group_tag
  }
}

const transformInstruments = (instruments) => {
  return instruments.map((instrument) => {
    const { instrument_name: name, symbol, logo_urls: logos } = instrument

    return {
      name,
      symbol,
      logo: logos[0].logo_url
    }
  })
}

export const getConstants = async () => {
  const { data } = await API.get(`/dictionary`)
  return transformConstants(data[0])
}

export const getInstruments = async () => {
  const { data: instruments } = await API.get(`/instruments`)
  return transformInstruments(instruments)
}

import React, { useState, useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'

import { Autocomplete, Button } from '@ui'

import * as UsersAPI from '@api/users'
import { sortByOrderProp } from '@utils'

import * as S from './MoreBrokersModal.styled'

export const MoreBrokersModal = ({ open, brokers, onBrokerChoose, onClose }) => {
  const { t } = useTranslation()
  const media500 = useMediaQuery({ query: '(min-width: 500px)' })

  const useFormProps = useForm({
    defaultValues: {
      brokernameSearch: ''
    }
  })
  const { watch } = useFormProps

  const [brokerSelected, setBrokerSelected] = useState('')

  const {
    data: brokersFounded = [],
    mutate: searchBrokerMutate,
    isLoading: isBrokersLoading
  } = useMutation((brokername) => UsersAPI.getBrokers({ search: brokername }))

  const brokernameSearch = watch('brokernameSearch')

  useEffect(() => {
    if (brokernameSearch) {
      searchBrokerMutate(brokernameSearch)
    }
  }, [brokernameSearch, searchBrokerMutate])

  const brokersItems = sortByOrderProp(brokers).map((broker) => {
    const { name, logos } = broker
    const logoBW = media500 ? logos[1] : logos[4]
    const logoColored = media500 ? logos[2] : logos[3]

    const isSelected = brokerSelected.name === name

    return (
      <S.BrokerItem
        key={name}
        selected={isSelected}
        onClick={() => {
          setBrokerSelected(broker)
        }}
      >
        <S.BrokerItemLogoBW url={logoBW.url} />
        <S.BrokerItemLogoColored url={logoColored.url} style={isSelected ? { opacity: 1 } : null} />
      </S.BrokerItem>
    )
  })

  const brokersFoundedNames = sortByOrderProp(brokersFounded).map(({ name }) => name)

  return (
    <S.MoreBrokerModal title={t('More Brokers')} open={open} onClose={onClose}>
      <FormProvider {...useFormProps}>
        <Autocomplete
          name="brokernameSearch"
          options={brokersFoundedNames}
          placeholder={t('Search among 1,000 brokers')}
          noOptionsText={t('Brokers not found')}
          loading={isBrokersLoading}
          loadingText={t('Searching for brokers...')}
          onChange={(_, value) => {
            onBrokerChoose(value)
          }}
        />

        <S.BrokersList>{brokersItems}</S.BrokersList>

        <Button
          variant="lime"
          onClick={() => {
            onBrokerChoose(brokerSelected)
          }}
          disabled={!brokerSelected}
        >
          {t('Choose')}
        </Button>
      </FormProvider>
    </S.MoreBrokerModal>
  )
}

import styled, { css } from 'styled-components'

import { BoxGray } from '@styled/components'

export const AnnualizedTotalReturns = styled(BoxGray)`
  width: 100%;
  height: 444px;
  padding: 33px 40px 40px;
  box-sizing: border-box;
`

export const ChartContainer = styled.div`
  margin-top: 20px;
  padding: 10px 20px 10px 10px;
  height: 313px;
  background: #fff;
  box-sizing: border-box;
`

export const ChartTop = styled.div`
  position: relative;
`

export const Returns = styled.div`
  position: absolute;
  top: 50%;
  left: -20px;
  font-family: 'Montserrat-Medium';
  transform: rotate(-90deg) translateY(-50%);
`

export const Percents = styled.div`
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 10px;
  width: 37px;
  margin-left: 20px;
  margin-right: 10px;
  font-family: 'Montserrat-SemiBold';
  line-height: 21px;
  color: #a5a5b1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 175px;
    width: 30px;
    height: 248px;
    background: #fff;
  }

  span {
    position: relative;
    text-align: right;

    &::before {
      content: '';
      position: absolute;
      left: 47px;
      top: 50%;
      width: 380px;
      height: 1px;
      background: #f1f1f1;
      transform: translateY(-50%);
    }
  }
`

export const Chart = styled.div`
  position: absolute;
  top: 0;
  height: 50%;

  &:nth-child(3) {
    left: 100px;
  }

  &:nth-child(4) {
    left: 290px;
  }

  div {
    width: 60px;
  }
`

export const BarGreen = styled.div`
  position: absolute;
  background: var(--color-primary);

  ${({ direction }) => {
    if (direction) {
      return css`
        bottom: -17px;
      `
    }

    return css`
      top: 134px;
    `
  }}
`

export const BarBlack = styled.div`
  position: absolute;
  margin-left: 60px;
  background: #000;

  ${({ direction }) => {
    if (direction) {
      return css`
        bottom: -17px;
      `
    }

    return css`
      top: 134px;
    `
  }}
`

export const ChartBottom = styled.div``

export const BarLabels = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 175px);
  grid-gap: 30px;
  margin-left: 70px;
  font-family: 'Montserrat-SemiBold';
  font-size: var(--font-size-reg);
  line-height: 24px;
  color: #a5a5b1;
`

export const Names = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 13px;

  p {
    position: relative;
    padding-left: 14px;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transform: translateY(-50%);
    }
  }
`

export const Username = styled.p`
  &::before {
    background: var(--color-primary);
  }
`

export const Benchmark = styled.p`
  display: flex;
  align-items: center;
  margin-left: 20px;

  &::before {
    background: #000;
  }

  svg {
    margin-left: 4px;
  }
`

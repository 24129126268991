import styled from 'styled-components'
import { Modal } from '@ui'

import { media } from '@styled/media'
import { NoItemsText } from '@styled/components'
import { customScrollStyles } from '@styled/customScrollStyles'
import {
  BrokerItem,
  BrokerItemLogoBW,
  BrokerItemLogoColored
} from '../LinkBroker/LinkBroker.styled'

import { ModalContent } from '@ui/Modal/Modal.styled'
import { AutocompleteContainer } from '@ui/Autocomplete/Autocomplete.styled'
import { Button } from '@ui/Button/Button.styled'

export { BrokerItem, BrokerItemLogoBW, BrokerItemLogoColored, NoItemsText }

export const MoreBrokerModal = styled(Modal)`
  ${ModalContent} {
    display: flex;
    flex-direction: column;
    width: 880px;
    height: calc(100% - 30px);
  }

  ${AutocompleteContainer} {
    margin-bottom: 40px;

    .autocomplete {
      &-paper {
        margin-left: -1px;
      }
    }
  }

  ${Button} {
    margin-top: 40px;
    flex-shrink: 0;
  }

  ${media.createMedia(700)} {
    ${ModalContent} {
      padding: 30px 16px;
    }
  }

  ${media.mobile} {
    ${AutocompleteContainer} {
      margin-bottom: 30px;
    }
  }
`

export const BrokersList = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  overflow-y: auto;

  ${customScrollStyles}

  ${media.createMedia(800)} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
  }

  ${media.mobile} {
    ${BrokerItem} {
      height: 52px;
    }
  }
`

import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'

import * as S from './Button.styled'

export const Button = ({ children, disabled, isLoading, ...props }) => {
  return (
    <S.Button {...props} disabled={disabled || isLoading}>
      <span>
        <span>{!isLoading ? children : <CircularProgress />}</span>
      </span>
    </S.Button>
  )
}

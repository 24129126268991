import React from 'react'

import * as S from './GetStartedCircle.styled'

import { ReactComponent as ArrowUpRightIcon } from '@assets/icons/arrow-up-right.svg'
import getStartedText from '@assets/img/get-started-text.png'
import getStartedTextBig from '@assets/img/get-started-text-big.png'

export const GetStartedCircle = ({ big, animated, onClick }) => {
  return (
    <S.GetStartedCircle big={big} onClick={onClick}>
      <S.GetStartedText animated={animated}>
        <img src={big ? getStartedTextBig : getStartedText} alt="get started *" />
      </S.GetStartedText>

      <S.CircleLime>
        <ArrowUpRightIcon />
      </S.CircleLime>
    </S.GetStartedCircle>
  )
}

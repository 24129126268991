import { css } from 'styled-components'

export const customScrollStyles = css`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-primary);
  }
`

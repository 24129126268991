import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTE_NAMES } from '@core/routes'

import { getCookie } from '@utils'

export const PrivateRoute = ({ component: RouteComponent }) => {
  const authToken = getCookie('token')

  if (authToken) {
    return <RouteComponent />
  }

  return <Navigate to={ROUTE_NAMES.LOGIN} />
}

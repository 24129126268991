import React, { useState } from 'react'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'

import { ContentDisplay } from '@components'
import { Button, Skeleton } from '@ui'
import { UserInfoEditModal } from '../UserInfoEditModal'

import { splitNumber } from '@utils'

import * as S from './Pitch.styled'

import { ReactComponent as InstagramIcon } from '@assets/icons/social/users/instagram.svg'
import { ReactComponent as LinkedinIcon } from '@assets/icons/social/users/linkedin.svg'
import { ReactComponent as FacebookIcon } from '@assets/icons/social/users/facebook.svg'
import { ReactComponent as TwitterIcon } from '@assets/icons/social/users/twitter.svg'
import { ReactComponent as YoutubeIcon } from '@assets/icons/social/users/youtube.svg'
import { ReactComponent as PenIcon } from '@assets/icons/pen.svg'

export const Pitch = ({
  isLoading,
  isLoggedUser,
  pitch,
  minimalCapital,
  currencySymbol,
  socialLinks = {},
  onChangeInfo
}) => {
  const { t } = useTranslation()

  const [isEditModalOpen, setEditModalOpen] = useState(false)

  const haveSocialLinks = Object.entries(socialLinks).some(([label, link]) => link)

  const socialIcons = [
    { label: 'facebook', icon: <FacebookIcon /> },
    { label: 'instagram', icon: <InstagramIcon /> },
    { label: 'linkedin', icon: <LinkedinIcon /> },
    { label: 'telegram', icon: <FacebookIcon /> },
    { label: 'twitter', icon: <TwitterIcon /> },
    { label: 'youtube', icon: <YoutubeIcon /> }
  ]

  const socialLinkItems = Object.entries(socialLinks).map(([label, link]) => {
    if (!link) return null

    const icon = socialIcons.find((social) => social.label === label)?.icon

    return (
      <li key={label}>
        <a href={link} target="_blank" rel="noreferrer">
          {icon}
        </a>
      </li>
    )
  })

  return (
    <S.Pitch>
      <S.Heading>
        <Typography variant="h4">{t('Pitch')}</Typography>

        {isLoggedUser ? (
          <Button
            variant="bordered"
            onClick={() => {
              setEditModalOpen(true)
            }}
          >
            {t('edit')} <PenIcon />
          </Button>
        ) : null}
      </S.Heading>

      <ContentDisplay
        isLoading={isLoading}
        skeleton={<Skeleton height={519} style={{ marginTop: 20 }} />}
      >
        <Typography variant="body2">{pitch}</Typography>

        <S.Bottom>
          <S.MinimalCapitalRow>
            <p>{t('Minimal capital to copy:')}</p>

            <Typography variant="h4" component="p">
              {minimalCapital ? `${splitNumber(minimalCapital)} ${currencySymbol}` : null}
            </Typography>
          </S.MinimalCapitalRow>

          {haveSocialLinks ? <S.SocialList>{socialLinkItems}</S.SocialList> : null}
        </S.Bottom>
      </ContentDisplay>

      <UserInfoEditModal
        open={isEditModalOpen}
        pitch={pitch}
        minimalCapital={minimalCapital}
        currencySymbol={currencySymbol}
        socialLinks={socialLinks}
        onChangeInfo={onChangeInfo}
        onClose={() => {
          setEditModalOpen(false)
        }}
      />
    </S.Pitch>
  )
}

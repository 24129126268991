const createMedia = (maxWidth) => {
  return `@media (max-width: ${maxWidth}px)`
}

const wrapperHorizontalPaddings = 30

export const MEDIA_DESKTOP = 1200 + wrapperHorizontalPaddings
export const MEDIA_TABLET = 768 + wrapperHorizontalPaddings
export const MEDIA_MOBILE = 450 + wrapperHorizontalPaddings

export const media = {
  createMedia,
  desktop: createMedia(MEDIA_DESKTOP),
  tablet: createMedia(MEDIA_TABLET),
  mobile: createMedia(MEDIA_MOBILE)
}

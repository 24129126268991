import React from 'react'

import * as S from './SortTriangles.styled'

export const SortTriangles = ({ onSort }) => {
  return (
    <S.SortTriangles>
      <S.Triangle
        onClick={() => {
          onSort('asc')
        }}
      />
      <S.Triangle
        onClick={() => {
          onSort('desc')
        }}
      />
    </S.SortTriangles>
  )
}
